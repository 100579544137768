<template>
    <div v-show="isBarVisible" class="select-wrapper">
        <div class="select-bar">
            <span style="font-size: 1.1rem; font-weight: 700; margin-left: 1rem;">{{ (isBulkMode) ? ((selectedInscriptions.length > 0) ? `${selectedInscriptions.length} Inscription${selectedInscriptions.length > 1 ? 's' : ''} selected` : (isSweep) ? "Enter items to sweep" : "Click to select inscription(s)") : (selectedInscriptions.length > 0) ? `Inscription selected` : "Click to select inscription" }}</span>
            <span v-if="priceTotal > 0" style="margin-left: 1rem;">
                <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>{{ satToBtc(priceTotal) }} <span class="usd-value">(${{ (satToBtc(priceTotal) * usd_rate).toFixed(2) }})</span>
            </span>
            <button v-if="selectedInscriptions.length > 0 && !isBuying && !isUpdate" class="bar-button list-button" style="margin-left: 1.5rem;" @click="listInscription">
                <font-awesome-icon :icon="['fas', 'tag']" />
                <span>List</span>
            </button>
            <button v-if="selectedInscriptions.length > 0 && isBuying && !isUpdate" class="bar-button buy-button" style="margin-left: 1.5rem;" @click="buyInscription">
                <font-awesome-icon :icon="['fas', 'shopping-cart']" />
                <span>Buy</span>
            </button>
            <button v-if="selectedInscriptions.length > 0 && isUpdate" class="bar-button edit-button" style="display: inline-flex; align-items: center; padding-left: 0.8em; padding-right: 0.8em; margin-left: 0.75rem;" @click="editListing">
                <font-awesome-icon :icon="['fas', 'tag']"/>
                <span>Edit</span>
            </button>
            <button v-if="selectedInscriptions.length > 0 && isUpdate" class="bar-button delist-button" style="display: inline-flex; align-items: center; padding-left: 0.8em; padding-right: 0.8em; margin-left: 0.75rem;" @click="delistInscription">
                <font-awesome-icon :icon="['fas', 'ban']"/>
                <span>Delist</span>
            </button>
            <button class="bar-button cancel-button outline" style="display: inline-flex; align-items: center; padding-left: 0.8em; padding-right: 0.8em; margin-left: 0.75rem;" @click="close">
                <font-awesome-icon :icon="['fas', 'xmark']"/>
                <span>Cancel</span>
            </button>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue'

export default {
  name: 'SelectBarComponent',
  
  data() {
    return {
      isBarVisible: false,
      isBulkMode: false,
      isSweep: false,
      isBuying: false,
      isUpdate: false,
      isToken: false,
      selectedInscriptions: [],
      priceTotal: 0,
      parameters: null,
      usd_rate: inject('usd_rate', 0),
    };
  },
  methods: {
    close() {
      this.selectedInscriptions = [];
      this.isBarVisible = false;
      console.log(this.isBarVisible);
      this.emitter.emit("select-bar-closed");
    },
    listInscription() {
      console.log(this.selectedInscriptions);
      this.emitter.emit("modal-list-open", { inscriptions: this.selectedInscriptions, token: this.isToken });
    },
    editListing() {
      console.log(this.selectedInscriptions);
      this.emitter.emit("modal-list-open", { inscriptions: this.selectedInscriptions, edit: true });
    },
    delistInscription() {
      console.log(this.selectedInscriptions);
      this.emitter.emit("modal-delist-open", { inscriptions: this.selectedInscriptions });
    },
    buyInscription() {
      console.log(this.inscription_id);
      this.emitter.emit("modal-buy-open", { listings: this.selectedInscriptions });
    },
    satToBtc(sat) {
      return Number(sat) / Math.pow(10, 8);
    },
  },
  mounted() { 
    this.emitter.on("select-bar-open", (parameters) => {
      this.isBarVisible = true;
      this.selectedInscriptions = parameters.inscription_ids;
      this.isBulkMode = parameters?.bulk_mode;
      this.isSweep = parameters?.sweep_mode;
      this.isBuying = parameters?.buying;
      this.isUpdate = parameters?.updating;
      this.isToken = parameters.token ? true : false;
      this.priceTotal = parameters?.price_total;
      this.parameters = parameters;
      console.log(this.selectedInscriptions);
    });
    this.emitter.on("select-bar-close", () => {
      this.close();
    });
  },
}
</script>

<style scoped>
.select-wrapper {
    position: fixed;
    bottom: 1rem;
    display: flex;
    justify-content: center;
    width: 100%;
}

.select-bar {
    flex-grow: 0;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 12px;
    background-color: #45454580;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
}

.bar-button {
    flex-shrink: 0;
}

.list-button {
    background-color: #8fc5ff;
    color: #007aff;
}

.list-button:enabled:hover {
    background-color: #007aff;
    color: #fff;
}

.buy-button {
    background-color: #aeffbe;
    color: #007e19;
}

.buy-button:enabled:hover {
    background-color: #007e19;
    color: #fff;
}

.edit-button {
    background-color: #5f530e;
    color: gold;
    padding-left: 0.8em;
    padding-right: 0.8em;
}

.edit-button:enabled:hover {
    background-color: #b09600;
    color: #fff;
}

.delist-button {
    background-color: #dc35454d;
    color: #dc3545;
    padding-left: 0.8em;
    padding-right: 0.8em;
}

.delist-button:enabled:hover {
    background-color: #880000;
    color: #fff;
}

button>svg {
    margin-right: 0.5rem;
}
</style>