import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EtchView from '../views/EtchView.vue'
// import InscribeView from '../views/InscribeView.vue'
import OrderView from '../views/OrderView.vue'
import OrdersView from '../views/OrdersView.vue'
import LeaderboardView from '../views/LeaderboardView.vue'
import AddressView from '../views/AddressView.vue'
import InscriptionView from '../views/InscriptionView.vue'
import CollectionsView from '../views/CollectionsView.vue'
import Ltc20View from '../views/Ltc20View.vue'
import ListingsView from '../views/ListingsView.vue'
import RuneView from '../views/RuneView.vue'
import RunesView from '../views/RunesView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/etch',
    name: 'etch',
    component: EtchView
  },
  // {
  //   path: '/inscribe',
  //   name: 'inscribe',
  //   component: InscribeView
  // },
  {
    path: '/order/:id',
    name: 'order',
    component: OrderView
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersView
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: LeaderboardView
  },
  {
    path: '/address/:address',
    name: 'address',
    component: AddressView,
    meta: {
      title: "Address"
    }
  },
  {
    path: '/inscription/:id',
    name: 'inscription',
    component: InscriptionView,
    meta: {
      title: "Inscription"
    }
  },
  {
    path: '/collections',
    name: 'collections',
    component: CollectionsView,
    meta: {
      title: "Collections"
    }
  },
  {
    path: '/collections/:name',
    name: 'collection',
    component: ListingsView,
    meta: {
      title: "Collections"
    }
  },
  {
    path: '/ltc20',
    name: 'ltc20s',
    component: Ltc20View,
    meta: {
      title: "LTC20"
    }
  },
  {
    path: '/ltc20/:name',
    name: 'ltc20',
    component: ListingsView,
    meta: {
      title: "LTC20"
    }
  },
  {
    path: '/litemaps',
    name: 'litemaps',
    component: ListingsView,
    meta: {
      title: "Litemaps"
    }
  },
  {
    path: '/rune/:id',
    name: 'rune',
    component: RuneView,
    meta: {
      title: "Rune"
    }
  },
  {
    path: '/runes',
    name: 'runes-list',
    component: RunesView,
    meta: {
      title: "Runes"
    }
  },
  {
    path: '/runes/:name',
    name: 'runes',
    component: ListingsView,
    meta: {
      title: "Runes"
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  document.title = to.meta?.title ? 'Chikun Market | ' + to.meta.title : 'Chikun Market'
})

export default router
