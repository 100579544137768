<template>
  <button class="back-button" @click="$router.go(-1)">
    <font-awesome-icon :icon="['fas', 'arrow-left']" />
    <span>Go back</span>
  </button>
  <div class="inscription">
    <a :href="'https://ordinalslite.com/inscription/' + inscription.inscriptionId">
      <img v-if="isImage(inscription.contentType)" :src="`https://ordinalslite.com/content/${inscription.inscriptionId}`" :alt="'Inscription ' + inscription.inscriptionNumber" class="inscription-content" loading="lazy"/>
      <iframe v-if="!isImage(inscription.contentType)" scrolling="no" loading="lazy" class="inscription-content inscription-preview" :src="`https://ordinalslite.com/preview/${inscription.inscriptionId}`"></iframe>
    </a>
    <div class="inscription-wrapper">
      <div class="inscription-header">
        <div v-if="!collection">
          <div class="inscription-title">
            Inscription
          </div>
          <div class="inscription-number">
            #{{ inscription.inscriptionNumber }}
          </div>
        </div>
        <div v-if="collection">
          <div class="inscription-title">
            {{ collection.item }}
          </div>
          <router-link :to="{ name: 'collection', params: { name: collection.collectionId }}">{{ collection.name }}</router-link>
          <div class="inscription-number">
            #{{ inscription.inscriptionNumber }}
          </div>
        </div>
      </div>
      <div v-if="listing" class="listing-details">
        <div style="font-weight: 500; margin-bottom: 0.5rem; color: rgba(255, 255, 255, 0.8);">Listed for sale</div>
        <div style="font-size: 1.3rem;"><img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(listing.price) }}</div>
        <div class="usd-value" style="padding-left: 2rem; line-height: 1.2;">${{ (satToBtc(listing.price) * usd_rate).toFixed(2) }}</div>
        <button class="button-listing" @click="buyItem(inscription.inscriptionId)" style="margin-top: 1rem;">Buy</button>
      </div>
      <div v-if="!listing && wallet_address == inscription.address" class="listing-details">
        <div style="font-weight: 500; margin-bottom: 0.5rem; color: rgba(255, 255, 255, 0.8);">List inscription for sale</div>
        <button class="button-listing" @click="listItem(inscription.inscriptionId)" style="margin-top: 1rem;">List</button>
      </div>
      <div class="inscription-details">
        <div class="inscription-detail">
          <div class="detail-name">Inscription ID</div>
          <div class="detail-value">{{ inscription.inscriptionId }}</div>
        </div>
        <div class="inscription-detail">
          <div class="detail-name">Inscription Number</div>
          <div class="detail-value">{{ inscription.inscriptionNumber }}</div>
        </div>
        <div class="inscription-detail">
          <div class="detail-name">Genesis Height</div>
          <div class="detail-value">{{ inscription.genesisHeight }}</div>
        </div>
        <div class="inscription-detail">
          <div class="detail-name">Owner</div>
          <div class="detail-value"><router-link :to="{ name: 'address', params: { address: inscription.address }}">{{ inscription.address }}</router-link></div>
        </div>
        <div class="inscription-detail">
          <div class="detail-name">Content Type</div>
          <div class="detail-value">{{ inscription.contentType }}</div>
        </div>
        <div class="inscription-detail">
          <div class="detail-name">Content Length</div>
          <div class="detail-value">{{ inscription.contentLength }}</div>
        </div>
        <div class="inscription-detail">
          <div class="detail-name">Location</div>
          <div class="detail-value">{{ inscription.location }}</div>
        </div>
        <div class="inscription-detail">
          <div class="detail-name">Output</div>
          <div class="detail-value">{{ inscription.output }}</div>
        </div>
        <div class="inscription-detail">
          <div class="detail-name">Value</div>
          <div class="detail-value">{{ inscription.outputValue }}</div>
        </div>
        <div v-if="inscription.rarity" class="inscription-detail">
          <div class="detail-name">Rarity Rank</div>
          <div class="detail-value">{{ inscription.rarity }}</div>
        </div>
      </div>
      <div v-if="inscription.traits" style="margin-top: 2rem;">
        <div style="font-size: 1.2rem; font-weight: 700; margin-bottom: 0.5rem;">Traits</div>
        <div class="attr-container">
          <div v-for="trait in inscription.traits" :key="trait.name" class="attr-badge">
            <div class="attr-name">{{ capitalizeFirstLetter(trait.attr) }}</div>
            <div class="attr-value">{{ breakCamelCase(trait.value) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import config from '@/config.js'
import utils from '@/utils.js'

export default {
  data() {
    return {
      isModalVisible: false,
      usd_rate: inject('usd_rate', 0),
      wallet_address: inject('wallet_address', false),
      listing: null,
      collection: null,
      inscription: {
        address: "ltc1",
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    fetch(`${config.apiUrl}/inscription/${to.params.id}`)
      .then(d => d.json())
      .then(data => {
        // Pass the fetched data to the next callback
        next(vm => {
          vm.inscription = data.result;
          vm.listing = data.result.listed;
          vm.collection = data.result.collection;
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        next(from.path);
      })
  },
  methods: {
    short_address(str) {
      return str.substr(0, 5) + '...' + str.substr(str.length-5, str.length);
    },
    satToBtc(sat) {
      return Number(sat) / Math.pow(10, 8);
    },
    buyItem(itemId) {
      console.log(`Buying item with ID ${itemId}`);
      this.emitter.emit("modal-buy-open", { listings: [itemId] });
    },
    listItem(itemId) {
      console.log(this.inscription_id);
      this.emitter.emit("modal-list-open", { inscriptions: [itemId] });
    },
    isImage(contentType) {
      if (contentType) {
        return contentType.startsWith("image");
      }
      return false;
    },
    ...utils
  },
};
</script>

<style scoped>
.inscription {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4rem;
  row-gap: 1rem;
  align-items: flex-start;
  margin-top: 1rem;
}

.inscription>* {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0px;
}

.inscription-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 30rem;
}

.inscription-header {
  display: flex;
  margin-bottom: 2rem;
}

@media (max-width: 576px) {
  .inscription-header {
    font-size: 1.5rem;
  }
}

.inscription-title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
}

.inscription-number {
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.8);
}

.inscription-content {
  width: 100%;
  min-width: 18rem;
  image-rendering: pixelated;
  max-height: 32rem;
  object-fit: contain;
  border-radius: 12px;
}

.inscription-preview {
  border: none;
  height: 32rem;
}

.listing-details {
  background-color: #ffffff0d;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.button-listing {
  width: 100%;
  font-weight: 800;
  color: #345D9D;;
  border: none;
  transition: all .25s ease-in-out;
  background-color: #D4D6E1;
}

.button-listing:hover {
  background-color: #345D9D;
  color: #fff
}

.buy-button {
  height: 30px;
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  background-color: #D4D6E1;
  font-weight: 700;
  color: #345D9D;
  cursor: pointer;
  transition: all .25s ease-in-out;
}

.inscription-details {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  overflow: hidden;
  border-radius: 12px;
}

.inscription-detail {
  padding: 0.75rem 1.5rem;
  background-color: #ffffff0d;
}

.detail-name {
  font-size: .9em;
  line-height: 1.2;
}

.detail-value {
  color: #fff;
  font-weight: 700;
  font-size: .95em;
  overflow-wrap: break-word;
}

.attr-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.attr-badge {
  border-radius: 12px;
  background: #ffffff0d;
  padding: 0.5rem 0.75rem;
  line-height: 1.2;
  min-width: 7rem;
}

.attr-name {
  font-size: .8rem;
  color: #fffc;
}

.attr-value {
  font-weight: 600;
  color: #fff;
}
</style>