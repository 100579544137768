<template>
  <table class="table">
    <thead class="table-head">
      <tr>
        <th class="number">#</th>
        <th class="token-img-column"></th>
        <th class="name">Rune</th>
        <th class="id">Id</th>
        <th class="supply">Supply</th>
        <th class="progress">Progress</th>
        <th class="price">Price</th>
        <th class="change_24h">24h %</th>
        <!-- <th class="volume">Volume</th> -->
        <!-- <th class="volume">Total volume</th>
        <th class="market_cap">Market Cap</th>
        <th class="holders">Holders</th>
        <th class="transactions">Transactions</th> -->
        <th class="created">Created</th>
        <th class="mint">Mint</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr v-for="token in items" :key="token.id" class="table-row clickable" @click="navToRune(token.spaced_rune)">
          <td class="number">{{ token.number }}</td>
          <td class="token-img-column">
            <img v-if="token.parent" class="token-img" loading="lazy" :src="getImageUrl(token.parent)" :title="`${token.symbol}`" @error="handleImageError($event)"/>
            <img v-if="!token.parent" class="token-img" loading="lazy" src="@/assets/rune.png">
          </td>
          <td class="name">{{ token.spaced_rune }}</td>
          <td class="id">{{ token.id }}</td>
          <td class="supply">
            {{ ((Number(token.premine) + ((token.terms.length != 0 ? token.terms.amount : 0) * token.mints)) / Math.pow(10, token.divisibility)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}  {{ token.symbol }}
          </td>
          <td class="progress">
            <!-- {{ token.terms.length != 0 ? Number(token.mints).toLocaleString() : '-' }}{{ token.terms.length != 0 ? token.terms.cap != 0 ? '/' + token.terms.cap : '' : '' }} -->
            <div v-if="token.terms.cap" style="margin-bottom: 0.25rem; text-align: center;">{{ (token.mints / token.terms.cap * 100).toFixed(0) }}%</div>
            <div v-if="token.terms.cap" class="progress-container" :title="token.mints + '/' + token.terms.cap">
              <div class="progress-bar  bg-success" role="progressbar" :style="{'width': (token.mints / token.terms.cap * 100) + '%'}"></div>
            </div>
            <div v-if="!token.terms.cap" style="margin-bottom: 0.25rem; text-align: center;">100%</div>
            <div v-if="!token.terms.cap" class="progress-container" :title="token.premine + '/' + token.premine">
              <div class="progress-bar  bg-success" role="progressbar" :style="{'width': '100%'}"></div>
            </div>
          </td>
          <td :class="{'price': true, 'no-data': token.market.floor_price <= 0 }">
            <div v-if="token.market.floor_price > 0">
                <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(token.market.floor_price) }}
                <div class="usd-value">${{ (satToBtc(token.market.floor_price) * usd_rate).toLocaleString() }}</div>
            </div>
            <span v-if="!token.market.floor_price || token.market.floor_price <= 0">-</span>
          </td>
          <td :class="{'change_24h': true, 'no-data': token.market.change_24h <= 0 }" :style="{ color: token.market.change_24h > 0 ? 'lawngreen' : (token.market.change_24h < 0 ? 'tomato' : 'inherit') }">
            <div v-if="token.market.change_24h">{{ token.market.change_24h }}%</div>
            <span v-if="!token.market.change_24h">-</span>
          </td>
          <td class="created" :title="new Date(token.timestamp * 1000)">{{ new Date(token.timestamp * 1000).toUTCString() }}</td>
          <td class="mint">
            <button class="table-button" :disabled="!token.mintable" @click.stop="mintRune(token.id)">Mint</button>
          </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { inject } from 'vue'
import utils from '@/utils';

export default {
  data() {
    return {
      placeholderImg: require('@/assets/rune.png'),
      usd_rate: inject('usd_rate', 0),
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
  },
  methods: {
    navToRune(name) {
      this.$router.push({
        name: "runes",
        params: {
            name: name,
        },
      });
    },
    mintRune(runeId) {
      console.log(this.placeholderImg)
      console.log(`Mint rune with ID ${runeId}`);
      this.emitter.emit("modal-mint-open", { rune_id: runeId });
    },
    getImageUrl(tokenParent) {
      return `https://ordinalslite.com/content/${tokenParent}`;
    },
    handleImageError(event) {
      event.target.src = this.placeholderImg;
    },
    ...utils
  }
}
</script>

<style>
.item-container {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.table-row .token-img {
  width: 42px;
  height: 42px;
  vertical-align: middle;
  object-fit: cover;
}

.table td {
  width: auto;
}

div.token-img {
  display: flex;
  background-color: #212121;
  font-size: .7rem;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
}

.token-img {
  border-radius: 50%;
  image-rendering: pixelated;
}

th.supply, th.volume, th.market_cap, th.change_24h, td.supply {
  white-space: nowrap;
}

.table-row .usd-value {
  margin-left: 20px;
  font-weight: 500;
  font-size: 90%;
  line-height: 1;
  color: #fffc;
}

.table-button {
  height: 30px;
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
  background-color: #D4D6E1;
  font-weight: 700;
  color: #345D9D;
  cursor: pointer;
  text-align: center;
  transition: all .25s ease-in-out;
}

.table-button:enabled:hover {
  background-color: #345d9d;
  color: #fff;
}

.progress-container {
  display: flex;
  width: auto;
  height: 0.25rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: 5px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width .6s ease;
}
</style>