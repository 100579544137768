<template>
  <table class="table">
    <thead class="table-head">
      <tr>
        <th class="spaced_rune">Rune</th>
        <th class="balance">Balance</th>
        <th></th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr v-for="rune in items" :key="rune.spaced_rune" class="table-row clickable" @click="navToRune((rune.id) ? rune.id : rune.spaced_rune)">
        <td class="spaced_rune">{{ rune.spaced_rune }}</td>
        <td class="balance">{{ amountToDecimal(Number(rune.amount), rune.divisibility).toLocaleString() }} {{ rune.symbol }}</td>
        <td style="display: flex; align-items: end; justify-content: flex-end;">
          <button v-if="is_owner" class="table-button send-button" @click.stop.prevent="showRuneSendModal(rune.spaced_rune, rune.amount, rune.divisibility, rune.symbol)">
            <font-awesome-icon :icon="['fas', 'paper-plane']" />
            <span>Send</span>
          </button>
          <button class="table-button details-button" style="margin-left: 0.5rem;" @click.stop.prevent="showRuneBalanceDetailsModal(rune)">
            <font-awesome-icon :icon="['fas', 'search']"/>
            <span>Details</span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
  
<script>
import utils from '@/utils';

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    is_owner: {
      type: Boolean,
    }
  },
  methods: {
    navToRune(id) {
      console.log(`Rune ${id} nav`);
      this.$router.push({
        name: "rune",
        params: {
          id: id,
        },
      });
    },
    showRuneSendModal(rune, amount, divisibility, symbol) {
      console.log("modal");
      this.emitter.emit("modal-rune-send-open", { spaced_rune: rune, amount: amount, divisibility: divisibility, symbol: symbol });
      event.preventDefault();
    },
    showRuneBalanceDetailsModal(rune) {
      console.log("modal");
      this.emitter.emit("modal-rune-balance-details-open", { rune_details: rune, is_owner: this.is_owner });
      event.preventDefault();
    },
    ...utils
  }
}
</script>
  
<style scoped>
.item-container {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.table-button {
  height: 30px;
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
  background-color: #D4D6E1;
  font-weight: 700;
  color: #345D9D;
  cursor: pointer;
  text-align: center;
  transition: all .25s ease-in-out;
}

button>svg {
  margin-right: 0.5rem;
}

.table-button:enabled:hover {
  background-color: #345d9d;
  color: #fff;
}

.send-button {
  background-color: #3c1295;
  color: #d94fff;
}

.send-button:enabled:hover {
  background-color: #9d12c8;
  color: #fff;
}
</style>