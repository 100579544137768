<template>
  <div v-show="isModalVisible" class="modal">
    <div class="modal-backdrop" @click="close"></div>
    <div class="modal-wrap">
      <div class="modal-window">
        <button
            type="button"
            class="btn-close"
            @click="close"
          >
          x
        </button>
        <h1 class="modal-header">
          <slot name="header">
            List LTC-20
          </slot>
        </h1>
  
        <div class="modal-body">
          <slot name="body">
            <div class="body-content">
              To list an LTC-20 Token you must first create a Transfer Inscription from your current address containing the tick and amount you would like to list (single listing).
            </div>
            <div class="body-grid">
              <div class="modal-row">
                <div>Ticker:</div>
                <div class="modal-row-value">
                  {{ token_ticker }}
                </div>
              </div>
              <div class="modal-row">
                <div>Available Balance:</div>
                <div class="modal-row-value">
                  {{ token_amount }}
                </div>
              </div>
              <div class="modal-row" style="margin-top: 1rem;">
                <div>Amount To Transfer:</div>
                <div class="modal-row-value">
                  <input v-model="token_transfer" :style="{ color: token_transfer > token_amount ? 'red' : 'inherit' }" class="modal-input" type="number" min="1" :max="token_amount" onkeypress="return event.charCode >= 48 && event.charCode <= 57" :placeholder="token_amount" step="1">
                </div>
              </div>
            </div>
            <div class="content-container" style="margin-top: 1rem;" @click="copyContent">
              <div class="code" style="padding: 1rem; text-align: center;">
                {{ ltc20InscriptionContent }}
                <tippy to="parent">Copy to Clipboard</tippy>
              </div>
              <div style="margin-top: 1rem; text-align: center;">
                Click to Copy, and then inscribe the text content to this address to create a transfer inscription using an inscription service.
              </div>
            </div>
          </slot>
        </div>
        <div v-if="errorMessage" class="error-message" style="color: red; text-align: center; padding-bottom: 1rem;">{{ errorMessage }}</div>
        <button
          type="button"
          class="modal-button confirm-button"
          @click="close"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import { Tippy } from 'vue-tippy';
import { useToast } from "vue-toastification";
import utils from '@/utils.js'

export default {
  name: 'ModalComponent',
  
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isReady: false,
      isModalVisible: false,
      errorMessage: null,
      content: null,
      token_ticker: null,
      token_amount: 0,
      token_transfer: null,
      usd_rate: inject('usd_rate', 0),
      available_balance: inject('wallet_balance', 0),
      wallet_connected: inject('wallet_connected', false),
      wallet_address: inject('wallet_address', false),
    };
  },
  components: {
    Tippy
  },
  methods: {
    close() {
      this.isModalVisible = false;
      this.errorMessage = null;
    },
    copyContent() {
      const input = document.createElement('input');
      input.value = this.ltc20InscriptionContent;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
    },
    ...utils
  },
  mounted() { 
    this.emitter.on("modal-ltc20-list-open", (parameters) => {
      this.isReady = false;
      this.isModalVisible = true;
      this.errorMessage = null;
      this.token_ticker = parameters.ltc20_ticker;
      this.token_amount = parameters.ltc20_amount;
    });
  },
  computed: {
    ltc20InscriptionContent: function() {
      return `{"p":"ltc-20","op":"transfer","tick":"${this.token_ticker}","amt":"${this.token_transfer}"}`
    }
  }
};
</script>

<style scoped>
  .modal-body {
    position: relative;
    padding: 0 10px 20px 10px;
  }

  .modal-backdrop {
    position: fixed;
    border: none;
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    opacity: 0.6;
    transition: opacity .15s ease;
    background-color: #000;
  }

  .hystmodal__shadow--show {
    pointer-events: auto;
    opacity: .6;
  }

  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    z-index: 99;
  }

  .modal-wrap {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    min-height: 100%;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    z-index: 97;
  }

  .modal-window {
    display: flex;
    flex-direction: column;
    margin: 50px 0;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    width: 500px;
    max-width: calc(100% - 1rem);
    min-height: 500px;
    overflow: visible;
    pointer-events: all;
    transition: transform .2s ease 0s,opacity .2s ease 0s;
    transform: scale(.9);
    background-color: #ffffff1f;
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    border-radius: 12px;
    padding: 1.5rem;
  }

  @media (max-width: 576px) {
    .modal-window {
      padding: .75rem;
    }
  }

  .modal-input {
    margin-right: 0.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    width: 6rem;
    text-align: left;
    font-size: inherit;
  }

  .modal-row.modal-input {
    padding: 0.1rem;
  }

  .modal-input:focus {
    border-bottom: 1px solid #007aff;
  }

  .modal-input:enabled {
    border-bottom: 1px solid tan;
  }

  .modal-amount {
    margin-top: 1em;
    font-size: larger;
  }

  .body-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .body-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .body-grid {
    margin-top: 1rem;
  }

  .btn-close {
    position: absolute;
    z-index: 10;
    display: block;
    top: 14px;
    right: 14px;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    font-size: 0;
    cursor: pointer;
    outline: none;
  }

  .modal-box {
    max-height: 26rem;
    overflow-y: auto
  }

  @media (max-width: 576px) {
    .modal-box {
      max-height: 20rem;
    }
  }

  .modal-row {
    display: flex;
    justify-content: center;
    line-height: 2;
  }

  .modal-row>div {
    width: 50%;
  }

  .modal-row>div:first-child {
    text-align: right;
    padding-right: 2rem;
  }

  .modal-row .modal-row-value {
    text-align: left;
  }

  .modal-row .modal-row-input {
    padding: .1rem;
  }

  @media (max-width: 576px) {
    .modal-row>div:first-child {
      padding-right:1rem;
    }
  }

  .modal-button {
    margin-top: auto;
  }

  .modal-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .item-text {
    text-align: center;
    margin-top: 2.75rem;
    margin-bottom: 2.75rem
  }

  .modal-total {
    margin-top: 1em;
    font-size: larger;
  }

  @media all and (max-width: 767px) {
    .modal-window {
      margin: 0;
    }
  }

  .modal-header {
    margin-top: 0;
    text-align: center;
    font-weight: 600;
  }

  @media (max-width: 576px) {
    .modal-header {
      font-size: 1.5rem;
    }
  }

  .content-container .code {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
    overflow-wrap: break-word;
  }
</style>