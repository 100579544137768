<template>
    <button class="wallet-button header-element">
        <span class="wallet-icon">
            <font-awesome-icon icon="wallet"/>
        </span>
        <span class="wallet-text">
            {{ !wallet_connected ? "Connect Wallet" : short_address(wallet_address) }}
        </span>
        <tippy interactive trigger="click" arrow="false" placement="bottom-end" to="parent" content-tag="div" content-class="wallet-container" theme="wallet" maxWidth="400px">
            <template v-if="screen == 0">
                <!-- Select Wallet -->
                <div class="wallet-header" style="justify-content: center;">Select Wallet</div>
                <div class="buttons-container">
                    <button class="create-button white" @click="selectWallet('builtin')">
                        <span class="wallet-img">
                            <font-awesome-icon icon="wallet" style="padding-left: 2px; padding-top: 5px;"/>
                        </span>
                        <span class="wallet-name">Built-in Web Wallet</span>
                    </button>
                    <button class="create-button white" @click="selectWallet('litescribe')">
                        <span class="wallet-img">
                            <img src="@/assets/litescribe.png"/>
                        </span>
                        <span class="wallet-name">Litescribe Wallet</span>
                    </button>
                    <div style="margin-top: -1rem; text-align: center; color: tomato;">Runes not supported</div>
                    <!-- <button class="create-button white" @click="selectWallet('gate')">
                        <span class="wallet-img">
                            <img src="@/assets/gateio.png"/>
                        </span>
                        <span class="wallet-name">Gate Wallet</span>
                    </button> -->
                </div>
            </template>
            <template v-if="screen == 1">
                <!-- Chikun Market Wallet -->
                <div class="wallet-header" style="justify-content: center;">Chikun Market Wallet</div>
                <div class="wallet-info">Chikun Market comes with built-in wallet, there is no need to download any browser extension. Your seed phrase and private keys are stored in the browser and are never sent to the server.</div>
                <div class="buttons-container">
                    <button class="create-button white" @click="generateSeedPhrase()">Create new wallet</button>
                    <button class="import-button" @click="nextScreen(2)">Import wallet</button>
                </div>
            </template>
            <template v-if="screen == 2">
                <!-- Import Wallet -->
                <div class="import-wallet-header">Import wallet</div>
                <textarea v-model="seed_phrase_input" class="seed-textarea" rows="6" placeholder="Enter your secret phrase here (mnemonic or private key)" autocapitalize="none"></textarea>
                <div class="error-message">{{ error_message }}</div>
                <div style="margin-top: auto; display: flex;">
                    <button class="outline" @click="nextScreen(1)">Go back</button>
                    <button class="white" style="margin-left: 1rem; flex-grow: 1;" @click="importWallet">Import wallet</button>
                </div>
            </template>
            <template v-if="screen == 3">
                <!-- Create Password -->
                <div class="set-password-title">Set your wallet password</div>
                <div class="password-container password-input">
                    <input v-model="password_one" :type="show_password_one ? 'text' : 'password'" placeholder="Enter password">
                    <font-awesome-icon v-if="show_password_one" :icon="['fas', 'eye']" @click="showPasswordOne(false)"/>
                    <font-awesome-icon v-if="!show_password_one" :icon="['fas', 'eye-slash']" @click="showPasswordOne(true)"/>
                </div>
                <div class="password-container password-input">
                    <input v-model="password_two" :type="show_password_two ? 'text' : 'password'" placeholder="Confirm password">
                    <font-awesome-icon v-if="show_password_two" :icon="['fas', 'eye']" @click="showPasswordTwo(false)"/>
                    <font-awesome-icon v-if="!show_password_two" :icon="['fas', 'eye-slash']" @click="showPasswordTwo(true)"/>
                </div>
                <div class="error-message">{{ error_message }}</div>
                <div class="button" style="margin-top: auto; display: flex;">
                    <button class="outline" @click="createPassword(true)">Skip</button>
                    <button class="white" style="margin-left: 1rem; flex-grow: 1;" @click="createPassword(false)">Set password</button>
                </div>
            </template>
            <template v-if="screen == 4">
                <!-- Password Screen -->
                <div class="enter-password-message">Enter your wallet password</div>
                <div class="password-container password-input">
                    <input v-model="password" :type="show_password ? 'text' : 'password'" placeholder="Password">
                    <font-awesome-icon v-if="show_password" :icon="['fas', 'eye']" @click="showPassword(false)"/>
                    <font-awesome-icon v-if="!show_password" :icon="['fas', 'eye-slash']" @click="showPassword(true)"/>
                </div>
                <div class="error-message">{{ error_message }}</div>
                <button class="unlock-button white" @click="unlockWallet()">Unlock</button>
            </template>
            <template v-if="screen == 5">
                <!-- Wallet Screen -->
                <div class="wallet-options">
                    <span class="wallet-addr" @click="copyAddress">{{ short_address(wallet_address) }}
                        <font-awesome-icon icon="copy"/>
                        <tippy to="parent">Copy to Clipboard</tippy>
                        <tippy to="parent" ref="tippyCopied" theme="success" trigger="manual">Copied</tippy>
                    </span>
                    <router-link v-if="leaderboard" class="wallet-leaderboard" style="margin-left: 0.5rem;" :to="{ name: 'leaderboard' }">
                        {{ leaderboard.points.toLocaleString() }} (#{{ leaderboard.rank.toLocaleString() }})
                        <tippy to="parent">Leaderboard Points</tippy>
                    </router-link>
                    <div style="margin-left: auto;">
                        <button class="button-icon" @click="refreshWallet">
                            <font-awesome-icon v-if="!loading" :icon="['fas', 'rotate']"/>
                            <font-awesome-icon class="fa-spin" v-if="loading" :icon="['fas', 'spinner']" />
                        </button>
                        <button class="button-icon">
                            <font-awesome-icon :icon="['fas', 'ellipsis-vertical']" style="width: 22px"/>
                            <tippy interactive trigger="click" placement="bottom-end" to="parent" content-tag="div" theme="dpn">
                                <div>
                                    <div class="menu-opt" @click="showSend()">
                                        <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" />
                                        Send Litecoin
                                    </div>
                                    <div class="menu-opt" @click="createDummies()">
                                        <font-awesome-icon :icon="['fas', 'plus']" />
                                        Create Dummies
                                    </div>
                                    <div v-if="wallet_provider.getSelectedWalletName() == 'builtin'" class="menu-opt" @click="showSecrets()">
                                        <font-awesome-icon :icon="['fas', 'key']" />
                                        Show Secrets
                                    </div>
                                    <div v-if="wallet_provider.getSelectedWalletName() == 'builtin'" class="menu-opt" @click="lockWallet()">
                                        <font-awesome-icon :icon="['fas', 'lock']" />
                                        Lock Wallet
                                    </div>
                                    <div v-if="wallet_provider.getSelectedWalletName() == 'builtin'" class="menu-opt" @click="nextScreen(8)" style="color: tomato;">
                                        <font-awesome-icon :icon="['fas', 'trash-can']" />
                                        Remove Wallet
                                    </div>
                                </div>
                            </tippy>
                        </button>
                    </div>
                </div>
                <div style="margin: 0.5rem 0px;">
                    <div style="display: flex; justify-content: space-between;">
                        <span class="wallet-balance">
                            <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(wallet_balance) }} <span class="usd-value">(${{ (satToBtc(wallet_balance) * usd_rate).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }})</span>
                        </span>
                        <span class="address-history">
                            <a :href="`${wallet_provider.MEMPOOL_URL}/address/${wallet_address}`" target="_blank" style="font-size: 0.8rem;">
                                View History
                            </a>
                        </span>
                    </div>
                </div>
                <div v-if="!wallet_backup && wallet_provider.getSelectedWalletName() == 'builtin'" class="backup-reminder">You have not backed up your wallet seed phrase <a style="font-weight: 600;" @click="showSecrets()">Backup now</a></div>
                <div class="tabs-wrapper hide-scroll">
                    <ul class="tabs small">
                        <li :class="{ 'active': tab == 0 }" @click="showTab(0)">Assets</li>
                        <li :class="{ 'active': tab == 1 }" @click="showTab(1)">Listings</li>
                    </ul>
                </div>
                <div v-if="tab == 0" class="wallet-list">
                    <div v-if="assets.length == 0 || (assets['collections'].length == 0 && assets['litemaps_total'] == 0) && assets['ltc20'].length == 0" class="empty">No active listings</div>
                    <div v-if="assets['litemaps_total'] > 0" class="section">Litemaps</div>
                    <div v-if="assets['litemaps_total'] > 0" class="asset-position" @click="navToAddressPage">
                        <img class="wallet-inscription listing-preview" src="@/assets/litemap.png" loading="lazy">
                        <div class="asset-name">Litemaps</div>
                        <div class="asset-total">{{ assets['litemaps_total'] }}</div>
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </div>
                    <div v-if="assets['collections'] && assets['collections'].length > 0" class="section">Collections</div>
                    <div v-for="collection in assets['collections']" :key="collection.name" class="asset-position" @click="navToAddressPage">
                        <img class="wallet-inscription listing-preview" :src="collection.collection.image" loading="lazy">
                        <div class="asset-name">{{ collection.collection.name }}</div>
                        <div class="asset-total">{{ collection.items }}</div>
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </div>
                    <div v-if="assets['runes'] && assets['runes'].length > 0" class="section">Runes</div>
                    <div v-for="rune in assets['runes']" :key="rune.spaced_rune" class="asset-position" @click="navToAddressPage">
                        <div class="asset-name">{{ rune.spaced_rune }}</div>
                        <div class="asset-total">{{ amountToDecimal(Number(rune.amount), rune.divisibility).toLocaleString() }}</div>
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </div>
                    <div v-if="assets['ltc20'] && assets['ltc20'].length > 0" class="section">LTC20</div>
                    <div v-for="token in assets['ltc20']" :key="token.ticker" class="asset-position" @click="navToAddressPage">
                        <div class="asset-name">{{ token.ticker }}</div>
                        <div class="asset-total">{{ Number(token.transferableBalance) + Number(token.availableBalance) }}</div>
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </div>
                </div>
                <div v-if="tab == 1" class="wallet-list">
                    <div v-if="listings.length == 0" class="empty">No active listings</div>
                    <div v-for="item in listings" :key="item.inscriptionId" class="row-listing">
                        <router-link v-if="item.type != 5" :to="{ name: 'inscription', params: { id: item.inscriptionId }}">
                            <iframe scrolling="no" loading="lazy" class="listing-preview" :src="`https://ordinalslite.com/preview/${item.inscriptionId}`"></iframe>
                        </router-link>
                        <div>
                            <div v-if="item.content" class="listing-name">{{ item.content }}</div>
                            <div v-if="item.token" class="listing-name">{{ item.token }}</div>
                            <div class="price-info">
                                <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(item.price).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8}) }} (${{ (satToBtc(item.price) * usd_rate).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }})
                            </div>
                        </div>
                        <button style="margin-left: auto;" @click="cancelListing(item.inscriptionId)" :disabled="isCancelling(item.inscriptionId)">
                            <font-awesome-icon v-if="isCancelling(item.inscriptionId)" class="fa-spin" :icon="['fas', 'spinner']" />
                            {{ isCancelling(item.inscriptionId) ? "" : "Delist" }}
                        </button>
                    </div>
                </div>
            </template>
            <template v-if="screen == 6">
                <!-- Send Screen -->
                <div class="wallet-header">
                    <font-awesome-icon :icon="['fas', 'arrow-left']" @click="nextScreen(5)"/>
                    Send Litecoin
                </div>
                <div style="display: flex; align-items: center; margin-bottom: 1rem;">
                    <span style="margin-right: 0.5rem;">To:</span>
                    <input v-model="send_address" class="modal-input" type="text" placeholder="Enter an address" style="width: 100%; padding: 0.3em 0.6em;">
                </div>
                <div style="display: flex; align-items: center;">
                    <span style="margin-right: 0.5rem;">Amount:</span>
                    <img src="@/assets/Litecoin.png" class="litecoin-icon">
                    <input v-model="send_amount" :class="{ 'modal-input': true, 'input-invalid': send_amount > satToBtc(wallet_balance) }" type="number" placeholder="Enter an amount" style="width: 100%; padding: 0.3em 0.6em; margin-right: 0px;">
                </div>
                <div class="modal-row" style="font-size: 0.9em; margin-top: auto;">
                    <div style="padding-right: 1rem;">Available balance:</div>
                    <div class="value">
                        <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(wallet_balance).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8}) }} <span class="usd-value">(${{ (satToBtc(wallet_balance) * usd_rate).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }})</span>
                    </div>
                </div>
                <div class="modal-row" style="font-size: 0.9em;">
                    <div style="padding-right: 1rem;">Network fee:</div>
                    <div class="value">
                        <img src="@/assets/Litecoin.png" class="litecoin-icon">≈0.00002 <span data-v-0b04a4b5="" class="usd-value">(${{ (satToBtc(200) * usd_rate).toFixed(5) }})</span>
                    </div>
                </div>
                <button class="modal-button" :disabled="!isValidAddress(send_address) || send_amount == 0 || send_amount > satToBtc(wallet_balance) || send_loading" style="width: 100%; margin-top: 1rem;" @click="sendLitecoin()">
                    <font-awesome-icon v-if="send_loading" class="fa-spin" style="margin-right: 0.5rem" :icon="['fas', 'spinner']" />
                    {{ !isValidAddress(send_address) ? "Enter Valid Address" : (send_amount == 0 ? "Enter an Amount" : (send_amount > satToBtc(wallet_balance) ? "Invalid amount" : "Send Litecoin") ) }}
                </button>
            </template>
            <template v-if="screen == 7">
                <!-- Secrets Screen -->
                <div class="seed-title">Your seed phrase:</div>
                <div class="seed">
                    <div v-for="(word, index) in seed_phrase" :key="index" class="seed-word">
                        <div class="index">{{ index + 1 }}.</div>
                        <div class="value">{{ word }}</div>
                    </div>
                </div>
                <div style="display: flex; justify-content: center; margin-top: 0.5rem; margin-bottom: 1rem;">
                    <button class="small" @click="copySeedPhrase">
                        <font-awesome-icon icon="copy"/>
                        Copy to clipboard
                        <tippy to="parent" ref="tippyCopied" theme="success" trigger="manual">Copied</tippy>
                    </button>
                </div>
                <div style="background-color: rgba(0, 0, 0, 0.2); padding: 0.5rem; font-size: 0.8rem; border-radius: 12px; margin-bottom: 1rem; text-align: justify;">Anyone who knows these words can access your funds. Before importing to another wallet make sure it supports the Litecoin Ordinals protocol.</div>
                <div class="button" style="display: flex;">
                    <button :class="{ 'outline': !wallet_backup, 'back-button': true }" style="flex-grow: 1;" @click="nextScreen(5)">Go back</button>
                    <button v-if="!wallet_backup" class="saved-button white" style="margin-left: 0.5rem; flex-grow: 1;" @click="backupWallet">I've saved these words</button>
                </div>
            </template>
            <template v-if="screen == 8">
                <!-- Remove Wallet Screen -->
                <div class="screen-title">Are you sure you want to remove this wallet?</div>
                <div style="background-color: rgba(0, 0, 0, 0.2); padding: 0.5rem; border-radius: 12px; margin-top: 1rem; margin-bottom: 1rem; text-align: justify;">Make sure you have backed up your wallet seed phrase before you remove this wallet as this action is irreversible and permanent.</div>
                <div class="button" style="display: flex; margin-top: auto;">
                    <button :class="{ 'outline': !wallet_backup, 'back-button': true }" style="flex-grow: 1;" @click="nextScreen(5)">Go back</button>
                    <button class="remove-button red" style="margin-left: 0.5rem; flex-grow: 1;" @click="removeWallet()">Remove Wallet</button>
                </div>
            </template>
        </tippy>
    </button>
</template>
  
<script>
import { Tippy, useTippy } from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import { useToast } from "vue-toastification";
import { Buffer } from 'buffer';

window.Buffer = Buffer;

import * as bip39 from '@scure/bip39';
import { wordlist } from '@scure/bip39/wordlists/english';

import { ref, inject } from 'vue';
import config from '@/config.js'
import utils from '@/utils';

export default {
    setup() {
        const toast = useToast();
        const tippyCopied = ref(null);

        useTippy(tippyCopied);

        return { toast, tippyCopied };
    },
    components: [ Tippy ],
    data() {
        return {
            wallet_provider: this.$walletProvider,
            wallet_address: inject('wallet_address', ''),
            wallet_balance: inject('wallet_balance', 0),
            wallet_balance_safe: inject('wallet_balance_safe', 0),
            wallet_connected: inject('wallet_connected', false),
            usd_rate: inject('usd_rate', 0),
            tab: 0,
            loading: false,
            assets: [],
            listings: [],
            listings_cancelling: [],
            leaderboard: null,
            showLegacyWarning: true,
            showNestedWarning: true,
            screen: 0,
            locked: false,
            send_address: null,
            send_amount: 0,
            send_loading: false,
            show_password: false,
            password: "",
            show_password_one: false,
            show_password_two: false,
            password_one: null,
            password_two: null,
            password_required: false,
            seed_phrase: ["abuse","abuse","abuse","abuse","abuse","abuse","abuse","abuse","abuse","abuse","abuse","abuse"],
            seed_phrase_input: null,
            wallet_data: null,
            wallet_backup: false,
            error_message: null,
        };
    },
    methods: {
        showTab(number) {
            this.tab = number;
            if (this.tab == 1) {
                this.fetchListings();
            }
        },
        showPassword(show) {
            this.show_password = show;
        },
        showPasswordOne(show) {
            this.show_password_one = show;
        },
        showPasswordTwo(show) {
            this.show_password_two = show;
        },
        createPassword(skip) {
            var passphrase = "";
            this.error_message = null;

            if (!skip) {
                if (this.password_one == null || this.password_one.length < 5) {
                    this.error_message = "Passwords needs to be at least 5 characters"
                    return;
                }
                if (this.password_one != this.password_two) {
                    this.error_message = "Passwords don't match"
                    return;
                }
                passphrase = this.password_one;
            }

            this.password_one = null;
            this.password_two = null;

            this.password = passphrase;
            this.wallet_backup = false;

            this.createWallet();
            this.wallet_provider.setSeedPhrase(this.seed_phrase.join(' '));
            this.connectWallet();
            this.screen = 5;
        },
        generateSeedPhrase() {
            var mnemonic = bip39.generateMnemonic(wordlist);

            this.seed_phrase = mnemonic.trim().split(' ');
            this.screen = 3;
        },
        createWallet() {
            console.log(this.seed_phrase.join(' '));
            console.log(this.password);
            var data = { 'seedphrase': this.seed_phrase.join(' '), 'backup': this.wallet_backup }
            var encrypted = this.encryptData(JSON.stringify(data), this.password);
            localStorage.setItem('data', encrypted);
        },
        importWallet() {
            if (!this.seed_phrase_input || this.seed_phrase_input.length == 0) {
                this.error_message = "Missing seed phrase";
                return;
            }

            var seed_phrase_validate = this.seed_phrase_input.trim().split(' ');

            if (seed_phrase_validate.length != 12 && seed_phrase_validate.length != 24) {
                this.error_message = "Incorrect length";
                return;
            }

            this.seed_phrase = seed_phrase_validate;
            this.seed_phrase_input = null;
            this.error_message = null;

            // go to create password screen
            this.screen = 3;
        },
        unlockWallet() {
            // console.log(this.password)
            var encrypted = localStorage.getItem('data');

            try {
                var decrypted = this.decryptData(encrypted, this.password);
            } catch (ex) {
                this.error_message = "Incorrect Password";
                return;
            }

            if (decrypted == null || decrypted.length == 0) {
                this.error_message = "Incorrect Password";
                return;
            }

            // console.log(encrypted);
            // console.log(decrypted);

            this.error_message = "";

            try {
                var data = JSON.parse(decrypted)
                this.seed_phrase = data['seedphrase'].split(' ');
                this.wallet_backup = data['backup'];
            } catch (ex) {
                return;
            }

            this.wallet_provider.setSeedPhrase(this.seed_phrase.join(' '));
            this.connectWallet();
            this.screen = 5;
        },
        lockWallet() {
            this.screen = 4;
            this.password = "";
            this.seed_phrase = null;
            this.wallet_provider.lockWallet();
        },
        removeWallet() {
            this.screen = 0;
            this.password = "";
            this.seed_phrase = null;
            localStorage.removeItem('data');
        },
        showSecrets() {
            this.screen = 7;
        },
        copySeedPhrase() {
            const input = document.createElement('input');
            input.value = this.seed_phrase.join(' ');
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);

            this.tippyCopied.show();
            setTimeout(() => {
                this.tippyCopied.hide();
            }, 2000);
        },
        backupWallet() {
            this.wallet_backup = true;
            this.createWallet();
            this.screen = 5;
        },
        showSend() {
            this.screen = 6;
        },
        nextScreen(screen) {
            this.error_message = null;
            this.screen = screen;
        },
        async selectWallet(wallet) {
            this.wallet_provider.selectWallet(wallet);
            if (wallet == 'builtin') {
                if (localStorage.getItem('data') === null) {
                    this.nextScreen(1);
                } else {
                    this.nextScreen(4);
                }
            } else {
                try {
                    if (this.wallet_provider.isWalletInstalled()) {
                        await this.connectWallet();
                        this.nextScreen(5);
                    }
                } catch (e) {
                    console.log(e)
                    this.toast.error(e.message, {
                        position: "bottom-right"});
                }
            }
        },
        copyAddress() {
            const input = document.createElement('input');
            input.value = this.wallet_address;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);

            this.tippyCopied.show();
            setTimeout(() => {
                this.tippyCopied.hide();
            }, 2000);
        },
        async connectWallet() {
            // if (window.getInstalledWalletName() == null) {
            //     this.toast.info("You must have Litescribe Wallet Installed");
            //     return;
            // }
            if (!this.wallet_provider.wallet_connected) {
                try {
                    await this.wallet_provider.initialize();
                } catch (e) {
                    console.log(e)
                    this.toast.error(e.message, {
                        position: "bottom-right"});
                    this.screen = 0;
                    return;
                }

                if (this.wallet_provider.getSelectedWalletName() == 'litescribe') {
                    window.litescribe.on('disconnect', () => {
                        console.log('Disconnected');
                        this.wallet_connected = false;
                    });

                    window.litescribe.on('accountsChanged', accounts => {
                        if (accounts != null && accounts.length > 0) {
                            console.log(`Account Changed: ${accounts[0]}` )
                            this.fetchWallet();
                        } else {
                            this.wallet_connected = false;
                        }
                    });
                } else if (this.wallet_provider.getSelectedWalletName() == 'gate') {
                    window.gatewallet.on('gateAccountChange', (gateAccountInfo) => {
                        console.log('---gateAccountInfo---', gateAccountInfo)
                    });
                }

                this.fetchWallet();
                this.wallet_connected = true;
            }
        },
        async createDummies() {
            await this.wallet_provider.updatePayerAddress(this.wallet_address);

            console.log(this.wallet_provider.dummyUtxos);

            if (this.wallet_provider.dummyUtxos && this.wallet_provider.dummyUtxos.length >= 2) {
                this.toast.info("You already have enough dummy utxos", {
                    position: "bottom-right"});
                return;
            }

            var unsigned_dummy_psbt = await this.wallet_provider.generatePSBTGeneratingDummyUtxos(this.wallet_address, this.wallet_provider.NUMBER_DUMMIES_REQUIRED - ((this.wallet_provider.dummyUtxos == null) ? 0 : this.wallet_provider.dummyUtxos.length), this.wallet_provider.paymentUtxos);
            await this.wallet_provider.signPSBTUsingWalletAndBroadcast(unsigned_dummy_psbt).catch(reason => {
                console.error(reason);
            });
        },
        async refreshWallet() {
            this.loading = true;
            this.listings_cancelling = [];
            await this.fetchWallet();
            this.loading = false;
        },
        async fetchWallet() {
            var address = await this.wallet_provider.getWalletAddress();
            if (address.startsWith('L') && this.showLegacyWarning) {
                this.toast.warning("Please use the Native Segwit (ltc1...) address type instead for best user experience.");
                this.showLegacyWarning = false;
            }

            if (address.startsWith('M') && this.showNestedWarning) {
                this.toast.error("Nested Segwit (M...) addresses are not supported. Please use Native Segwit (ltc1...) addresses instead.");
                this.showNestedWarning = false;
            }

            this.wallet_address = address;
            console.log(address);
            try {
                var balance_resp = await this.wallet_provider.getBalance();
                this.wallet_balance = balance_resp.total;
                this.fetchCollections();
                this.fetchListings();
                this.fetchRunes();
                this.fetchLeaderboard();
                console.log(this.assets);
            } catch (ex) {
                console.log(ex);
            }
        },
        async fetchCollections() {
            var collections = await fetch(`${config.apiUrl}/address/collections?more=false&address=${this.wallet_address}`)
                .then(d => d.json())
            console.log(collections);
            if (collections) {
                this.assets = collections.result;
            }
        },
        async fetchListings() {
            var listed_resp = await fetch(`${config.apiUrl}/market/listings?address=${this.wallet_address}`)
                .then(d => d.json());
            this.listings = listed_resp.result;
        },
        async cancelListing(inscription_id) {
            this.setCancelling(inscription_id);

            const response = await fetch(`${config.apiUrl}/market/cancelListing`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    address: this.wallet_address,
                    inscription_id: inscription_id,
                    tx_id: "",
                }),
            });
            if (!response.ok) {
                this.errorMessage = response.statusText;
                this.isCreatingListing = false;
                this.unsetCancelling(inscription_id);
                return;
            }
            var result = await response.json();
            this.unsetCancelling(inscription_id)
            if (result.status == 0) {
                this.errorMessage = result.message;
                this.isCreatingListing = false;
                return;
            }
            this.listings = this.listings.filter(item => !this.isCancelling(item.inscriptionId));
            this.toast.success("Listing delisted successfully", {
              position: "bottom-right"});
        },
        setCancelling(inscription_id) {
            var listing_index = this.listings_cancelling.indexOf(inscription_id);
            if (listing_index === -1) {
                this.listings_cancelling.push(inscription_id);
            }
        },
        unsetCancelling(inscription_id) {
            var listing_index = this.listings_cancelling.indexOf(inscription_id);
            if (listing_index !== -1) {
                this.listings_cancelling.splice(listing_index, 1);
            }
        },
        isCancelling(inscription_id) {
            return this.listings_cancelling.includes(inscription_id);
        },
        async sendLitecoin() {
            this.send_loading = true;

            try {
                await this.wallet_provider.sendBitcoin(this.send_address, this.btcToSat(this.send_amount));
            } catch (e) {
                console.warn(e);
                this.toast.error(e, {
                    position: "bottom-right"});
                this.send_loading = false;
                return;
            }
            this.toast.success("Litecoin sent successfully", {
                position: "bottom-right"});
            this.send_address = null;
            this.send_amount = null;
            this.send_loading = false;
            this.screen = 5;
        },
        navToAddressPage() {
            this.$router.push({
                name: "address",
                params: {
                    address: this.wallet_address,
                },
            });
        },
        async fetchRunes() {
            this.wallet_provider.getRunicBalances(this.wallet_address)
                .then(r => this.assets['runes'] = r);
        },
        async fetchLeaderboard() {
            var lb_resp = await fetch(`${config.apiUrl}/leaderboard?address=${this.wallet_address}`)
                .then(d => d.json());
            this.leaderboard = lb_resp.result;
        },
        ...utils,
    },
};
</script>

<style scoped>
.wallet-button:active {
    background-color: #20222580;
    border-color: #ffffff52;
    outline: rgba(255,255,255,.32) solid 2px
}

.wallet-header {
    display: flex;
    font-size: 1.6rem;
    align-items: center;
    margin-bottom: 0.5rem;
}

.wallet-header svg {
    cursor: pointer;
    margin-right: 1rem;
}

.wallet-info {
    line-height: 1.2;
    font-size: 90%;
    text-align: justify;
}

.wallet-img {
    position: absolute;
    left: 20px;
    top: 8px;
}

.wallet-img>img {
    height: 24px;
}

.import-wallet-header {
    font-size: 1.2rem;
    margin: 1rem 0;
    text-align: center;
}

.seed-textarea:focus {
    outline: white solid 2px;
}

.seed-textarea {
    resize: none;
    font-family: inherit;
    border-radius: 8px;
    margin: 0.5rem 0;
    border: none;
    background-color: #ffffff1a;
    padding: 0.6rem;
}

.buttons-container {
    padding: 1rem;
    margin-top: auto;
}

.create-button {
    width: 100%;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    margin-bottom: 1rem;
    position: relative;
}

.import-button {
    width: 100%;
    background-color: #8fc5ff;
    color: #007aff;
}

.import-button:enabled:hover {
    background-color: #6bb2ff;
}

.set-password-title {
    text-align: center;
    font-size: 1.2rem;
    margin: 1rem 0;
}

.enter-password-message {
    text-align: center;
    font-size: 1.2rem;
    margin: 2rem 0;
}

.password-input {
    margin: 0.25rem 0;
}

.password-container {
    display: flex;
    border: 1px solid white;
    border-radius: 8px;
}

.password-container:focus-within {
    outline: white solid 2px;
}

.password-container>input {
    width: 100%;
    padding: 0.6em 0 0.6em 0.6em;
    border: none;
    outline: none;
}

.password-container>svg {
    cursor: pointer;
    padding: 0.6em;
}

.modal-input {
    margin-right: 0.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    width: 6rem;
    text-align: left;
    font-size: inherit;
}

.modal-input:focus {
    border-bottom: 1px solid #007aff;
}

.modal-input:enabled {
    border-bottom: 1px solid tan;
}

.input-invalid {
    color: #ff4500;
}

input {
    font-family: inherit;
    font-size: inherit;
    background-color: transparent;
    border: 1px solid gray;
    padding: 0.4em;
}

.error-message {
    font-size: 95%;
    font-weight: 700;
    color: #ff4500;
}

.unlock-button {
    margin-top: 1rem;
}

.screen-title, .seed-title {
    font-size: 1.2rem;
}

.seed {
    margin: 0.5rem 1rem;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    justify-items: center;
}

.seed-word {
    border-radius: 6px;
    width: 8rem;
    background-color: #202225;
    display: flex;
    overflow: hidden;
}

.seed-word>.index {
    background-color: #2c2f33;
    padding: 0.1rem 0.2rem;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    width: 2.5rem;
}

.seed-word>.value {
    padding: 0.1rem 0.4rem;
}

.wallet-options {
    display: flex;
    align-items: flex-start;
}

.options-icon,.wallet-addr, .wallet-leaderboard {
    cursor: pointer;
    padding: 0 .2em
}

.options-icon:hover,.wallet-addr:hover, .wallet-leaderboard:hover {
    background-color: #202225;
    box-shadow: #00000026 0 3px 15px
}

.button-icon {
    border-radius: 50%;
    background: none;
    transition: all .15s ease 0s;
    padding: 0;
    border: 0;
    line-height: 0;
    color: #fff;
}

.button-icon svg {
    padding: 0.3rem;
}

.button-icon:enabled {
    cursor: pointer;
}

button:not(:disabled):hover {
    background-color: #222;
}

.button-icon:enabled:hover {
    background-color: #202225;
    box-shadow: #00000026 0 3px 15px;
}

.options-icon {
    border-radius: 50%
}

.wallet-addr, .wallet-leaderboard {
    border-radius: 6px;
    padding: .3rem .6rem;
}

.wallet-addr i {
    margin-left: 3px;
    margin-bottom: -3px
}

.wallet-leaderboard:hover {
    text-decoration: none;
    color: inherit;
}

.wallet-balance {
    font-weight: 700;
}

.wallet-list {
    display: flex;
    flex-direction: column;
}

.wallet-name {
    white-space: nowrap;
    margin-left: 10px;
}

.section {
    font-weight: 700;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.asset-position {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    background-color: #0000004d;
    cursor: pointer;
    font-size: 1.1rem;
    border: 1px solid transparent;
    transition: all .2s ease-in-out;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0.75rem;
}

.asset-name {
    color: #fff;
    font-weight: 600;
    margin-right: 0.5rem;
}

.asset-total {
    font-weight: 700;
    margin-left: auto;
    margin-right: 1rem;
    color: #daa520;
}

.row-listing {
    display: flex;
    align-items: center;
    margin: .5rem 0
}

.listing-name {
    font-weight: 600;
    font-size: 1.1rem
}

.row-listing {
    line-height: 1.2
}

.listing-preview {
    width: 42px;
    height: 42px;
    flex-basis: 42px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 12px;
    border: none;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
}

.price-info .litecoin-icon {
    margin-right: 0.4em;
}

.wallet-inscription {
    border-radius: 12px
}

.wallet-inscriptions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr
}

.wallet-inscriptions>img .wallet-inscription>iframe {
    height: 1.5rem;
    width: 1.5rem;
    object-fit: contain
}

.empty {
    text-align: center;
}
</style>