<template>
<div class="token-details">
  <div class="token-detail">
    <div class="detail-name">Ticker</div>
    <div class="detail-value">{{ token.ticker }}</div>
  </div>
  <div class="token-detail">
    <div class="detail-name">Inscription ID</div>
    <div class="detail-value">
      <router-link :to="{ name: 'inscription', params: { id: token.deploy_inscription }}">{{ token.deploy_inscription }}</router-link>
    </div>
  </div>
  <div class="token-detail">
    <div class="detail-name">Inscription Number</div>
    <div class="detail-value">{{ token.deploy_inscription_num }}</div>
  </div>
  <div class="token-detail">
    <div class="detail-name">Supply</div>
    <div class="detail-value">{{ Number(token.supply).toLocaleString() }}</div>
  </div>
  <div class="token-detail">
    <div class="detail-name">Supply Minted</div>
    <div class="detail-value">{{ Number(token.supply_minted).toLocaleString() }}</div>
  </div>
  <div class="token-detail">
    <div class="detail-name">Limit Mint</div>
    <div class="detail-value">{{ Number(token.limit_mint).toLocaleString() }}</div>
  </div>
  <div class="token-detail">
    <div class="detail-name">Decimals</div>
    <div class="detail-value">{{ token.decimals }}</div>
  </div>
  <div class="token-detail">
    <div class="detail-name">Inscription Number Start</div>
    <div class="detail-value">{{ token.inscription_mint_start }}</div>
  </div>
  <div class="token-detail">
    <div class="detail-name">Inscription Number End</div>
    <div class="detail-value">{{ token.inscription_mint_end }}</div>
  </div>
</div>
</template>
  
<script>
import utils from '@/utils';

export default {
  props: {
    token: {
      type: Array,
      required: true
    },
  },
  methods: {
    ...utils
  }
}
</script>
  
<style>
.token-details {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  overflow: hidden;
  border-radius: 12px;
}

.token-detail {
  padding: 0.75rem 1.5rem;
  background-color: #ffffff0d;
}

.detail-name {
  font-size: .9em;
  line-height: 1.2;
}

.detail-value {
  color: #fff;
  font-weight: 700;
  font-size: .95em;
  overflow-wrap: break-word;
}
</style>