import CryptoJS from 'crypto-js';

import config from '@/config';

export default {
  short_address(str) {
    if (str == null || str.length == 0) {
      return "No Address";
    }
    return str.substr(0, 5) + '...' + str.substr(str.length-5, str.length);
  },
  satToBtc(sat) {
    return Number(sat) / Math.pow(10, 8);
  },
  btcToSat(btc) {
    return Math.floor(Number(btc) * Math.pow(10, 8));
  },
  amountToDecimal(amount, divisibility) {
    return Number(amount) / Math.pow(10, divisibility);
  },
  decimalToAmount(decimal, divisibility) {
    return Math.floor(Number(decimal) * Math.pow(10, divisibility));
  },
  toHexString(bytes) {
    return bytes.map(byte => byte.toString(16).padStart(2, '0')).join('');
  },  
  base64ToHex(str) {
    return atob(str)
        .split("")
        .map((c) => c.charCodeAt(0).toString(16).padStart(2, "0"))
        .join("");
  },
  hexToBase64(hexstring) {
    return btoa(hexstring.match(/\w{2}/g).map(function(a) {
        return String.fromCharCode(parseInt(a, 16));
    }).join(""));
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  breakCamelCase(input) {
    return input.replace(/([a-z])([A-Z])|([A-Z])([A-Z][a-z])/g, '$1$3 $2$4');
  },
  utf8ByteLength(str) {
    var utf8Encoder = new TextEncoder();
    var encoded = utf8Encoder.encode(str);
    return encoded.length;
  },
  unix_timestamp_local_str(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000); // Convert to milliseconds

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}.${month}.${day}`;

    return `${formattedTime} ${formattedDate}`;
  },
  time_ago(timestamp) {
    const seconds = Math.floor((Date.now() - timestamp) / 1000);
    const interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return `${interval} years ago`;
    } else if (interval === 1) {
      return "1 year ago";
    }

    const months = Math.floor(seconds / 2592000);
    if (months > 1) {
      return `${months} months ago`;
    } else if (months === 1) {
      return "1 month ago";
    }

    const days = Math.floor(seconds / 86400);
    if (days > 1) {
      return `${days} days ago`;
    } else if (days === 1) {
      return "1 day ago";
    }

    const hours = Math.floor(seconds / 3600);
    if (hours > 1) {
      return `${hours} hours ago`;
    } else if (hours === 1) {
      return "1 hour ago";
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes > 1) {
      return `${minutes} minutes ago`;
    } else if (minutes === 1) {
      return "1 minute ago";
    }

    return "Just now";
  },
  file_size_str(size) {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  },
  encryptData(data, passphrase) {
    const salt = CryptoJS.lib.WordArray.random(128/8);
    const iv = CryptoJS.lib.WordArray.random(128/8);
  
    const key = CryptoJS.PBKDF2(passphrase, salt, {
      keySize: 256/32,
      iterations: 1000
    });
  
    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
  
    const encryptedWithIvAndSalt = salt.toString(CryptoJS.enc.Hex) + iv.toString(CryptoJS.enc.Hex) + encrypted.toString();
    return encryptedWithIvAndSalt;
  },
  decryptData(encryptedDataWithIvAndSalt, passphrase) {
    const saltHex = encryptedDataWithIvAndSalt.substr(0, 32);
    const ivHex = encryptedDataWithIvAndSalt.substr(32, 32);
    const encryptedData = encryptedDataWithIvAndSalt.substr(64);
  
    const salt = CryptoJS.enc.Hex.parse(saltHex);
    const iv = CryptoJS.enc.Hex.parse(ivHex);
  
    const key = CryptoJS.PBKDF2(passphrase, salt, {
      keySize: 256/32,
      iterations: 1000
    });
  
    const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
  
    const data = bytes.toString(CryptoJS.enc.Utf8);
    return data;
  },
  isValidAddress(address) {
    // Ensure the address is provided.
    if (address === undefined) {
        return false;
    }

    // Ensure the address is a string.
    if (typeof address !== 'string') {
        return false;
    }

    const pattern_regex = config.network === "MAINNET" ? `^[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}$` : `^[nm][a-km-zA-HJ-NP-Z1-9]{26,33}$`;

    // Quick and dirty regex pattern for validating the address
    const pattern = new RegExp(pattern_regex);

    const segwit_regex = config.network === "MAINNET" ? `^ltc1[a-zA-HJ-NP-Z0-9]{25,59}$` : `^tltc1[a-zA-HJ-NP-Z0-9]{25,59}$`;

    const segwit = new RegExp(segwit_regex);

    // Test the regex
    if (!pattern.test(address) && !segwit.test(address)) return false;

    return true;
  },
  saveOrderToBrowserCache(order) {
    var orders = [];

    orders.push(order);

    console.log(order);

    var saved_orders = localStorage.getItem('chikun-market-orders');
    var orders_parsed = null;
    try {
      orders_parsed = JSON.parse(saved_orders);
    } catch (ex) {
      console.error(ex);
      return;
    }

    if (orders_parsed != null) {
      orders.push(...orders_parsed);
    }

    localStorage.setItem('chikun-market-orders', JSON.stringify(orders));
  }
}