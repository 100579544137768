<template>
  <div v-show="isModalVisible" class="modal">
    <div class="modal-backdrop" @click="close"></div>
    <div class="modal-wrap">
      <div class="modal-window">
        <button
            type="button"
            class="btn-close"
            @click="close"
          >
          x
        </button>
        <h1 class="modal-header">
          <slot v-if="!runes" name="header">
            Buy Inscription{{ listings.length > 1 ? "s" : ""}}
          </slot>
          <slot v-if="runes" name="header">
            Buy Rune
          </slot>
        </h1>
  
        <div class="modal-body">
          <slot name="body">
            <div v-if="!runes" class="body-items">
              <!-- placeholder -->
              <div v-if="!listings || listings.length == 0" class="body-item">
                <div>
                  <div class="inscription-preview placeholder-loading"></div>
                </div>
                <div>‎</div>
              </div>
              <div v-for="listing in listings" :key="listing.inscription_id" class="body-item">
                <div v-if="!listing.content && listing.inscription_id">
                  <img v-if="isImage(listing.contentType)" class="inscription-preview" :src="`https://ordinalslite.com/content/${listing.inscription_id}`">
                  <div v-if="isIframe(listing.contentType)" class="inscription-img inscription-iframe">
                    <iframe scrolling="no" loading="lazy" style="transform: scale(0.25); transform-origin: 0 0; width: 400%; height: 400%; border: 0;" :src="'https://ordinalslite.com/preview/' + listing.inscription_id"></iframe>
                  </div>
                </div>
                <div v-if="listing.content" class="item-text">
                  {{ listing.content }}
                </div>
                <div>#{{ listing.inscription_number }}</div>
              </div>
            </div>
            <div v-if="runes" class="body-items">
              <!-- placeholder -->
              <div v-if="!runes" class="body-item">
                <div>
                  <div class="inscription-preview placeholder-loading"></div>
                </div>
                <div>‎</div>
              </div>
              <div v-if="runes" class="body-item">
                <div>
                  <img v-if="runes.parent" class="inscription-preview" loading="lazy" :src="`https://ordinalslite.com/content/${runes.parent}`">
                  <img v-if="!runes.parent" class="inscription-preview" src="@/assets/rune.png">
                </div>
                <div>{{ runes.id }}</div>
              </div>
            </div>
            <div class="body-grid">
              <div v-if="runes" class="modal-row">
                <div>Rune:</div>
                <div class="modal-row-value">
                  <span>{{ runes.spaced_rune }}</span>
                </div>
              </div>
              <div v-if="runes" class="modal-row" style="margin-bottom: 1rem;">
                <div>Amount:</div>
                <div class="modal-row-value">
                  <span v-if="listings.length > 0" >{{ amountToDecimal(Number(listings[0].amount), runes.divisibility).toLocaleString() }} {{ runes.symbol }}</span>
                </div>
              </div>
              <div class="modal-row">
                <div>Taker Fee ({{ taker_fee }}%):</div>
                <div class="modal-row-value">
                  <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
                  {{ (satToBtc((price * service_fee_rate) - price)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 8}) }}
                  <span class="usd-value">(${{ (satToBtc((price * service_fee_rate) - price) * usd_rate).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }})</span>
                </div>
              </div>
              <div v-if="total_royalties > 0" class="modal-row">
                <div style="display: flex; justify-content: flex-end; gap: 1rem;" >
                  <div>
                    <Toggle v-model="royalties_enabled" style="box-shadow: none;" onLabel="On" class="toggle-blue"/>
                    <tippy to="parent">Support Creators</tippy>
                  </div>
                  Royalties:
                </div>
                <div class="modal-row-value">
                  <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
                  {{ (satToBtc(total_royalties)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 8}) }}
                  <span class="usd-value">(${{ (satToBtc(total_royalties) * usd_rate).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }})</span>
                </div>
              </div>
              <div class="modal-row">
                <div>Network Fee:</div>
                <div class="modal-row-value">
                  <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
                  ≈ {{ satToBtc(network_fee) }}
                  <span class="usd-value">(${{ (satToBtc(network_fee) * usd_rate).toFixed(2) }})</span>
                </div>
              </div>
              <div class="modal-row modal-total">
                <div>Total:</div>
                <div class="modal-row-value">
                  <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
                  {{ satToBtc(price * service_fee_rate + (royalties_enabled ? total_royalties : 0)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 8}) }}
                  <span class="usd-value">(${{ (satToBtc(price * service_fee_rate + (royalties_enabled ? total_royalties : 0)) * usd_rate).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }})</span>
                </div>
              </div>
              <div class="modal-row">
                <div>Available Balance:</div>
                <div class="modal-row-value" :style="{ color: wallet_connected && available_balance < price * service_fee_rate + (royalties_enabled ? total_royalties : 0) ? 'red' : 'inherit' }">
                  <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
                  {{ satToBtc(available_balance).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 8}) }}
                  <span class="usd-value">(${{ (satToBtc(available_balance) * usd_rate).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }})</span>
                </div>
              </div>
            </div>
          </slot>
        </div>
        <div v-if="errorMessage" class="error-message" style="color: red; text-align: center; padding-bottom: 1rem;">{{ errorMessage }}</div>
        <button
          type="button"
          class="modal-button confirm-button"
          :disabled="!wallet_connected || !isReady || available_balance < price * service_fee_rate || isBuyingListing"
          @click="buyInscription"
        >
          <font-awesome-icon v-if="isBuyingListing" class="fa-spin" style="margin-right: 0.5rem" :icon="['fas', 'spinner']" />
          {{ !wallet_connected ? 'Connect wallet to Buy' : available_balance >= price * service_fee_rate ? (isBuyingListing ? (isCreatingDummies ? 'Creating Dummy Utxos' : `Buying Listing${ listings.length > 1 ? "s" : ""}`) : 'Buy Now') : 'Insufficient Balance' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from "vue-toastification";
import Toggle from '@vueform/toggle';
import config from '@/config.js'
import utils from '@/utils';

export default {
  name: 'ModalComponent',
  
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isReady: false,
      isModalVisible: false,
      isBuyingListing: false,
      isCreatingDummies: false,
      errorMessage: null,
      listings: [],
      inscription_id: null,
      inscription_number: 0,
      content: null,
      tx_id: null,
      output: 0,
      psbt: null,
      taker_fee: config.serviceFee,
      network_fee: 500,
      price: 0,
      royalties: {},
      royalties_enabled: true,
      usd_rate: inject('usd_rate', 0),
      available_balance: inject('wallet_balance', 0),
      wallet_connected: inject('wallet_connected', false),
      wallet_address: inject('wallet_address', false),
      wallet_provider: this.$walletProvider,
    };
  },
  components: {
    Toggle,
  },
  methods: {
    close() {
      this.isModalVisible = false;
    },
    async buyInscription() {
      if (!this.isReady) return;

      this.isBuyingListing = true;
      this.isCreatingDummies = false;
      this.errorMessage = null;

      var payerAddress = await this.wallet_provider.getWalletAddress();

      var receiverAddress = payerAddress;
      var inscription_ids = [];

      this.price = 0;
      var sellerSignedPsbts64 = [];
      var sellerSignedPsbts = [];
      for (const listing of this.listings) {
        inscription_ids.push(listing.inscription_id);
        var txid = this.txidFromLocation(listing.location);
        console.log(`${txid}:${listing.output}`);
        try {
          this.price += this.wallet_provider.validateSellerPSBTAndExtractPrice(utils.hexToBase64(listing.psbt), `${txid}:${listing.output}`, false);
        } catch(e) {
          console.error(e);
          this.errorMessage = e;
          this.isBuyingListing = false;
          return;
        }
        sellerSignedPsbts.push(this.wallet_provider.sellerSignedPsbt);
        sellerSignedPsbts64.push(utils.hexToBase64(listing.psbt));

        console.log(listing.psbt);
      }

      await this.wallet_provider.updatePayerAddress(payerAddress, this.price * this.service_fee_rate + (this.royalties_enabled ? this.total_royalties : 0));

      if (!this.runes) {
        console.log(this.wallet_provider.dummyUtxos);

        var dummyUtxosRequired = this.wallet_provider.NUMBER_DUMMIES_REQUIRED;
        if (this.listings.length > 1) {
          dummyUtxosRequired = this.listings.length + 1;
        }

        if (this.wallet_provider.dummyUtxos == null || this.wallet_provider.dummyUtxos.length < dummyUtxosRequired) {
          this.isCreatingDummies = true;
          this.toast.error(`You need to have at least ${dummyUtxosRequired} spendable dummy utxos`, {
            position: "bottom-right"});
          var unsigned_dummy_psbt = await this.wallet_provider.generatePSBTGeneratingDummyUtxos(payerAddress, dummyUtxosRequired - ((this.wallet_provider.dummyUtxos == null) ? 0 : this.wallet_provider.dummyUtxos.length), this.wallet_provider.paymentUtxos);
          await this.wallet_provider.signPSBTUsingWalletAndBroadcast(unsigned_dummy_psbt)
          .then(data => {
            console.log(data);
            this.toast.info("Please wait for your dummy utxos to have at least 1 confirmation", {
              position: "bottom-right"});
          })
          .catch(reason => {
              console.error(reason);
              if (reason.message) {
                this.errorMessage = reason.message;
              }
          });
          this.isBuyingListing = false;
          return;
        }
      }

      var unsigned_psbt = null;
      var signed_buying_psbt = null;

      var psbtMergeRunes = false;

      try {
        if (!this.runes) {
          unsigned_psbt = await this.wallet_provider.generatePSBTBuyingInscription(
            payerAddress,
            receiverAddress,
            this.price,
            this.wallet_provider.paymentUtxos,
            this.wallet_provider.dummyUtxos,
            sellerSignedPsbts,
            this.royalties
          );
        } else {
          unsigned_psbt = await this.wallet_provider.generatePSBTBuyingRune(payerAddress, receiverAddress, this.price, this.runes.id, this.wallet_provider.paymentUtxos, sellerSignedPsbts);
          psbtMergeRunes = true;
        }
      } catch (e) {
        return alert(e);
      }

      try {
        signed_buying_psbt = await this.wallet_provider.signPsbt(unsigned_psbt);
      } catch (error) {
        console.log(error);
        if (error.message) {
          this.errorMessage = error.message;
        }
        this.isBuyingListing = false;
        return;
      }

      var merged_signed_psbt = await this.wallet_provider.mergeSignedBuyingPSBTBase64(sellerSignedPsbts64, utils.hexToBase64(signed_buying_psbt), psbtMergeRunes);

      var pending_tx_id = null;

      try {
        pending_tx_id = await this.wallet_provider.broadcastTx(this.wallet_provider.psbtBase64ToTransactionHex(merged_signed_psbt));
      } catch (error) {
        console.error(error);
        if (error.message) {
          this.errorMessage = error.message;
        }
        this.isBuyingListing = false;
        return;
      }

      if (pending_tx_id == null) {
        this.errorMessage = "Error: Something went wrong";
        this.isBuyingListing = false;
        return;
      }

      const response = await fetch(`${config.apiUrl}/market/buyListing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          address: this.wallet_address,
          inscription_ids: this.inscription_ids,
          tx_id: pending_tx_id,
        }),
      });
      if (!response.ok) {
        this.errorMessage = response.statusText;
        this.isBuyingListing = false;
        return;
      }
      var result = await response.json();
      if (result.status == 0) {
        this.errorMessage = result.message;
        this.isBuyingListing = false;
        return;
      }
      
      for (const id of this.inscription_ids) {
        if (this.runes) {
          this.toast.success(`Rune ${this.short_address(id)} bought!\n`, {
            position: "bottom-right"});
        } else {
          this.toast.success(`Inscription ${this.short_address(id)} bought!\n`, {
          position: "bottom-right"});
          this.emitter.emit("listing-pending", { inscription_id: id });
        }
      }

      this.isModalVisible = false;
      this.emitter.emit("select-bar-close");
    },
    satToBtc(sat) {
      return Number(sat) / Math.pow(10, 8);
    },
    txidFromLocation(location) {
      let colonIndex = location.indexOf(':');
      if (colonIndex !== -1) {
          return location.substring(0, colonIndex);
      } else {
          return location; // Return the original string if no colon is found
      }
    },
    isImage(contentType) {
      if (contentType) {
        return contentType.startsWith("image");
      }
      return false;
    },
    isIframe(contentType) {
      if (contentType) {
        return contentType.startsWith("text/html");
      }
      return false;
    },
    ...utils
  },
  mounted() { 
    this.emitter.on("modal-buy-open", (parameters) => {
      this.isReady = false;
      this.isModalVisible = true;
      this.isBuyingListing = false;
      this.errorMessage = null;
      this.listings = [];
      this.royalties = {};
      this.royalties_enabled = true;
      this.price = 0;
      this.runes = parameters.runes;
      this.rune_amount = parameters.rune_amount;
      this.inscription_ids = parameters.listings;
      if (!this.runes) {
        if (this.inscription_ids) {
          for (var i = 0; i < this.inscription_ids.length; i++) {
            fetch(`${config.apiUrl}/inscription/${this.inscription_ids[i]}`)
            .then(d => d.json())
            .then(j => {
              if (j.status == 1) {
                this.listings.push({
                  "inscription_id": j.result.inscriptionId,
                  "inscription_number": j.result.inscriptionNumber,
                  "content": j.result.contentBody,
                  "contentType": j.result.contentType,
                  "location": j.result.location,
                  "output": j.result.output,
                  "psbt": j.result.listed.psbt,
                  "price": j.result.listed.price,
                })

                const royalties_address = j.result.collection.royaltiesAddress;
                const royalties_rate = Number(j.result.collection.royalties);
                if (royalties_address && royalties_rate > 0) {
                  const royalties_value = Number(j.result.listed.price) * (Number(royalties_rate) / 100);
                  if (this.royalties[royalties_address]) {
                      this.royalties[royalties_address] += royalties_value;
                  } else {
                      this.royalties[royalties_address] = royalties_value;
                  }
                }

                this.price += Number(j.result.listed.price);
                this.isReady = true;

                // console.log(this.listings)
                // console.log(this.price)
                // console.log(this.royalties)
              }
            })
          }
        } else {
          this.errorMessage = "No inscriptions selected";
        }
      } else if (this.runes) {
        if (this.inscription_ids) {
          fetch(`${config.apiUrl}/market/listing?inscription_id=${this.inscription_ids[0]}`)
            .then(d => d.json())
            .then(j => {
              if (j.status == 1) {
                this.listings.push({
                  "inscription_id": j.result.inscriptionId,
                  "amount": j.result.amount,
                  "location": j.result.inscriptionId,
                  "output": j.result.inscriptionId.split(":")[1],
                  "psbt": j.result.psbt,
                  "price": j.result.price
                })
                this.price += Number(j.result.price);
                console.log(this.price)
                this.isReady = true;
              }
            })
        } else {
          this.errorMessage = "No runes selected";
        }
      }
    });
  },
  computed: {
    service_fee_rate: function() {
      return 1 + this.taker_fee / 100
    },
    total_royalties: function() {
      return Object.values(this.royalties).reduce((acc, val) => acc + val, 0)
    }
  }
};
</script>

<style>
  .modal-body {
    position: relative;
    padding: 0 10px 20px 10px;
  }

  .modal-backdrop {
    position: fixed;
    border: none;
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    opacity: 0.6;
    transition: opacity .15s ease;
    background-color: #000;
  }

  .hystmodal__shadow--show {
    pointer-events: auto;
    opacity: .6;
  }

  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    z-index: 99;
  }

  .modal-wrap {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    min-height: 100%;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    z-index: 97;
  }

  .modal-window {
    display: flex;
    flex-direction: column;
    margin: 50px 0;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    width: 500px;
    max-width: calc(100% - 1rem);
    min-height: 500px;
    overflow: visible;
    pointer-events: all;
    transition: transform .2s ease 0s,opacity .2s ease 0s;
    transform: scale(.9);
    background-color: #ffffff1f;
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    border-radius: 12px;
    padding: 1.5rem;
  }

  @media (max-width: 576px) {
    .modal-window {
      padding: .75rem;
    }
  }

  .body-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .body-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .body-grid {
    margin-top: 1rem;
  }

  .inscription-iframe {
    height: 8rem;
    overflow: hidden;
  }

  .btn-close {
    position: absolute;
    z-index: 10;
    display: block;
    top: 14px;
    right: 14px;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    font-size: 0;
    cursor: pointer;
    outline: none;
  }

  .modal-box {
    max-height: 26rem;
    overflow-y: auto
  }

  @media (max-width: 576px) {
    .modal-box {
      max-height: 20rem;
    }
  }

  .modal-row {
    display: flex;
    justify-content: center;
    line-height: 2;
  }

  .modal-row>div {
    width: 50%;
  }

  .modal-row>div:first-child {
    text-align: right;
    padding-right: 2rem;
  }

  .modal-row .modal-row-value {
    text-align: left;
  }

  .modal-row .modal-row-input {
    padding: .1rem;
  }

  @media (max-width: 576px) {
    .modal-row>div:first-child {
      padding-right:1rem;
    }
  }

  .modal-button {
    margin-top: auto;
  }

  .modal-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .item-text {
    text-align: center;
    margin-top: 2.75rem;
    margin-bottom: 2.75rem
  }

  .modal-total {
    margin-top: 1em;
    font-size: larger;
  }

  @media all and (max-width: 767px) {
    .modal-window {
      margin: 0;
    }
  }

  .modal-header {
    margin-top: 0;
    text-align: center;
    font-weight: 600;
  }

  @media (max-width: 576px) {
    .modal-header {
      font-size: 1.5rem;
    }
  }

  .placeholder-loading {
    width: 100%;
    height: 205px;
    overflow: hidden;
    position: relative;
    animation: loading 1.7s linear infinite;
    background: #000;
    background: linear-gradient(to right, #444 2%, #333 18%, #444 33%);
    background-size: 1300px;
  }

  @keyframes loading {
    0% {
      background-position: -650px 0;
    }
    100% {
      background-position: 650px 0;
    }
  }

  .toggle-blue {
    --toggle-bg-on: #345D9D;
    --toggle-border-on: #345D9D;
    --toggle-bg-off: #222;
    --toggle-border-off: #222;
  }
</style>