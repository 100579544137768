<template>
  <div v-show="isModalVisible" class="modal">
    <div class="modal-backdrop" @click="close"></div>
    <div class="modal-wrap">
      <div class="modal-window">
        <button
            type="button"
            class="btn-close"
            @click="close"
          >
          x
        </button>
        <h1 class="modal-header">
          <slot v-if="!isEdit && rune" name="header">
            List Rune
          </slot>
          <slot v-if="!isEdit && !rune" name="header">
            List Inscription{{ inscriptions.length > 1 ? "s" : ""}}
          </slot>
          <slot v-if="isEdit" name="header">
            Edit Listing{{ inscriptions.length > 1 ? "s" : ""}}
          </slot>
        </h1>
  
        <div class="modal-body">
          <slot name="body">
            <div v-if="!rune" class="body-items">
              <!-- placeholder -->
              <div v-if="!inscriptions || inscriptions.length == 0" class="body-item">
                <div>
                  <div class="inscription-preview placeholder-loading"></div>
                </div>
                <div>‎</div>
              </div>
              <div v-for="inscription in inscriptions" :key="inscription.inscription_id" class="body-item">
                <div v-if="!inscription.content && inscription.inscription_id">
                  <img v-if="isImage(inscription.contentType)" class="inscription-preview" :src="`https://ordinalslite.com/content/${inscription.inscription_id}`">
                  <div v-if="isIframe(inscription.contentType)" class="inscription-img inscription-iframe">
                    <iframe scrolling="no" loading="lazy" style="transform: scale(0.25); transform-origin: 0 0; width: 400%; height: 400%; border: 0;" :src="'https://ordinalslite.com/preview/' + inscription.inscription_id"></iframe>
                  </div>
                </div>
                <div v-if="inscription.content" class="item-text">
                  {{ inscription.content }}
                </div>
                <div>#{{ inscription.inscription_number }}</div>
              </div>
            </div>
            <div v-if="rune" class="body-items">
              <!-- placeholder -->
              <div v-if="!runes" class="body-item">
                <div>
                  <div class="inscription-preview placeholder-loading"></div>
                </div>
                <div>‎</div>
              </div>
              <div v-if="runes" class="body-item">
                <div>
                  <img v-if="runes.parent" class="inscription-preview" loading="lazy" :src="`https://ordinalslite.com/content/${runes.parent}`">
                  <img v-if="!runes.parent" class="inscription-preview" src="@/assets/rune.png">
                </div>
                <div>{{ runes.id }}</div>
              </div>
            </div>
            <div v-if="!isToken" class="body-grid">
              <div class="modal-row">
                <div>Price:</div>
                <div class="modal-row-value">
                  <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
                  <input v-model="price" class="modal-input" type="number" min="0.01" step="any">
                </div>
              </div>
              <div class="modal-row modal-amount">
                <div>You will receive:</div>
                <div class="modal-row-value" :style="{ color: price != 0 && price * usd_rate < 1 ? 'red' : 'inherit' }">
                  <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
                  {{ satToBtc(btcToSat(price) * listing_count).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 8}) }}
                  <span class="usd-value">(${{ (price * listing_count * usd_rate).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }})</span>
                </div>
              </div>
            </div>
            <div v-if="isToken" class="body-grid">
              <div class="modal-row">
                <div v-if="!rune">Ticker:</div>
                <div v-if="rune">Rune:</div>
                <div class="modal-row-value">
                  {{ token_ticker }}
                </div>
              </div>
              <div class="modal-row">
                <div>Amount:</div>
                <div v-if="!runes" class="modal-row-value">
                  -
                </div>
                <div v-if="runes" class="modal-row-value">
                  {{ token_amount }} {{ runes.symbol }}
                </div>
              </div>
              <div class="modal-row">
                <div>Price per Token:</div>
                <div class="modal-row-value">
                  <input v-model="price_token" class="modal-input" type="number" min="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" placeholder="1000000" step="1"> LITS
                </div>
              </div>
              <div class="modal-row">
                <div></div>
                <div class="modal-row-value">
                  ${{ (satToBtc(price_token) * usd_rate).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </div>
              </div>
              <div class="modal-row modal-amount">
                <div>You will receive:</div>
                <div class="modal-row-value" :style="{ color: price_token != 0 && token_amount != 0 && (satToBtc(price_token * token_amount) * usd_rate < 1) ? 'red' : 'inherit' }">
                  <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
                  {{ satToBtc(price_token * token_amount * listing_count).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 8}) }}
                  <span class="usd-value">(${{ (satToBtc(price_token * token_amount * listing_count) * usd_rate).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }})</span>
                </div>
              </div>
            </div>
          </slot>
        </div>
        <div v-if="errorMessage" class="error-message" style="color: red; text-align: center; padding-bottom: 1rem;">{{ errorMessage }}</div>
        <button
          type="button"
          class="modal-button confirm-button"
          :disabled="!wallet_connected || !isReady || price_total * usd_rate < 1 || isCreatingListing"
          @click="listInscription"
        >
          <font-awesome-icon v-if="isCreatingListing" class="fa-spin" style="margin-right: 0.5rem" :icon="['fas', 'spinner']" />
          {{ !wallet_connected ? 'Connect wallet to List' : price_total * usd_rate >= 1 ? (isCreatingListing ? `${this.isEdit ? "Updating" : "Creating"} Listing` : `${this.isEdit ? "Update" : "List"}`) : 'Enter an amount' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from "vue-toastification";
import config from '@/config.js'
import utils from '@/utils.js'

export default {
  name: 'ModalComponent',
  
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isReady: false,
      isModalVisible: false,
      isCreatingListing: false,
      isEdit: false,
      isToken: false,
      errorMessage: null,
      inscriptions: [],
      inscription_id: null,
      inscription_ids: [],
      inscription_number: 0,
      inscription_utxo: null,
      content: null,
      content_type: null,
      runes: null,
      rune: null,
      rune_balance: null,
      rune_utxo: null,
      token_ticker: null,
      token_amount: 0,
      taker_fee: config.serviceFee,
      network_fee: 0,
      listing_count: 0,
      price: 0,
      price_token: 0,
      usd_rate: inject('usd_rate', 0),
      available_balance: inject('wallet_balance', 0),
      wallet_connected: inject('wallet_connected', false),
      wallet_address: inject('wallet_address', false),
      wallet_provider: this.$walletProvider,
    };
  },
  methods: {
    close() {
      this.isModalVisible = false;
      this.isCreatingListing = false;
      this.errorMessage = null;
    },
    async listInscription() {
      if (!this.isReady) return;

      this.isCreatingListing = true;
      this.errorMessage = null;

      var unsigned_psbts = [];
      var signed_psbts = [];
      var signed_psbt = null;

      if (!this.rune) {
        for (var i = 0; i < this.inscriptions.length; i++) {
          var inscription = this.inscriptions[i];
          console.log(inscription)
          var unsigned_psbt = await this.wallet_provider.generatePSBTListingInscriptionForSale(inscription.location.replace(/:\d+$/, ''), this.btcToSat(this.price_total), this.wallet_address);
          unsigned_psbts.push(unsigned_psbt);
        }
      } else if (this.rune) {
        unsigned_psbt = await this.wallet_provider.generatePSBTListingInscriptionForSale(this.rune_utxo, this.btcToSat(this.price_total), this.wallet_address);
        unsigned_psbts.push(unsigned_psbt);
      }

      try {
        if (unsigned_psbts.length > 1) {
          signed_psbts = await this.wallet_provider.signPsbts(unsigned_psbts);
        } else {
          signed_psbt = await this.wallet_provider.signPsbt(unsigned_psbts[0]);
          signed_psbts.push(signed_psbt);
        }

      } catch (error) {
        console.log(error);
        if (error.message) {
          this.errorMessage = error.message;
        }
        this.isCreatingListing = false;
        return;
      }

      var listings = [];
      
      for (var j = 0; j < signed_psbts.length; j++) {
        var listing = {
          address: this.wallet_address,
          value: this.btcToSat(this.price_total),
          psbt: signed_psbts[j],
          update: this.isEdit,
        }

        if (this.rune) {
          listing['rune'] = this.runes.spaced_rune;
          listing['inscription_id'] = this.rune_utxo;
        } else {
          listing['inscription_id'] = this.inscriptions[j].inscription_id;
        }

        listings.push(listing);
      }

      const response = await fetch(`${config.apiUrl}/market/createListings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          listings: listings,
        }),
      });
      if (!response.ok) {
        this.errorMessage = response.statusText;
        this.isCreatingListing = false;
        return;
      }
      var result = await response.json();
      if (result.status == 0) {
        this.errorMessage = result.message;
        this.isCreatingListing = false;
        return;
      }

      this.toast.success(`${this.rune ? "Runes" : `Inscription${signed_psbts.length > 1 ? "s" : ""}`} ${this.isEdit ? "updated" : "listed"} successfully`, {
        position: "bottom-right"});
      this.isModalVisible = false;
      this.emitter.emit("select-bar-close");
    },
    isImage(contentType) {
      if (contentType) {
        return contentType.startsWith("image");
      }
      return false;
    },
    isIframe(contentType) {
      if (contentType) {
        return contentType.startsWith("text/html");
      }
      return false;
    },
    ...utils
  },
  mounted() { 
    this.emitter.on("modal-list-open", (parameters) => {
      this.isReady = false;
      this.isModalVisible = true;
      this.isCreatingListing = false;
      this.errorMessage = null;
      this.content = null;
      this.price = 0;
      this.listing_count = 0;
      this.inscription_number = null;
      this.inscription_id = parameters.inscription_id;
      this.inscriptions = [];
      this.inscription_ids = parameters.inscriptions;
      this.isEdit = parameters.edit;
      this.isToken = parameters.token;
      this.rune = parameters.rune;
      this.rune_utxo = parameters.rune_utxo;
      this.rune_balance = parameters.rune_balance;
      if (this.inscription_ids && !this.rune) {
        for (var i = 0; i < this.inscription_ids.length; i++) {
          fetch(`${config.apiUrl}/inscription/${this.inscription_ids[i]}`)
          .then(d => d.json())
          .then(j => {
            if (j.status == 1) {
              this.inscriptions.push({
                "inscription_id": j.result.inscriptionId,
                "inscription_number": j.result.inscriptionNumber,
                "content": j.result.contentBody,
                "contentType": j.result.contentType,
                "location": j.result.location,
                "output": j.result.output
              })
              if (this.isToken) {
                var content_json = JSON.parse(j.result.contentBody);
                this.token_ticker = content_json.tick;
                this.token_amount = Number(content_json.amt);
              }
              this.listing_count = this.inscriptions.length;
              console.log(this.inscriptions)
              this.isReady = true;
            }
          })
        }
      } else if (this.rune) {
        fetch(`${config.apiUrl}/runes/${this.rune}`)
          .then(d => d.json())
          .then(j => {
            if (j.status == 1) {
              this.runes = j.result;
              this.token_ticker = this.runes.spaced_rune;
              this.listing_count = 1;
              this.isReady = true;
            }
          })
        this.token_amount = this.rune_balance;
      } else {
        this.errorMessage = "No inscriptions selected";
      }
    });
  },
  computed: {
    service_fee_rate: function() {
      return 1 + this.taker_fee / 100
    },
    price_total: function() {
      return this.isToken ? this.satToBtc(this.price_token * this.token_amount) : this.price
    }
  }
};
</script>

<style scoped>
  .modal-body {
    position: relative;
    padding: 0 10px 20px 10px;
  }

  .modal-backdrop {
    position: fixed;
    border: none;
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    opacity: 0.6;
    transition: opacity .15s ease;
    background-color: #000;
  }

  .hystmodal__shadow--show {
    pointer-events: auto;
    opacity: .6;
  }

  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    z-index: 99;
  }

  .modal-wrap {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    min-height: 100%;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    z-index: 97;
  }

  .modal-window {
    display: flex;
    flex-direction: column;
    margin: 50px 0;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    width: 500px;
    max-width: calc(100% - 1rem);
    min-height: 500px;
    overflow: visible;
    pointer-events: all;
    transition: transform .2s ease 0s,opacity .2s ease 0s;
    transform: scale(.9);
    background-color: #ffffff1f;
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    border-radius: 12px;
    padding: 1.5rem;
  }

  @media (max-width: 576px) {
    .modal-window {
      padding: .75rem;
    }
  }

  .modal-input {
    margin-right: 0.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    width: 6rem;
    text-align: left;
    font-size: inherit;
  }

  .modal-row.modal-input {
    padding: 0.1rem;
  }

  .modal-input:focus {
    border-bottom: 1px solid #007aff;
  }

  .modal-input:enabled {
    border-bottom: 1px solid tan;
  }

  .modal-amount {
    margin-top: 1em;
    font-size: larger;
  }

  .body-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .body-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .body-grid {
    margin-top: 1rem;
  }

  .inscription-iframe {
    height: 8rem;
    overflow: hidden;
  }

  .btn-close {
    position: absolute;
    z-index: 10;
    display: block;
    top: 14px;
    right: 14px;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    font-size: 0;
    cursor: pointer;
    outline: none;
  }

  .modal-box {
    max-height: 26rem;
    overflow-y: auto
  }

  @media (max-width: 576px) {
    .modal-box {
      max-height: 20rem;
    }
  }

  .modal-row {
    display: flex;
    justify-content: center;
    line-height: 2;
  }

  .modal-row>div {
    width: 50%;
  }

  .modal-row>div:first-child {
    text-align: right;
    padding-right: 2rem;
  }

  .modal-row .modal-row-value {
    text-align: left;
  }

  .modal-row .modal-row-input {
    padding: .1rem;
  }

  @media (max-width: 576px) {
    .modal-row>div:first-child {
      padding-right:1rem;
    }
  }

  .modal-button {
    margin-top: auto;
  }

  .modal-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .item-text {
    text-align: center;
    margin-top: 2.75rem;
    margin-bottom: 2.75rem
  }

  .modal-total {
    margin-top: 1em;
    font-size: larger;
  }

  @media all and (max-width: 767px) {
    .modal-window {
      margin: 0;
    }
  }

  .modal-header {
    margin-top: 0;
    text-align: center;
    font-weight: 600;
  }

  @media (max-width: 576px) {
    .modal-header {
      font-size: 1.5rem;
    }
  }
</style>