<template>
  <div style="display: flex; background-color: rgba(255, 0, 0, 0.2); padding: 1rem; border-radius: 12px; text-align: justify; align-items: center;">
    <font-awesome-icon :icon="['fas', 'circle-info']" style="margin: 0 0.5rem;"/>
    <div style="margin-left: 8px;">
      <div>Runes are an experimental protocol. Use at your own risk.</div>
      <div style="margin-top: 0.5rem">Please follow the guidelines below if you wish to proceed.</div>
      <div style="margin-top: 0.5rem">Prepare two wallets, with one for creating orders and one for receiving rune mints. We recommend you limit your use to your runes wallet during this early phase as it may result in accidental rune losses.</div>
      <div style="margin-top: 0.5rem">Note: Wallet APIs are limited to 500 utxos per address</div>
    </div>
  </div>
  <!-- <router-link :to="{ name: 'etch' }" style="display: flex; background-color: rgba(0, 0, 255, 0.2); margin-top: 1rem; padding: 1rem; border-radius: 12px; text-align: justify; align-items: center;">
    <font-awesome-icon :icon="['fas', 'pencil']" style="margin: 0 0.5rem;"/>
    <div style="margin-left: 8px; padding: 1rem 0;">
      <div>Etching a new rune starts here, click to begin!</div>
    </div>
  </router-link> -->
  <div class="runes-header">
    <h1>Runes</h1>
    <div style="display: flex; align-items: center;">
      <button class="option-button" style="margin: 0px 0.5rem;">
        <font-awesome-icon :icon="['fas', 'filter']" />
        <tippy interactive trigger="click" arrow="false" placement="bottom-end" to="parent" content-tag="div" theme="dpn">
          <div :class="{ 'menu-opt': true, 'active': this.filter_rune == 'all' }" @click="filter('all')">
            All Runes<font-awesome-icon v-if="this.filter_rune == 'all'" :icon="['fas', 'check']" style="margin-left: 1rem; color: #FFF" />
          </div>
          <div :class="{ 'menu-opt': true, 'active': this.filter_rune == 'fixed' }" @click="filter('fixed')">
            Fixed Supply<font-awesome-icon v-if="this.filter_rune == 'fixed'" :icon="['fas', 'check']" style="margin-left: 1rem; color: #FFF" />
          </div>
          <div :class="{ 'menu-opt': true, 'active': this.filter_rune == 'fair' }" @click="filter('fair')">
            Fair Mint<font-awesome-icon v-if="this.filter_rune == 'fair'" :icon="['fas', 'check']" style="margin-left: 1rem; color: #FFF" />
          </div>
        </tippy>
      </button>
      <button class="option-button" style="margin: 0px 0.5rem;">
        <font-awesome-icon :icon="['fas', 'arrow-up-wide-short']" />
        <tippy interactive trigger="click" arrow="false" placement="bottom-end" to="parent" content-tag="div" theme="dpn">
          <div :class="{ 'menu-opt': true, 'active': (this.sort_runes == 'id') }" @click="sort('id')">
            Number: lowest first<font-awesome-icon v-if="this.sort_runes == 'id'" :icon="['fas', 'check']" style="margin-left: 1rem; color: #FFF" />
          </div>
          <div :class="{ 'menu-opt': true, 'active': (this.sort_runes == 'time') }" @click="sort('time')">
            Newest first<font-awesome-icon v-if="this.sort_runes == 'time'" :icon="['fas', 'check']" style="margin-left: 1rem; color: #FFF" />
          </div>
        </tippy>
      </button>
    </div>
  </div>
  <div class="table-container">
    <RunesTableComponent :items="tokens"/>
    <InfiniteScrollComponent v-if="allowLoadTokens" @infinite="loadTokens" />
  </div>
</template>

<script>
import { inject } from 'vue';
import config from '@/config.js'
import utils from '@/utils';

import InfiniteScrollComponent from '@/components/InfiniteScrollComponent.vue';
import RunesTableComponent from '@/components/RunesTableComponent.vue';

export default {
  data() {
    return {
      page_number: 1,
      allowLoadTokens: true,
      filter_rune: 'all',
      sort_rune: 'id',
      usd_rate: inject('usd_rate', 0),
      tokens: {},
    };
  },
  async beforeRouteEnter(to, from, next) {
    let tokens_resp = await fetch(`${config.apiUrl}/runes`)
      .then(d => d.json());

    next(vm => {
      vm.tokens = tokens_resp['result'];
    });
  },
  components: {
    InfiniteScrollComponent,
    RunesTableComponent
  },
  methods: {
    async loadTokens() {
      if (!this.allowLoadTokens || this.tokens.length < 24) {
        return;
      }

      var tokens_paged = await fetch(`${config.apiUrl}/runes?page=${++this.page_number}&filter=${this.filter_rune}&sort=${this.sort_rune}`)
        .then(d => d.json());
      
      if (tokens_paged.status == 1) {
        if (tokens_paged.result.length < 24) {
          this.allowLoadTokens = false;
        }

        this.tokens.push(...tokens_paged.result);
      }
    },
    async filter(filter) {
      this.filter_rune = filter;
      this.page_number = 0;
      this.allowLoadTokens = true;

      var tokens_paged = await fetch(`${config.apiUrl}/runes?page=${++this.page_number}&filter=${this.filter_rune}&sort=${this.sort_rune}`)
          .then(d => d.json());

      if (tokens_paged.status == 1) {
        if (tokens_paged.result.length < 24) {
          this.allowLoadTokens = false;
        }
      }

      this.tokens = tokens_paged.result;
    },
    async sort(sort) {
      this.sort_rune = sort;
      this.page_number = 0;
      this.allowLoadTokens = true;

      var tokens_paged = await fetch(`${config.apiUrl}/runes?page=${++this.page_number}&filter=${this.filter_rune}&sort=${this.sort_rune}`)
          .then(d => d.json());

      if (tokens_paged.status == 1) {
        if (tokens_paged.result.length < 24) {
          this.allowLoadTokens = false;
        }
      }

      this.tokens = tokens_paged.result;
    },
    mintRune(runeId) {
      console.log(`Mint rune with ID ${runeId}`);
      this.emitter.emit("modal-mint-open", { rune_id: runeId });
    },
    ...utils,
  },
};
</script>

<style scoped>
.runes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

img.token-img {
  object-fit: cover;
}

.token-img {
  border-radius: 50%;
}

td.name {
  font-size: 1.1rem;
  font-weight: 600;
}

.volume, .price, .market-cap, .holders {
  white-space: nowrap;
}

.table-row .no-data {
  color: #8a939b;
  font-weight: 400;
}

.option-button {
  padding: 0.3rem;
  background: none;
  line-height: 0;
  border-radius: 8px;
}

button:not(:disabled):hover {
  background-color: #222;
}
</style>