<template>
  <button class="back-button" @click="$router.go(-1)">
    <font-awesome-icon :icon="['fas', 'arrow-left']" />
    <span>Go back</span>
  </button>
  <div class="rune">
    <a v-if="rune.parent" :href="'https://ordinalslite.com/inscription/' + rune.parent">
      <img :src="`https://ordinalslite.com/content/${rune.parent}`" :alt="'Inscription ' + rune.parent" class="inscription-content" loading="lazy"/>
    </a>
    <div class="rune-wrapper">
      <div class="rune-header">
        <div>
          <div class="rune-title">
            Rune
          </div>
          <div class="rune-spaced">
            {{ rune.spaced_rune }}
          </div>
        </div>
      </div>
      <div v-if="rune.mintable" class="mint-details">
        <div style="font-weight: 500; margin-bottom: 0.5rem; color: rgba(255, 255, 255, 0.8);">Mint Rune</div>
        <div style="margin-bottom: 0.5rem; text-align: center;">Progress: {{ (rune.mints / rune.terms.cap * 100).toFixed(0) }}%</div>
        <div class="progress" :title="rune.mints + '/' + rune.terms.cap">
          <div class="progress-bar  bg-success" role="progressbar" :style="{'width': (rune.mints / rune.terms.cap * 100) + '%'}"></div>
        </div>
        <button class="button-mint" @click="mintRune(rune.id)" style="margin-top: 1rem;">Mint</button>
      </div>
      <div class="rune-details">
        <div class="rune-detail">
          <div class="detail-name">Number</div>
          <div class="detail-value">{{ rune.number }}</div>
        </div>
        <div class="rune-detail">
          <div class="detail-name">Id</div>
          <div class="detail-value">{{ rune.id }}</div>
        </div>
        <div class="rune-detail">
          <div class="detail-name">Block</div>
          <div class="detail-value">{{ rune.block }}</div>
        </div>
        <div class="rune-detail">
          <div class="detail-name">Etching</div>
          <div class="detail-value"><a :href="`https://litecoinspace.org/tx/${rune.etching}`" target="_blank">{{ rune.etching }}</a></div>
        </div>
        <div class="rune-detail">
          <div class="detail-name">Symbol</div>
          <div class="detail-value">{{ rune.symbol }}</div>
        </div>
        <div class="rune-detail">
          <div class="detail-name">Divisibility</div>
          <div class="detail-value">{{ rune.divisibility }}</div>
        </div>
        <div class="rune-detail">
          <div class="detail-name">Supply</div>
          <div class="detail-value">{{ (rune.premine + ((rune.terms ? rune.terms.amount : 0) * rune.mints)) / Math.pow(10, rune.divisibility) }}</div>
        </div>
        <div class="rune-detail">
          <div class="detail-name">Premine</div>
          <div class="detail-value">{{ rune.premine / Math.pow(10, rune.divisibility) }}</div>
        </div>
        <div class="rune-detail">
          <div class="detail-name">Mintable</div>
          <div class="detail-value">{{ rune.mintable }}</div>
        </div>
        <div v-if="rune.terms" class="rune-detail">
          <div class="detail-name">Amount</div>
          <div class="detail-value">{{ rune.terms.amount / Math.pow(10, rune.divisibility) }}</div>
        </div>
        <div v-if="rune.terms" class="rune-detail">
          <div class="detail-name">Cap</div>
          <div class="detail-value">{{ rune.terms.cap }}</div>
        </div>
        <div v-if="rune.terms && rune.terms.height_start" class="rune-detail">
          <div class="detail-name">Height Start</div>
          <div class="detail-value">{{ rune.terms.height_start }}</div>
        </div>
        <div v-if="rune.terms && rune.terms.height_end" class="rune-detail">
          <div class="detail-name">Height End</div>
          <div class="detail-value">{{ rune.terms.height_end }}</div>
        </div>
        <div v-if="rune.terms && rune.terms.offset_start" class="rune-detail">
          <div class="detail-name">Offset Start</div>
          <div class="detail-value">{{ rune.terms.offset_start }}</div>
        </div>
        <div v-if="rune.terms && rune.terms.offset_end" class="rune-detail">
          <div class="detail-name">Offset End</div>
          <div class="detail-value">{{ rune.terms.offset_end }}</div>
        </div>
        <div class="rune-detail">
          <div class="detail-name">Mints</div>
          <div class="detail-value">{{ rune.mints }}</div>
        </div>
        <div class="rune-detail">
          <div class="detail-name">Burned</div>
          <div class="detail-value">{{ rune.burned }}</div>
        </div>
        <div class="rune-detail">
          <div class="detail-name">Turbo</div>
          <div class="detail-value">{{ rune.turbo }}</div>
        </div>
        <div class="rune-detail">
          <div class="detail-name">Timestamp</div>
          <div class="detail-value" :title="new Date(rune.timestamp * 1000)">{{ new Date(rune.timestamp * 1000).toUTCString() }}</div>
        </div>
        <div class="rune-detail">
          <div class="detail-name">Parent</div>
          <div v-if="rune.parent" class="detail-value"><router-link :to="{ name: 'inscription', params: { id: rune.parent }}">{{ rune.parent }}</router-link></div>
          <div v-if="!rune.parent" class="detail-value">-</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import config from '@/config.js'

export default {
  data() {
    return {
      isModalVisible: false,
      usd_rate: inject('usd_rate', 0),
      wallet_address: inject('wallet_address', false),
      rune: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    fetch(`${config.apiUrl}/runes/${to.params.id}`)
      .then(d => d.json())
      .then(data => {
        // Pass the fetched data to the next callback
        next(vm => {
          vm.rune = data.result;
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        next(from.path);
      })
  },
  methods: {
    short_address(str) {
      return str.substr(0, 5) + '...' + str.substr(str.length-5, str.length);
    },
    satToBtc(sat) {
      return Number(sat) / Math.pow(10, 8);
    },
    mintRune(runeId) {
      console.log(`Mint rune with ID ${runeId}`);
      this.emitter.emit("modal-mint-open", { rune_id: runeId });
    },
    isImage(contentType) {
      if (contentType) {
        return contentType.startsWith("image");
      }
      return false;
    }
  },
};
</script>

<style scoped>
.rune {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4rem;
  row-gap: 1rem;
  align-items: flex-start;
  margin-top: 1rem;
}

.rune>* {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0px;
}

.rune-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 30rem;
}

.rune-header {
  display: flex;
  margin-bottom: 2rem;
}

@media (max-width: 576px) {
  .rune-header {
    font-size: 1.5rem;
  }
}

.rune-title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
}

.rune-number {
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.8);
}

.inscription-content {
  width: 100%;
  min-width: 18rem;
  image-rendering: pixelated;
  max-height: 32rem;
  object-fit: contain;
  border-radius: 12px;
}

.rune-preview {
  border: none;
  height: 32rem;
}

.mint-details {
  background-color: #ffffff0d;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: 10px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width .6s ease;
}

.button-mint {
  width: 100%;
  font-weight: 800;
  color: #345D9D;;
  border: none;
  transition: all .25s ease-in-out;
  background-color: #D4D6E1;
}

.button-mint:hover {
  background-color: #345D9D;
  color: #fff
}

.buy-button {
  height: 30px;
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  background-color: #D4D6E1;
  font-weight: 700;
  color: #345D9D;
  cursor: pointer;
  transition: all .25s ease-in-out;
}

.rune-details {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  overflow: hidden;
  border-radius: 12px;
}

.rune-detail {
  padding: 0.75rem 1.5rem;
  background-color: #ffffff0d;
}

.detail-name {
  font-size: .9em;
  line-height: 1.2;
}

.detail-value {
  color: #fff;
  font-weight: 700;
  font-size: .95em;
  overflow-wrap: break-word;
}
</style>