<template>
  <div class="wrapper">
    <header>
      <div class="header-content">
        <img src="@/assets/chikun_market.png" alt="Logo" class="logo" title="Chikun Market" style="cursor: pointer;" @click="refreshPage"/>
        <div class="search-bar header-element">
          <font-awesome-icon icon="search" class="search-icon" />
          <input v-model="searchValue" ref="searchInput" type="text" placeholder="Search addresses, ltc-20 and more..." class="search-input" @keypress.enter="handleSubmit"/>
        </div>
        <button class="runes-button header-element" @click="navToRunes">
          <span class="runes-icon">
            <font-awesome-icon :icon="['fas', 'scroll']" />
          </span>
          <span class="runes-text">Runes</span>
        </button>
        <WalletComponent/>
      </div>
    </header>

    <main>
    <router-view/>
    </main>
  </div>

  <ModalBuyComponent/>
  <ModalListComponent/>
  <ModalDelistComponent/>
  <ModalSendComponent/>
  <ModalSendRuneComponent/>
  <ModalRuneBalanceDetailsComponent/>
  <ModalMintComponent/>
  <ModalLtc20ListComponent/>
  <SelectBarComponent/>

  <footer>
    <img src="@/assets/chikun_market.png" alt="Logo" class="footer-logo"/>
    <p>Chikun Market</p>
    <a class="footer-link" href="https://twitter.com/chikun_market" target="_blank">
      <span class="footer-icon">
        <font-awesome-icon :icon="['fab', 'twitter']" />
      </span>
      <span class="footer-icon-label">Twitter</span>
    </a>
    <a class="footer-link" href="https://t.me/chikunmarket_ltc" target="_blank">
      <span class="footer-icon">
        <font-awesome-icon :icon="['fab', 'telegram']" />
      </span>
      <span class="footer-icon-label">Telegram</span>
    </a>
  </footer>
</template>

<script>
import { ref, provide } from 'vue'
import config from '@/config.js'
import utils from '@/utils.js'
import WalletComponent from './components/WalletComponent.vue';
import ModalBuyComponent from './components/ModalBuyComponent.vue';
import ModalListComponent from './components/ModalListComponent.vue';
import ModalDelistComponent from './components/ModalDelistComponent.vue';
import ModalSendComponent from './components/ModalSendComponent.vue';
import ModalSendRuneComponent from './components/ModalSendRuneComponent.vue';
import ModalRuneBalanceDetailsComponent from './components/ModalRuneBalanceDetailsComponent.vue';
import ModalMintComponent from './components/ModalMintComponent.vue';
import ModalLtc20ListComponent from './components/ModalLtc20ListComponent.vue';
import SelectBarComponent from './components/SelectBarComponent.vue';

const wallet_address = ref("")
const wallet_connected = ref(false);
const wallet_balance = ref(0);
const usd_rate = ref(0);
const block_height = ref(0);

export default {
  data() {
    return {
      searchValue: "",
    }
  },
  setup() {
    provide('wallet_address', wallet_address);
    provide('wallet_connected', wallet_connected);
    provide('wallet_balance', wallet_balance);
    provide('usd_rate', usd_rate);
    provide('block_height', block_height);

    fetch(`${config.apiUrl}/market/summary`)
      .then(d => d.json())
      .then(j => {
        usd_rate.value = j.result.price
        block_height.value = j.result.block_height
      });
  },
  components: {
    WalletComponent,
    ModalBuyComponent,
    ModalListComponent,
    ModalDelistComponent,
    ModalSendComponent,
    ModalSendRuneComponent,
    ModalRuneBalanceDetailsComponent,
    ModalMintComponent,
    ModalLtc20ListComponent,
    SelectBarComponent,
  },
  methods: {
    refreshPage() {
      console.log(this.$route)
      if (this.$route.path == '/') {
        location.reload();
      } else {
        this.$router.push('/');
      }
    },
    handleSubmit() {
      const inputValue = this.searchValue.trim();
      let isMatched = false;

      console.log(inputValue);

      if (this.isValidAddress(inputValue)) {
        this.$router.push({
          name: "address",
          params: {
            address: inputValue,
          },
        });
        isMatched = true;
      } else if (
        inputValue.length === 66 &&
        /^[0-9a-f]{64}i\d+$/.test(inputValue)
      ) {
        this.$router.push({
          name: "inscription",
          params: {
            id: inputValue,
          },
        });
        isMatched = true;
      // } else if (/^#?\d+$/.test(inputValue)) {
      //   this.$router.push({
      //     name: "inscription",
      //     params: {
      //       id: inputValue.charAt(0) === "#" ? inputValue.slice(1) : inputValue,
      //     },
      //   });
      //   isMatched = true;
      } else if (/^\d+\.litemap$/.test(inputValue)) {
        this.$router.push({
          name: "litemap",
          params: {
            litemap: inputValue.split(".")[0],
          },
        });
        isMatched = true;
      } else if (this.utf8ByteLength(inputValue) == 4) {
        this.$router.push({
          name: "ltc20",
          params: {
            name: inputValue,
          },
        });
        isMatched = true;
      }

      if (isMatched) {
        this.searchValue = "";
        this.$refs.searchInput.blur();
      }
    },
    navToRunes() {
      if (this.$route.path == '/runes') {
        location.reload();
      } else {
        this.$router.push('/runes');
      }
    },
    isValidAddress(address) {
      // Ensure the address is provided.
      if (address === undefined) {
          return false;
      }

      // Ensure the address is a string.
      if (typeof address !== 'string') {
          return false;
      }

      // Quick and dirty regex pattern for validating the address
      const pattern = new RegExp(
          `^[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}$`,
      );

      const segwit = new RegExp(
          `^ltc1[a-zA-HJ-NP-Z0-9]{25,59}$`,
      );

      // Test the regex
      if (!pattern.test(address) && !segwit.test(address)) return false;

      return true;
    },
    ...utils
  }
};
</script>

<style>
:root {
  color-scheme: dark;
  color: #fffffff2;
}

body {
  margin: 0px;
  background-color: #111;
  font-family: consolas;
  display: flex;
  flex-direction: column;
  font-family: 'Consolas', 'Monaco', 'Lucida Console', 'Courier New', monospace;
  min-height: 100vh;
}

#app {
  color: #fffffff2;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper {
  max-width: 1200px;
  width: -webkit-fill-available;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

a {
  color: #D4D6E1;
  font-weight: 700;
  text-decoration: inherit;
  cursor: pointer;
}

a:hover {
  color: #345D9D;
}

.back-button>svg {
  padding-right: 0.4rem;
}

header {
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.header-content .logo {
  margin-top: -0.5rem;
  margin-bottom: -0.7rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.header-content {
  width: 100%;
  height: 45px;
  display: flex;
}

.header-element:hover {
  background-color: #20222580;
  border-color: #ffffff52;
}

.header-element {
  background-color: #ffffff1f;
  color: #fff;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  transition: background-color .2s cubic-bezier(.05,0,.2,1) 0s,border-color .2s cubic-bezier(.05,0,.2,1) 0s;
  border-radius: 12px;
  border: 1px solid transparent;
}

.logo {
  width: 64px;
  height: 64px;
}

.footer-logo {
  width: 32px;
  height: 32px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  padding: 0 0.5rem;
}

.table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
}

.table-head {
  padding: 0.5rem;
  color: #8a939b;
  font-weight: 400;
  font-size: .95rem;
  text-align: left;
}

.table th {
  font-weight: 400;
  padding: 0.5rem 0.75rem;
  width: 1%;
}

.table th, .table td {
  width: auto;
}

.table td {
  padding: 1rem 0.75rem;
  width: 1%;
  vertical-align: middle;
}

.table-row.clickable {
  cursor: pointer;
}

.table-row {
  padding: 1rem 0;
  color: #fff;
  transition: all .15s ease-in-out;
}

.table-row.clickable:hover>td {
  color: #fff;
  background-color: #4c505c33;
}

.table-row.clickable>td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.table-row.clickable>td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.table-row .inscription-img {
  display: flex;
  width: 42px;
  height: 42px;
  vertical-align: middle;
  image-rendering: pixelated;
  align-items: center;
  text-align: center;
}

th.name {
  white-space: nowrap;
}

button {
  border-radius: 12px;
  border: 1px solid transparent;
  padding: .5em 1em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  transition: all .2s ease-in-out;
}

button:disabled {
  background-color: #1a1a1a;
  color: #fff;
}

button:enabled {
  cursor: pointer;
}

button.small {
  font-size: .8rem;
  padding: 0.4em 0.6em;
}

button.white {
  background-color: #fff;
  color: #000;
}

button.white:enabled:hover {
  background-color: #dedede;
}

button.red {
  background-color: tomato;
  color: #fff;
}

button.red:enabled:hover {
  background-color: #ff4524;
}

button.outline {
  border: 2px solid white;
  color: #fff;
  background: none;
}

button.outline:enabled:hover {
  background-color: #dedede;
  border-color: #e9e9e9;
  color: #000;
}

.confirm-button {
  display: flex;
  justify-content: center;
  color: #fff;
  font-weight: 700;
}

.confirm-button:not(:disabled):hover {
  background-color: #345D9D;
}

.confirm-button:not(:disabled) {
  background-color: #345D9D;
}

.search-bar {
  display: flex;
  width: 100%;
  line-height: 26px;
  padding: 0.5em;
  margin: 0 1rem;
  cursor: text;
  align-items: center;
}

.search-bar:focus-within {
  background-color: #20222580;
  border-color: #ffffff52;
  outline: rgba(255,255,255,.32) solid 2px
}

.search-input {
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
}

.search-icon {
  flex-shrink: 0;
  margin-right: 10px;
  color: #BEBEBE;
}

.inscribe-button, .runes-button {
  margin-right: 1rem;
}

.wallet-button, .inscribe-button, .runes-button {
  padding: 0.25em 1em;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.wallet-icon, .inscribe-icon, .runes-icon {
  line-height: 1;
  position: relative;
}

.wallet-text, .inscribe-text, .runes-text {
  white-space: nowrap;
  margin-left: 10px;
}

@media (max-width: 576px) {
  .wallet-text, .inscribe-text, .runes-text {
    display: none;
  }
}

main {
  padding: 0 2rem 1rem 2rem;
}

@media (max-width: 576px) {
  main {
    padding: 0 0.75rem 0.5rem 0.75rem;
  }
}

.tabs.small {
  font-size: 90%;
  margin: 0.5rem 0;
}

.tabs {
  display: flex;
  margin: 1.5rem 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.tabs.small>li+li {
  margin-left: 0.5rem;
}

.tabs.small>li {
  padding: 0.2rem 0.4rem;
}

.tabs>li+li {
  margin-left: 1rem;
}

.tabs>li {
  padding: 0.4rem 0.6rem;
  border-radius: 12px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  color: #ffffffd9;
  font-weight: 600;
  white-space: nowrap;
}

.tabs>li.active {
  color: #fff;
  background-color: #353840;
}

.cards-wrapper {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}

@media (max-width: 576px) {
  .cards {
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill,minmax(10rem,1fr));
  }
}

.cards {
  display: flex;
  grid-gap: 10px;
  flex-wrap: wrap;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill,minmax(12rem,1fr));
}

.item-card {
  border: 1px solid #BEBEBE;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  max-width: 200px;
  background-color: #111;
  color: #BEBEBE;
}

.hot-badge {
  font-weight: 700;
  display: inline-block;
  background-color: #ffffff14;
  border-radius: 12px;
  padding: 0.3rem 0.6rem;
  line-height: 1.5rem;
}

.inscription-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  background-color: #4c505c33;
  padding: 1rem 1rem 0.5rem 1rem;
  border: 2px solid transparent;
  transition: all .15s ease-in-out;
}

.inscription-preview {
  width: 8rem;
  height: 8rem;
  object-fit: contain;
  border-radius: 5px;
  image-rendering: pixelated;
}

.listing .inscription-number {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 12px;
  color: #fff;
  background-color: #0000006b;
  padding: 0 0.5rem;
  font-weight: 600;
  font-size: .9rem;
  pointer-events: none;
}

.listing .rarity {
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 12px;
  color: #fff;
  background-color: #0000006b;
  padding: 0 0.5rem;
  font-weight: 600;
  font-size: .9rem;
}

.inscription-img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
  background-color: #00000080;
  display: block;
  image-rendering: pixelated;
  border-radius: 12px;
}

.inscription-iframe {
  border: none;
}

.listing:hover:hover {
  border: 1px solid rgb(52, 93, 157)
}

.listing:hover .buy-button:enabled {
  background-color: #345D9D;
  color: #fff
}

.listing {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 0.25rem 0.75rem 0.75rem;
  background-color: #4c505c33;
  border: 1px solid transparent;
  transition: all .25s ease-in-out;
}

.listing-item {
  text-align: center;
  font-size: 1.1rem;
  margin: 0.25rem 0;
  color: #fff;
}

.listing-info {
  display: flex;
  justify-content: space-between;
  font-size: .8rem;
  color: #fffc;
}

.listing-price {
  margin-top: 0.4rem;
  border-top: 1px solid rgba(255, 255, 255, .1);
  text-align: center;
  padding: 0.5rem 0;
}

.litecoin-icon {
  width: 1.1em;
  height: 1.1em;
  margin-right: 0.4em;
  margin-bottom: -0.2em;
}

.usd-value {
  font-size: .9rem
}

.buy-button:disabled {
  background-color: #ffffff12;
  color: #cacaca
}

.tippy-box[data-theme~=dpn] {
  font-size: inherit;
  background-color: #121212;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 12px;
  box-shadow: #ffffff26 0 0 13px
}

.tippy-box[data-theme~=dpn] .tippy-content {
  padding: 8px
}

.tippy-box[data-theme~=dpn]>.tippy-svg-arrow {
  display: none;
}

.tippy-box[data-theme~=wallet] {
  font-size: inherit;
  background-color: #ffffff1a;
  color: #fff;
  -webkit-backdrop-filter: blur(42px);
  backdrop-filter: blur(42px);
  border-radius: 12px;
  width: min(400px,100vw - 1.5rem);
  min-height: 20rem;
  max-height: 30rem;
  overflow-y: auto;
  display: flex;
}

.tippy-box[data-theme~=wallet]>.tippy-content {
  display: flex;
  padding: 1rem;
  width: 100%;
}

.tippy-box[data-theme~=wallet]>.tippy-svg-arrow {
  display: none;
}

.tippy-box[data-theme~='success'] {
  background-color: #3fae2e;
}

.tippy-box[data-theme~='success'] .tippy-arrow {
  color: #3fae2e;
}

.menu-opt:hover {
  background-color: #24292e;
}

.menu-opt {
  border-radius: 8px;
  cursor: pointer;
  padding: 0.5rem;
  transition: all .15s ease 0s;
}

.menu-opt>i {
  margin-bottom: -2px;
  margin-right: 5px;
}

.wallet-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

footer {
  height: 60px;
  display: flex;
  margin: 0 1rem;
  align-items: center;
  background-color: #111;
  color: #BEBEBE;
  font-size: 1.2rem;
}

footer>p {
  margin: 0 1rem;
}

.footer-link:hover {
  color: #fff;
  background-color: #202225;
  box-shadow: #00000026 0 3px 15px;
}

.footer-link {
  display: flex;
  align-items: center;
  border-radius: 12px;
  transition: all .15s ease 0s;
  font-size: .9rem;
  border: 0;
  line-height: 0;
  color: #fff;
  margin: 0 0.25rem;
  padding: 0.4rem 0.6rem;
}

.footer-icon {
  margin-right: 0.3rem;
}
</style>
