<template>
  <table class="table">
    <thead class="table-head">
      <tr>
        <th class="rank"># Rank</th>
        <th class="address">Address</th>
        <th class="points">Points</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr v-for="leaderboard in items" :key="leaderboard.rank" :class="{ 'table-row': true, 'own-rank': leaderboard.address === wallet_address }">
        <td class="rank">{{ leaderboard.rank.toLocaleString() }}</td>
        <td class="address">
          <router-link :to="{ name: 'address', params: { address: leaderboard.address }}">{{ leaderboard.address }}</router-link>
        </td>
        <td class="points">{{ leaderboard.points.toLocaleString() }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { inject } from 'vue'
import utils from '@/utils';

export default {
  data() {
    return {
      wallet_address: inject('wallet_address', ''),
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
  },
  methods: {
    ...utils
  }
}
</script>

<style>
.item-container {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.table-row {
  width: 42px;
  height: 42px;
  vertical-align: middle;
  object-fit: cover;
}

.table td {
  width: auto;
}

th.rank, th.address, th.points {
  white-space: nowrap;
}

th.rank {
  width: 50px;
}

.own-rank {
  background-color: #345D9D;
}
</style>