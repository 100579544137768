<template>
  <div v-show="isModalVisible" class="modal">
    <div class="modal-backdrop" @click="close"></div>
    <div class="modal-wrap">
      <div class="modal-window">
        <button
            type="button"
            class="btn-close"
            @click="close"
          >
          x
        </button>
        <h1 class="modal-header">
          <slot name="header">
            Delist Inscription{{ inscriptions.length > 1 ? "s" : ""}}
          </slot>
        </h1>
  
        <div class="modal-body">
          <slot name="body">
            <div class="body-items">
              <!-- placeholder -->
              <div v-if="!inscriptions || inscriptions.length == 0" class="body-item">
                <div>
                  <div class="inscription-preview placeholder-loading"></div>
                </div>
                <div>‎</div>
              </div>
              <div v-for="inscription in inscriptions" :key="inscription.inscription_id" class="body-item">
                <div v-if="!inscription.content && inscription.inscription_id">
                  <img v-if="isImage(inscription.contentType)" class="inscription-preview" :src="`https://ordinalslite.com/content/${inscription.inscription_id}`">
                  <div v-if="isIframe(inscription.contentType)" class="inscription-img inscription-iframe">
                    <iframe scrolling="no" loading="lazy" style="transform: scale(0.25); transform-origin: 0 0; width: 400%; height: 400%; border: 0;" :src="'https://ordinalslite.com/preview/' + inscription.inscription_id"></iframe>
                  </div>
                </div>
                <div v-if="inscription.content" class="item-text">
                  {{ inscription.content }}
                </div>
                <div>#{{ inscription.inscription_number }}</div>
              </div>
            </div>
          </slot>
        </div>
        <div v-if="inscriptions.length > 0" class="info-message" style="text-align: center; font-size: 1.25rem; padding-bottom: 1rem;">Are you sure you want to delist the selected inscription{{ inscriptions.length > 1 ? "s" : ""}}?</div>
        <div v-if="errorMessage" class="error-message" style="color: red; text-align: center; padding-bottom: 1rem;">{{ errorMessage }}</div>
        <button
          type="button"
          class="modal-button confirm-button"
          :disabled="!wallet_connected || !isReady || isRemovingListing"
          @click="delistInscription"
        >
          <font-awesome-icon v-if="isRemovingListing" class="fa-spin" style="margin-right: 0.5rem" :icon="['fas', 'spinner']" />
          {{ !wallet_connected ? 'Connect wallet to remove Listing' : (isRemovingListing ? 'Removing Listing' : 'Delist') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from "vue-toastification";
import config from '@/config.js'

export default {
  name: 'ModalComponent',
  
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isReady: false,
      isModalVisible: false,
      isRemovingListing: false,
      errorMessage: null,
      inscriptions: [],
      inscription_ids: [],
      wallet_connected: inject('wallet_connected', false),
      wallet_address: inject('wallet_address', false),
    };
  },
  methods: {
    close() {
      this.isModalVisible = false;
      this.isRemovingListing = false;
      this.errorMessage = null;
    },
    async delistInscription() {
      this.isRemovingListing = true;

      for (var i = 0; i < this.inscriptions.length; i++) {
        var inscription = this.inscriptions[i];
        const response = await fetch(`${config.apiUrl}/market/cancelListing`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            address: this.wallet_address,
            inscription_id: inscription.inscription_id,
            tx_id: "",
          }),
        });
        if (!response.ok) {
          this.errorMessage = response.statusText;
          this.isRemovingListing = false;
          return;
        }
        var result = await response.json();
        if (result.status == 0) {
          this.errorMessage = result.message;
          this.isRemovingListing = false;
          return;
        }
        this.toast.success("Listing delisted successfully", {
          position: "bottom-right"});
      }
      this.isModalVisible = false;
      this.emitter.emit("select-bar-close");
    },
    satToBtc(sat) {
      return Number(sat) / Math.pow(10, 8);
    },
    btcToSat(btc) {
      return Math.floor(Number(btc) * Math.pow(10, 8));
    },
    isImage(contentType) {
      if (contentType) {
        return contentType.startsWith("image");
      }
      return false;
    },
    isIframe(contentType) {
      if (contentType) {
        return contentType.startsWith("text/html");
      }
      return false;
    }
  },
  mounted() { 
    this.emitter.on("modal-delist-open", (parameters) => {
      this.isReady = false;
      this.isModalVisible = true;
      this.isRemovingListing = false;
      this.errorMessage = null;
      this.inscriptions = [];
      this.inscription_ids = parameters.inscriptions;
      if (this.inscription_ids) {
        for (var i = 0; i < this.inscription_ids.length; i++) {
          fetch(`${config.apiUrl}/inscription/${this.inscription_ids[i]}`)
          .then(d => d.json())
          .then(j => {
            if (j.status == 1) {
              this.inscriptions.push({
                "inscription_id": j.result.inscriptionId,
                "inscription_number": j.result.inscriptionNumber,
                "content": j.result.contentBody,
                "contentType": j.result.contentType,
                "location": j.result.location,
                "output": j.result.output
              })
              console.log(this.inscriptions)
              this.isReady = true;
            }
          })
        }
      } else {
        this.errorMessage = "No inscriptions selected";
      }
    });
  },
};
</script>

<style>
  .modal-body {
    position: relative;
    padding: 0 10px 20px 10px;
  }

  .modal-backdrop {
    position: fixed;
    border: none;
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    opacity: 0.6;
    transition: opacity .15s ease;
    background-color: #000;
  }

  .hystmodal__shadow--show {
    pointer-events: auto;
    opacity: .6;
  }

  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    z-index: 99;
  }

  .modal-wrap {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    min-height: 100%;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    z-index: 97;
  }

  .modal-window {
    display: flex;
    flex-direction: column;
    margin: 50px 0;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    width: 500px;
    max-width: calc(100% - 1rem);
    min-height: 500px;
    overflow: visible;
    pointer-events: all;
    transition: transform .2s ease 0s,opacity .2s ease 0s;
    transform: scale(.9);
    background-color: #ffffff1f;
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    border-radius: 12px;
    padding: 1.5rem;
  }

  @media (max-width: 576px) {
    .modal-window {
      padding: .75rem;
    }
  }

  .modal-amount {
    margin-top: 1em;
    font-size: larger;
  }

  .body-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .body-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .body-grid {
    margin-top: 1rem;
  }

  .inscription-iframe {
    height: 8rem;
    overflow: hidden;
  }

  .btn-close {
    position: absolute;
    z-index: 10;
    display: block;
    top: 14px;
    right: 14px;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    font-size: 0;
    cursor: pointer;
    outline: none;
  }

  .modal-box {
    max-height: 26rem;
    overflow-y: auto
  }

  @media (max-width: 576px) {
    .modal-box {
      max-height: 20rem;
    }
  }

  .modal-row {
    display: flex;
    justify-content: center;
    line-height: 2;
  }

  .modal-row>div {
    width: 50%;
  }

  .modal-row>div:first-child {
    text-align: right;
    padding-right: 2rem;
  }

  .modal-row .modal-row-value {
    text-align: left;
  }

  .modal-row .modal-row-input {
    padding: .1rem;
  }

  @media (max-width: 576px) {
    .modal-row>div:first-child {
      padding-right:1rem;
    }
  }

  .modal-button {
    margin-top: auto;
  }

  .modal-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .item-text {
    text-align: center;
    margin-top: 2.75rem;
    margin-bottom: 2.75rem
  }

  .modal-total {
    margin-top: 1em;
    font-size: larger;
  }

  @media all and (max-width: 767px) {
    .modal-window {
      margin: 0;
    }
  }

  .modal-header {
    margin-top: 0;
    text-align: center;
    font-weight: 600;
  }

  @media (max-width: 576px) {
    .modal-header {
      font-size: 1.5rem;
    }
  }
</style>