<template>
  <div v-show="isModalVisible" class="modal">
    <div class="modal-backdrop" @click="close"></div>
    <div class="modal-wrap">
      <div class="modal-window">
        <button
            type="button"
            class="btn-close"
            @click="close"
          >
          x
        </button>
        <h1 class="modal-header">
          <slot name="header">
            Send Rune
          </slot>
        </h1>
  
        <div class="modal-body">
          <slot name="body">
            <div class="body-items">
              <!-- placeholder -->
              <div v-if="!rune" class="body-item">
                <div>
                  <div class="inscription-preview placeholder-loading"></div>
                </div>
                <div>‎</div>
              </div>
              <div v-if="rune" class="body-item">
                <div>
                  <img v-if="rune.parent" class="inscription-preview" loading="lazy" :src="`https://ordinalslite.com/content/${rune.parent}`">
                  <img v-if="!rune.parent" class="inscription-preview" src="@/assets/rune.png">
                </div>
                <div>{{ rune.id }}</div>
              </div>
            </div>
            <div class="body-grid">
              <div class="modal-row">
                <div>Rune:</div>
                <div class="modal-row-value">
                  <span>{{ spaced_rune }}</span>
                </div>
              </div>
              <div class="modal-row">
                <div>Balance:</div>
                <div class="modal-row-value">
                  <span>{{ amountToDecimal(Number(rune_amount), rune_divisibility).toLocaleString() }} {{ rune_symbol }}</span>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; width: 100%; margin: 2rem 0px 1rem 0px;">
              <div style="font-size: 1.1rem; font-weight: 600; margin-right: 1rem; white-space: nowrap;">Send to:</div>
              <input v-model="receiver_address" class="modal-input" type="text" placeholder="LTC Address" style="width: 100%; max-width: 24rem;">
            </div>
            <div class="modal-row">
              <div style="font-size: 1.1rem; font-weight: 600;">Amount:</div>
              <div class="modal-row-value">
                <input v-model="send_amount" :class="{ 'modal-input': true, 'input-invalid': send_amount > amountToDecimal(Number(rune_amount), rune_divisibility) }" type="number" min="0.01" step="any">
                {{ rune_symbol }}
              </div>
            </div>
          </slot>
        </div>
        <div class="modal-row" style="margin-top: auto;">
          <div>Network Fee:</div>
          <div class="modal-row-value">
            <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>
            ≈ {{ satToBtc(network_fee) }}
            <span class="usd-value">(${{ (satToBtc(network_fee) * usd_rate).toFixed(2) }})</span>
          </div>
        </div>
        <div v-if="errorMessage" class="error-message" style="color: red; text-align: center; padding-bottom: 1rem;">{{ errorMessage }}</div>
        <button
          type="button"
          class="modal-button confirm-button"
          style="margin-top: 1rem;"
          :disabled="!wallet_connected || !isReady || isSendingRune || receiver_address == null || send_amount == null || send_amount <= 0 || send_amount > amountToDecimal(Number(rune_amount), rune_divisibility) || !isValidAddress(receiver_address)"
          @click="sendRune"
        >
        {{  }}
          <font-awesome-icon v-if="isSendingRune" class="fa-spin" style="margin-right: 0.5rem" :icon="['fas', 'spinner']" />
          {{ !wallet_connected ? 'Connect wallet to Send' : receiver_address == null ? "Enter an address" : (!isValidAddress(receiver_address) ? "Invalid address" : (send_amount <= 0 || send_amount > amountToDecimal(Number(rune_amount), rune_divisibility)) ? "Enter an amount" : "Send Rune") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from "vue-toastification";
import config from '@/config.js'
import utils from '@/utils.js'

export default {
  name: 'ModalComponent',
  
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isReady: false,
      isModalVisible: false,
      isSendingRune: false,
      errorMessage: null,
      rune: {},
      spaced_rune: null,
      rune_amount: 0,
      network_fee: 200,
      receiver_address: null,
      send_amount: null,
      usd_rate: inject('usd_rate', 0),
      available_balance: inject('wallet_balance', 0),
      wallet_connected: inject('wallet_connected', false),
      wallet_address: inject('wallet_address', false),
      wallet_provider: this.$walletProvider,
    };
  },
  methods: {
    close() {
      this.isModalVisible = false;
      this.isSendingRune = false;
      this.errorMessage = null;
    },
    async sendRune() {
      if (!this.isReady) return;

      this.isSendingRune = true;
      this.errorMessage = null;

      console.log("send rune");

      await this.wallet_provider.updatePayerAddress(this.wallet_address, 3000, false);

      var utxos = this.wallet_provider.paymentUtxos;

      var rune_amount = utils.decimalToAmount(this.send_amount, this.rune.divisibility);

      var runic_utxos = await this.wallet_provider.getRunicUtxos(this.wallet_provider.payerUtxos, this.rune.spaced_rune, rune_amount);

      var unsigned_send_psbt = await this.wallet_provider.generatePSBTSendRune(this.wallet_address, this.receiver_address, this.rune.id, rune_amount, runic_utxos, utxos);

      console.log(unsigned_send_psbt)

      try {
        var txid = await this.wallet_provider.signPSBTUsingWalletAndBroadcast(unsigned_send_psbt);
        console.log(txid);
      } catch (e) {
        this.isSendingRune = false;
        this.errorMessage = e;
        console.error(e);
        return;
      }

      this.toast.success(`Rune sent successfully`, {
        position: "bottom-right"});
      this.isSendingRune = false;
      this.isModalVisible = false;
    },
    isImage(contentType) {
      if (contentType) {
        return contentType.startsWith("image");
      }
      return false;
    },
    isIframe(contentType) {
      if (contentType) {
        return contentType.startsWith("text/html");
      }
      return false;
    },
    ...utils
  },
  mounted() { 
    this.emitter.on("modal-rune-send-open", (parameters) => {
      this.isReady = false;
      this.isModalVisible = true;
      this.isSendingRune = false;
      this.receiver_address = null;
      this.errorMessage = null;
      this.content = null;
      this.rune = null;
      this.spaced_rune = parameters.spaced_rune;
      this.rune_amount = parameters.amount;
      this.rune_divisibility = parameters.divisibility;
      this.rune_symbol = parameters.symbol;
      if (this.spaced_rune) {
        fetch(`${config.apiUrl}/runes/${this.spaced_rune}`)
        .then(d => d.json())
        .then(j => {
          if (j.status == 1) {
            this.rune = j.result;
            this.isReady = true;
          }
        })
      } else {
        this.errorMessage = "No Rune provided";
      }
    });
  }
};
</script>

<style scoped>
  .modal-body {
    position: relative;
    padding: 0 10px 20px 10px;
  }

  .modal-backdrop {
    position: fixed;
    border: none;
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    opacity: 0.6;
    transition: opacity .15s ease;
    background-color: #000;
  }

  .hystmodal__shadow--show {
    pointer-events: auto;
    opacity: .6;
  }

  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    z-index: 99;
  }

  .modal-wrap {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    min-height: 100%;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    z-index: 97;
  }

  .modal-window {
    display: flex;
    flex-direction: column;
    margin: 50px 0;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    width: 500px;
    max-width: calc(100% - 1rem);
    min-height: 500px;
    overflow: visible;
    pointer-events: all;
    transition: transform .2s ease 0s,opacity .2s ease 0s;
    transform: scale(.9);
    background-color: #ffffff1f;
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    border-radius: 12px;
    padding: 1.5rem;
  }

  @media (max-width: 576px) {
    .modal-window {
      padding: .75rem;
    }
  }

  .modal-input {
    margin-right: 0.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    width: 6rem;
    text-align: left;
    font-size: inherit;
  }

  .modal-row.modal-input {
    padding: 0.1rem;
  }

  .modal-input:focus {
    border-bottom: 1px solid #007aff;
  }

  .modal-input:enabled {
    border-bottom: 1px solid tan;
  }

  .modal-amount {
    margin-top: 1em;
    font-size: larger;
  }

  .input-invalid {
    color: #ff4500;
  }

  .body-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .body-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .body-grid {
    margin-top: 1rem;
  }

  .inscription-iframe {
    height: 8rem;
    overflow: hidden;
  }

  .btn-close {
    position: absolute;
    z-index: 10;
    display: block;
    top: 14px;
    right: 14px;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    font-size: 0;
    cursor: pointer;
    outline: none;
  }

  .modal-box {
    max-height: 26rem;
    overflow-y: auto
  }

  @media (max-width: 576px) {
    .modal-box {
      max-height: 20rem;
    }
  }

  .modal-row {
    display: flex;
    justify-content: center;
    line-height: 2;
  }

  .modal-row>div {
    width: 50%;
  }

  .modal-row>div:first-child {
    text-align: right;
    padding-right: 2rem;
  }

  .modal-row .modal-row-value {
    text-align: left;
  }

  .modal-row .modal-row-input {
    padding: .1rem;
  }

  @media (max-width: 576px) {
    .modal-row>div:first-child {
      padding-right:1rem;
    }
  }

  .modal-button {
    margin-top: auto;
  }

  .modal-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .item-text {
    text-align: center;
    margin-top: 2.75rem;
    margin-bottom: 2.75rem
  }

  .modal-total {
    margin-top: 1em;
    font-size: larger;
  }

  @media all and (max-width: 767px) {
    .modal-window {
      margin: 0;
    }
  }

  .modal-header {
    margin-top: 0;
    text-align: center;
    font-weight: 600;
  }

  @media (max-width: 576px) {
    .modal-header {
      font-size: 1.5rem;
    }
  }
</style>