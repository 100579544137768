<template>
  <table class="table">
    <thead class="table-head">
      <tr>
        <th class="item">#</th>
        <th class="address">Address</th>
        <th class="balance">Balance</th>
        <th class="percentage">%</th>
        <th class="value">Value</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr v-for="(holder, index) in items" :key="holder.id" class="table-row">
        <td class="item">{{ index + 1 }}</td>
        <td class="address"><router-link :to="{ name: 'address', params: { address: holder.address }}">{{ holder.address }}</router-link></td>
        <td class="balance">{{ (Number(holder.balance) + Number(holder.transfer_balance)).toLocaleString() }}</td>
        <td class="percentage">{{ ((Number(holder.balance) + Number(holder.transfer_balance)) / supply * 100).toFixed(2) }}%</td>
        <td class="value">
          <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>{{ ((Number(holder.balance) + Number(holder.transfer_balance)) * satToBtc(price)).toLocaleString() }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import utils from '@/utils';

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    supply: {
      type: Number
    },
    price: {
      type: Number
    },
  },
  methods: {
    ...utils
  }
}
</script>

<style>
.item-container {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}
</style>