import utils from '@/utils';

export const GateWallet = {
  provider: null,
  address: null,
  setProvider(provider) {
    this.provider = provider;
  },
  async initialize() {
    // Force unlock
    if (this.isWalletInstalled) {
        await window.gatewallet.connect();
    }
  },
  async getWalletAddress() {
    if (this.isWalletInstalled()) {
      this.address = (await window.gatewallet.getAccount())?.['accountNetworkArr'].find(account => account.network === "LTC" && account.accountFormat === "BIP84")?.['address'];
      return this.address;
    }
  },
  async getBalance() {
    const balance = {
        total: 1000000000
    }
    return balance
    // return await window.gatewallet.bitcoin.getBalance();
  },
  async sendBitcoin(address, amount) {
    console.log(`Sending ${amount} LTC to ${address}...`);
    return await window.gatewallet.bitcoin.sendBitcoin({ fromAddress: this.address, toAddress: address, satoshis: amount, network: "LTC"});
  },
  async sendInscription(address, inscriptionId) {
    console.log(`Sending ${inscriptionId} to ${address}...`);
    return await window.gatewallet.bitcoin.sendInscription({ fromAddress: this.address, toAddress: address, inscriptionId, network: "LTC", option: { feeRate: 1 }});
  },
  async signPsbt(psbtBase64) {
      await this.getWalletAddress();

      return await window.gatewallet.bitcoin.signPsbt({ address: this.address, psbtHex: utils.base64ToHex(psbtBase64) });
  },
  async signPsbts(psbtsBase64) {
      await this.getWalletAddress();

      var psbtsHex = [];
      for (var i = 0; i< psbtsBase64.length; i++) {
          psbtsHex.push(utils.base64ToHex(psbtsBase64[i]))
      }
      return await window.gatewallet.bitcoin.signPsbts({ address: this.address, psbtHexs: psbtsHex });
  },
  async signMessage(message) {
      await this.getWalletAddress();

      return window.gatewallet.bitcoin.signMessage({ fromAddress: this.address, text: message });
  },
  isWalletInstalled() {
      if (typeof window.gatewallet !== "undefined") {
          return true;
      }
      throw new Error("Gate Wallet not installed");
  }
};