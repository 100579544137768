<template>
  <h1>Etch</h1>
  <form class="etch" @submit.prevent="submit">
    <div class="etch-header">
      <div style="font-weight: bold;">Create a new rune token</div>
    </div>
    <div>
      <div style="margin-top: 1rem; margin-bottom: 0.5rem;">Rune Name:</div>
      <div class="input-container" style="width: 100%;">
        <input v-model="rune_name" style="text-transform: uppercase;" type="text" placeholder="Name" name="rune_name" maxlength="52" required>
      </div>
    </div>
    <div>
      <div style="margin-top: 1rem; margin-bottom: 0.5rem;">Rune Image:</div>
      <label class="inscribe-selector" role="button" for="fileUpload">
        <div v-if="!files || files.length == 0" style="display: flex; flex-direction: column; width: 100%; height: 100%; justify-content: center; align-items: center;">
          <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" />
          <div style="font-size: 0.7rem; margin-top: 0.2rem">(optional)</div>
        </div>
        <div v-if="files && files.length > 0" class="file-container">
          <img :src="file_preview" class="image-preview" alt="Preview" />
        </div>
      </label>
      <input type="file" id="fileUpload" name="upload[]" @change="handleFiles" style="display: none;" accept>
    </div>
    <div style="display: flex; flex-direction: row; column-gap: 1rem;">
      <div style="width: 100%;">
        <div style="margin-top: 1rem; margin-bottom: 0.5rem;">Decimals:</div>
        <div class="input-container" style="width: 100%;">
          <input v-model="rune_divisibility" type="number" placeholder="0 to 38" name="rune_divisibility" min="0" max="38" step="1" required>
        </div>
      </div>
      <div style="width: 100%;">
        <div style="margin-top: 1rem; margin-bottom: 0.5rem;">Symbol:</div>
        <div class="input-container" style="width: 100%;">
          <input v-model="rune_symbol" type="text" placeholder="One character" name="rune_symbol" minlength="1" maxlength="1" required>
        </div>
      </div>
    </div>
    <div>
      <div style="margin-top: 1rem; margin-bottom: 0.5rem;">Max Supply:</div>
      <div class="input-container" style="width: 100%;">
        <input v-model="rune_supply" type="number" placeholder="84000000" name="rune_supply" required>
      </div>
    </div>
    <div style="width: 100%;">
      <div style="margin-top: 1rem; margin-bottom: 0.5rem;">Premine:</div>
      <div class="input-container" style="width: 100%;">
        <input v-model="rune_premine" type="number" placeholder="Premine Amount (optional)" name="rune_premine">
      </div>
    </div>
    <div>
      <div style="margin-top: 1rem; margin-bottom: 0.5rem;">Limit Per Mint:</div>
      <div class="input-container" style="width: 100%;">
        <input v-model="rune_limit" type="number" placeholder="1000 (optional)" name="rune_limit">
      </div>
      <div v-if="rune_limit && (rune_supply % rune_limit != 0)" class="input-invalid">
        Supply must be divisible by limit per mint.
      </div>
    </div>
    <div style="display: flex; flex-direction: row; column-gap: 1rem;">
      <div style="width: 100%;">
        <div style="margin-top: 1rem; margin-bottom: 0.5rem;">Starting Block Number:</div>
        <div class="input-container" style="width: 100%;">
          <input v-model="rune_start_height" type="number" placeholder="Block Number (optional)" name="rune_start_height">
        </div>
      </div>
      <div style="width: 100%;">
        <div style="margin-top: 1rem; margin-bottom: 0.5rem;">Ending Block Number:</div>
        <div class="input-container" style="width: 100%;">
          <input v-model="rune_end_height" type="number" placeholder="Block Number (optional)" name="rune_end_height">
        </div>
      </div>
    </div>
    <div style="width: 100%;">
      <div style="margin-top: 1rem; margin-bottom: 0.5rem;">Turbo:</div>
      <div class="checkbox-container" style="width: 100%;">
        <input type="checkbox" v-model="rune_turbo">
        <span>Opt-in to future protocol changes</span>
      </div>
    </div>
    <div>
      <div style="margin-top: 1rem; margin-bottom: 0.5rem;">Destination Address:</div>
      <div class="input-container" style="width: 100%;">
        <input v-model="receiver_address" type="text" placeholder="Destination LTC Address" name="address" require>
      </div>
      <!-- <div v-if="!receiver_address && (rune_premine || (files && files.length > 0))" class="input-invalid">
        Inscription and premine require a delivery address.
      </div> -->
      <div v-if="receiver_address && !isValidAddress(receiver_address)" class="input-invalid">
        Please input a valid destination address.
      </div>
    </div>
    <div style="display: flex; width: -webkit-fill-available; text-align: center; margin-top: 1rem;">
      <button class="button confirm-button" type="submit" style="width: 100%; padding: 0.8rem" :disabled="isSubmitting || !receiver_address || !isValidAddress(receiver_address) || !isEtchValid()">
        <font-awesome-icon v-if="isSubmitting" class="fa-spin" style="margin-right: 0.5rem" :icon="['fas', 'spinner']" />
        {{ isSubmitting ? "Creating Order..." : "Etch" }}
      </button>
    </div>
    <div v-if="error_message" class="input-invalid" style="width: 100%; text-align: center;">
      {{ error_message }}
    </div>
  </form>
  <div class="previous-orders">
    <router-link :to="{ name: 'orders' }">
      <div>Previous Orders</div>
    </router-link>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import config from '@/config.js'
import utils from '@/utils.js'

export default {
  setup() {
    const rune_name_raw = ref('');

    const rune_name = computed({
      get() {
        return rune_name_raw.value;
      },
      set(value) {
        const upperCased = value.toUpperCase(); // Convert to upper case
        const lettersOnly = upperCased.replace(/[^A-Z\s•]/g, ''); // Allow A-Z and spaces
        const singleBullet = lettersOnly.replace(/\s+/g, '•').replace(/•+/g, '•'); // Convert spaces to bullets, merge bullets
        
        rune_name_raw.value = singleBullet;
      }
    });

    return { rune_name };
  },
  data() {
    return {
      isModalVisible: false,
      isSubmitting: false,
      usd_rate: inject('usd_rate', 0),
      wallet_address: inject('wallet_address', false),
      receiver_address: null,
      error_message: null,
      files: [],
      file_preview: null,
      rune_symbol: null,
      rune_divisibility: null,
      rune_supply: null,
      rune_limit: null,
      rune_cap: null,
      rune_start_height: null,
      rune_end_height: null,
      rune_premine: null,
      rune_turbo: true,
    };
  },
  methods: {
    handleFiles(event) {
      const selectedFiles = event.target.files;
      if (!selectedFiles.length) {
        this.files = [];
        this.file_preview = null;
        return;
      }
      this.files = Array.from(selectedFiles);
      this.file_preview = URL.createObjectURL(this.files[0]);
    },
    removeFiles() {
      this.files = [];
    },
    handleSymbolInput() {
      const clusterSplitter = new Intl.Segmenter('en', { type: 'grapheme' });
      const graphemes = [...clusterSplitter.segment(this.rune_symbol)];
      if (graphemes.length > 1) {
        this.rune_symbol = graphemes[0].segment;
      }
    },
    handleNameInput(event) {
      console.log("input")
      this.rune_name_raw = event.target.value;
    },
    isEtchValid() {
      const rune_name_letters = this.rune_name.replace(/[^A-Z]/g, '');

      if (!this.rune_name || this.rune_name.length > 58 || rune_name_letters < 12 || rune_name_letters.length > 26) {
        return false;
      }
      if (!this.rune_symbol || this.rune_symbol.length == 0) {
        return false;
      }
      if (!Number.isInteger(this.rune_divisibility) || !Number.isInteger(this.rune_supply) || (this.rune_premine && !Number.isInteger(this.rune_premine))) {
        return false;
      }
      if (this.rune_limit && (this.rune_limit > this.rune_supply || this.rune_supply % this.rune_limit != 0)) {
        return false;
      }
      if (!this.rune_limit && !this.rune_premine) {
        return false;
      }
      if (this.rune_divisibility < 0 || this.rune_divisibility > 38) {
        return false;
      }
      if (this.rune_divisibility < 0 || this.rune_divisibility > 38) {
        return false;
      }
      if (this.rune_start_height && this.rune_end_height) {
        if (this.rune_start_height > this.rune_end_height) {
          return false;
        }
      }
      if (this.rune_premine > this.rune_supply) {
        return false;
      }
      if (this.rune_limit) {
        const cap = (this.rune_supply - this.rune_premine) / this.rune_limit;
        if (cap == 0 || !Number.isInteger(cap)) {
          return false;
        }
      }
      if (!this.rune_limit && this.rune_supply != this.rune_premine) {
        return false;
      }
      if ((this.files.length > 0 || this.rune_premine) && !this.isValidAddress(this.receiver_address)) {
        return false;
      }

      return true;
    },
    async submit() {
      this.error_message = null;
      this.isSubmitting = true;
      console.log("submit")

      const formData = new FormData();

      if (this.files.length > 0) {
        this.files.forEach((file) => {
          formData.append(`upload[]`, file);
        });
      }

      if (this.rune_premine == undefined || this.rune_premine.length == 0) {
        this.rune_premine = 0;
      }

      // null out unused settings
      if (this.rune_limit == undefined || this.rune_limit.length == 0) {
        this.rune_limit = null;
      }
      if (this.rune_start_height == undefined || this.rune_start_height.length == 0) {
        this.rune_start_height = null;
      }
      if (this.start_height == undefined || this.rune_end_height.length == 0) {
        this.rune_end_height = null;
      }

      // calculate cap
      if (this.rune_limit) {
        this.rune_cap = (this.rune_supply - this.rune_premine) / this.rune_limit;
      }

      let etching = {
        "spaced_rune": this.rune_name.trim(),
        "symbol": this.rune_symbol.trim(),
        "divisibility": this.rune_divisibility,
        "supply": this.rune_supply,
        "limit": this.rune_limit,
        "cap": this.rune_cap,
        "premine": this.rune_premine,
        "start_height": this.rune_start_height,
        "end_height": this.rune_end_height,
        "turbo": this.rune_turbo,
      }

      formData.append('address', this.receiver_address);
      formData.append('etching', JSON.stringify(etching));

      try {
        const response = await fetch(`${config.apiUrl}/order/create`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Response error ' + response.status);
        }

        const post_response = await response.json();
        console.log(post_response); // Handle the response data
        if (post_response.status == 0) {
          this.error_message = post_response.message;
          this.isSubmitting = false;
          return;
        }

        this.saveOrderToBrowserCache({'order_id': post_response.result.order, 'time_created': Math.round(Date.now()/1000)})

        this.$router.push({
          name: "order",
          params: {
            id: post_response.result.order,
          },
        });
      } catch (error) {
        console.error('Error:', error);
        this.error_message = error;
        this.isSubmitting = false;
        return;
      }

      this.isSubmitting = false;
    },
    ...utils
  },
  computed: {
    totalBytes() {
      var bytes = 0;
      for (const file of this.files) {
        bytes += file.size;
      }
      return bytes;
    },
    totalBytesRound() {
      return Math.floor(this.totalBytes / 1000) * 1000;
    },
    totalFee() {
      return (this.files.length * (0.0001 + 0.005)) + this.satToBtc(this.totalBytesRound);
    }
  }
};
</script>

<style scoped>
.etch {
  display: flex;
  flex-direction: column;
  column-gap: 4rem;
  row-gap: 1rem;
  align-items: flex-start;
  margin-top: 1rem;
  max-width: 920px;
  margin: auto;
  padding: 2rem;
  background: hsla(0, 0%, 100%, .05);
  border-radius: 5px;
}

.etch>div {
  width: 100%;
}

.etch-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inscribe-selector {
  cursor: pointer;
  display: block;
  width: 5rem;
  height: 5rem;
  background-color: #ffffff1f;
  border-radius: 5px;
}

.image-preview {
  padding: 0.5rem;
}

.etch-container {
  width: 100%;
  overflow: auto;
  text-align: center;
  border-radius: 5px;
  padding: 1rem;
  display: none;
}

.input-invalid {
  margin-top: 0.5rem;
  color: #ff4500;
}

.input-container {
  display: flex;
  border: 1px solid white;
  border-radius: 8px;
}

.input-container:focus-within {
  outline: white solid 2px;
}

.input-container>input {
  width: 100%;
  padding: 0.6em 0 0.6em 0.6em;
  border: none;
  outline: none;
}

input {
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
  border: 1px solid gray;
  padding: 0.4em;
}

.checkbox-container {
  display: flex;
  column-gap: 1rem;
  background-color: #ffffff1f;
  border-radius: 8px;
  padding: 0.5rem;
}

.file-container {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: auto;
}

.file-upload {
  margin: 0.5rem;
  padding: 0.5rem;
  background: #0004;
  border-radius: 5px;
}

.file-name {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.fee-summary {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 1rem;
  background-color: #ffffff0f;
  border-radius: 5px;
}

.previous-orders {
  display: flex;
  margin-top: 2rem;
  width: -webkit-fill-available;
  justify-content: center;
}

.previous-orders a {
  padding: 1rem;
  width: 50%;
  text-align: center;
  background: hsla(0, 0%, 100%, .05);
  border-radius: 5px;
}

.previous-orders a:hover {
  color: inherit;
  background-color: #222;
}
</style>