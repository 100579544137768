<template>
  <div class="lb-header">
    <h1>Leaderboard</h1>
  </div>
  <div class="table-container">
    <LeaderboardTableComponent :items="ranks"/>
    <InfiniteScrollComponent v-if="allowLoadLeaderboard" @infinite="loadLeaderboard" />
  </div>
</template>

<script>
import config from '@/config.js'
import utils from '@/utils';

import LeaderboardTableComponent from '@/components/LeaderboardTableComponent.vue';
import InfiniteScrollComponent from '@/components/InfiniteScrollComponent.vue';

export default {
  data() {
    return {
      page_number: 1,
      allowLoadLeaderboard: true,
      ranks: [],
    };
  },
  async beforeRouteEnter(to, from, next) {
    let lb_resp = await fetch(`${config.apiUrl}/leaderboard`)
      .then(d => d.json());

    next(vm => {
      vm.page_number = 1;
      vm.ranks = lb_resp.result.leaderboard;
    });
  },
  components: {
    LeaderboardTableComponent,
    InfiniteScrollComponent
  },
  methods: {
    async loadLeaderboard() {
      if (!this.allowLoadLeaderboard || this.ranks.length < 20) {
        return;
      }

      var lb_paged = await fetch(`${config.apiUrl}/leaderboard?page=${++this.page_number}`)
        .then(d => d.json());
      
      if (lb_paged.status == 1) {
        if (lb_paged.result.length < 20) {
          this.allowLoadLeaderboard = false;
        }

        this.ranks.push(...lb_paged.result.leaderboard);
      }
    },
    ...utils,
  },
};
</script>

<style scoped>
.lb-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>