<template>
  <div class="order">
    <!-- <div id="order-timer" class="me-4 <?php echo ($order->status >= 5 && $order->status <= 7) ? 'bg-danger' : 'bg-warning'; echo (($order->status >= 2 && $order->status <= 4 && $remaining < 0) || $order->status == 4) ? '" style="visibility: hidden;' : ''; ?>">
        <span><?php echo ($order->status >= 5 && $order->status <= 7) ? 'Expired' : gmdate("i:s", $remaining); ?></span>
    </div> -->
    <div class="order-header">
      <div>
        <div style="font-size: larger;">
          <span class="copy" @click="copyContent(order_id)">
            <b>Order {{ order_id }}</b>
            <font-awesome-icon :icon="['fas', 'copy']" style="margin-left: 1rem;"/>
          </span>
        </div>
        <span style="font-size: smaller;" :title="new Date(order.time_created * 1000)">{{ time_ago(order.time_created * 1000) }}</span>
      </div>
      <div>
        <div v-if="order.status == 0" class="order-time-remaining" title="Time remaining">
          {{ Math.floor(time_remaining / 60).toString().padStart(2, '0') }}:{{ (time_remaining % 60).toString().padStart(2, '0') }}
        </div>
        <div v-if="order.status == 4">
          <font-awesome-icon :icon="['fas', 'circle-check']" style="font-size: 1.5rem; color: #3fae2e;" />
        </div>
        <div v-if="order.status == 5">
          <font-awesome-icon :icon="['fas', 'circle-xmark']" style="font-size: 1.5rem; color: grey;" />
        </div>
        <div v-if="order.status == 7">
          <font-awesome-icon :icon="['fas', 'circle-xmark']" style="font-size: 1.5rem; color: orange;" />
        </div>
        <div v-if="order.status == 8">
          <font-awesome-icon :icon="['fas', 'circle-xmark']" style="font-size: 1.5rem; color: tomato;" />
        </div>
      </div>
    </div>
    <div class="order-details">
      <div>
          Send exactly <b class="copy" style="font-size: larger;" @click="copyContent(satToBtc(order.amount_to_pay))">
            {{ satToBtc(order.amount_to_pay) }} LTC (${{ (satToBtc(order.amount_to_pay) * usd_rate).toFixed(2) }})
          </b> to the address:
      </div>
      <div>
        <p class="copy" @click="copyContent(order.payment_address)">
          <b style="word-break: break-all;">{{ order.payment_address }}</b>
          <font-awesome-icon :icon="['fas', 'copy']" style="margin-left: 1rem;"/>
        </p>
      </div>
      <div class="qr-code">
        <QrCodeVue
          :value="`litecoin:${order.payment_address}?amount=${satToBtc(order.amount_to_pay)}`"
          :size=200
          :render-as="'svg'"
          :margin=1
          />
          <div style="color: black;"><small><b>Payment Address</b></small></div>
      </div>
    </div>
    <div style="width: 100%; text-align: center;">
      <div>{{ orderStatus(order.status) }}</div>
    </div>
  </div>
  <div class="previous-orders">
    <router-link :to="{ name: 'orders' }">
      <div>Previous Orders</div>
    </router-link>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import { useTippy } from 'vue-tippy';
import config from '@/config.js'
import utils from '@/utils.js'
import QrCodeVue from 'qrcode.vue';

export default {
  setup() {
    const tippyCopied = ref(null);
    useTippy(tippyCopied);

    return { tippyCopied };
  },
  data() {
    return {
      usd_rate: inject('usd_rate', 0),
      wallet_address: inject('wallet_address', false),
      order_id: null,
      order: {},
      time_remaining: 0,
      order_expiry_time: null,
      current_time: Date.now() / 1000,
      files: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    fetch(`${config.apiUrl}/order/status?order=${to.params.id}`)
      .then(d => d.json())
      .then(data => {
        // Pass the fetched data to the next callback
        if (data.status == 1) {
          next(vm => {
            vm.order = data.result;
            vm.order_id = to.params.id;
            vm.time_remaining = data.result.time_remaining;
            vm.order_expiry_time = Date.now() / 1000 + data.result.time_remaining;
          });
        } else {
          next(from.path);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        next(from.path);
      })
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    this.stopTimer();
  },
  components: {
    QrCodeVue
  },
  methods: {
    copyContent(content) {
      const input = document.createElement('input');
      input.value = content;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);

      // this.tippyCopied.show();
      // setTimeout(() => {
      //     this.tippyCopied.hide();
      // }, 2000);
    },
    orderStatus(status) {
      switch(status) {
        case 0:
          return "Awaiting Payment";
        case 1:
          return "Awaiting Transaction Confirmations";
        case 2:
          return "Payment Confirmed";
        case 3:
          return "Order Processing";
        case 4:
          return "Order Completed";
        case 5:
          return "Order Expired";
        case 7:
          return "Awaiting User Decision";
        case 8:
          return "Failed Processing";
      }
      return "Unknown Order Status";
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.updateTimeRemaining();
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    updateTimeRemaining() {
      this.time_remaining = Math.floor(this.order_expiry_time - Date.now() / 1000);
      if (this.time_remaining < 0) {
        this.time_remaining = 0;
      }
    },
    ...utils
  },
};
</script>

<style scoped>
.order {
  display: flex;
  flex-direction: column;
  column-gap: 4rem;
  row-gap: 1rem;
  align-items: flex-start;
  margin-top: 1rem;
  max-width: 920px;
  margin: auto;
  padding: 2rem;
  background: hsla(0, 0%, 100%, .05);
  border-radius: 5px;
}

.order-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.order-time-remaining {
  padding: 0.8rem;
  background-color: #333;
  border-radius: 5px;
}

.order-details {
  width: -webkit-fill-available;
  text-align: center;
}

.qr-code {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  background: #FFF;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 5px;
}

.copy {
  cursor: pointer;
}

.input-invalid {
  color: #ff4500;
}

.input-container {
  display: flex;
  border: 1px solid white;
  border-radius: 8px;
}

.input-container:focus-within {
  outline: white solid 2px;
}

.input-container>input {
  width: 100%;
  padding: 0.6em 0 0.6em 0.6em;
  border: none;
  outline: none;
}

input {
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
  border: 1px solid gray;
  padding: 0.4em;
}

.file-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-height: 300px;
  overflow: auto;
}

.file-upload {
  margin: 0.5rem;
  padding: 0.5rem;
  background: #0004;
  border-radius: 5px;
}

.file-name {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.fee-summary {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 1rem;
  background-color: #ffffff0f;
  border-radius: 5px;
}

.previous-orders {
  display: flex;
  margin-top: 2rem;
  width: -webkit-fill-available;
  justify-content: center;
}

.previous-orders a {
  padding: 1rem;
  width: 50%;
  text-align: center;
  background: hsla(0, 0%, 100%, .05);
  border-radius: 5px;
}

.previous-orders a:hover {
  color: inherit;
  background-color: #222;
}
</style>