<template>
  <div>
    <Scatter :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import utils from '@/utils.js'
import { Scatter } from 'vue-chartjs';
import { Chart as ChartJS, TimeScale, LinearScale, CategoryScale, PointElement, ScatterController, LineElement, LineController, BarElement, BarController, Tooltip, Legend } from 'chart.js'
import 'chartjs-adapter-date-fns';

ChartJS.register(TimeScale, LinearScale, CategoryScale, PointElement, ScatterController, LineElement, LineController, BarElement, BarController, Tooltip, Legend);

export default {
  components: {
    Scatter
  },
  props: {
    items: {
      type: Array,
      required: true
    },
  },
  computed: {
    chartData() {
      const formatDate = (date) => {
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
      };

      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 29);

      const last30Days = [...Array(30)].map((_, i) => {
        const date = new Date();
        date.setDate(date.getDate() - (29 - i));
        return formatDate(date);
      });

      const salesByDate = {};
      this.items.forEach(sale => {
        const date = formatDate(new Date(sale.timestamp * 1000));
        if (!salesByDate[date]) salesByDate[date] = [];
        salesByDate[date].push(sale);
      });

      const scatterData = [];
      const barData = [];
      const averageData = [];

      let maxSum = 0;
      let lastAvg = 0;

      last30Days.forEach(date => {
        const sales = salesByDate[date] || [];
        const sum = sales.reduce((acc, sale) => acc + Number(sale.price), 0);
        const avg = sales.length ? sum / sales.length : 0;

        if (sum > maxSum) {
          maxSum = sum;
        }

        if (sales.length > 0) {
          lastAvg = avg;
        }

        scatterData.push(...sales.map(sale => ({
          x: new Date((sale.timestamp - (60 * 60 * 12)) * 1000),
          y: utils.satToBtc(sale.price),
          ...sale
        })));
        barData.push({
          x: date,
          y: utils.satToBtc(sum),
          volume: sum
        });
        if (sales.length > 0) {
          lastAvg = avg;
          averageData.push({
            x: date,
            y: utils.satToBtc(lastAvg)
          });
        }
      });

      return {
        labels: [],
        datasets: [
          {
            type: 'line',
            label: 'Average Sale Price',
            data: averageData,
            borderColor: '#00d181',
            borderWidth: 0.8,
            fill: false,
            pointRadius: 0,
            yAxisID: 'y-axis-price'
          },
          {
            type: 'scatter',
            label: 'Sales',
            data: scatterData,
            backgroundColor: '#fff6',
            yAxisID: 'y-axis-price'
          },
          {
            type: 'bar',
            label: 'Volume',
            data: barData,
            backgroundColor: '#00d1814d',
            categoryPercentage: 1.0,
            barPercentage: 1.0,
            yAxisID: 'y-axis-volume'
          }
        ]
      };
    },
    chartOptions() {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 29);

      const maxSum = Math.max(...this.chartData.datasets[2].data.map(d => utils.satToBtc(d.volume)), 1) * 4;

      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
            },
            min: thirtyDaysAgo,
            max: today,
            grid: {
              display: false
            },
            ticks: {
              color: '#FFF'
            }
          },
          'y-axis-price': {
            type: 'linear',
            grid: {
              color: 'rgb(48, 48, 48)'
            },
            ticks: {
              color: '#FFF'
            }
          },
          'y-axis-volume': {
            type: 'linear',
            ticks: {
              display: false
            },
            min: 0,
            max: maxSum,
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function(tooltipItem) {
                if (tooltipItem.datasetIndex === 1) { // Scatter plot
                  const sale = tooltipItem.raw;
                  return ` ${sale.inscription_name} | ${utils.satToBtc(sale.price)} LTC`;
                } else if (tooltipItem.datasetIndex === 2) { // Bar plot
                  return ` Volume: ${utils.satToBtc(tooltipItem.raw.volume)} LTC`;
                }
                return null;
              }
            },
            usePointStyle: true,
          }
        }
      };
    }
  }
};
</script>

<style scoped>
</style>