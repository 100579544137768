import { WalletProvider } from './web3';
import * as btc from '@scure/btc-signer';
import { Transaction, Psbt, address as PsbtAddress } from 'bitcoinjs-lib';

function toXOnly(pubKey) {
    return pubKey.length === 32 ? pubKey : pubKey.subarray(1, 33);
}

export async function signPsbt(
  psbtBase64,
  keyPair,
  account,
  finalize = true,
  network = WalletProvider.LITECOIN_NETWORK
) {
  if (psbtBase64.length <= 0) {
    throw new Error('Invalid transaction');
  }

  // decode raw tx
  let psbt = null;
  try {
    psbt = Psbt.fromBase64(psbtBase64, {
      network: WalletProvider.LITECOIN_NETWORK,
    });
  } catch (error) {
    throw new Error('Error decoding transaction');
  }

  var inputsToSign = [];

  psbt.data.inputs.forEach((v, index) => {
      let script = null;
      let value = 0;
      if (v.witnessUtxo) {
        script = v.witnessUtxo.script;
        value = v.witnessUtxo.amount;
      } else if (v.nonWitnessUtxo) {
        const tx = Transaction.fromBuffer(v.nonWitnessUtxo);
        const output = tx.outs[psbt.txInputs[index].index];
        script = output.script;
        value = output.amount;
      }
      const isSigned = (v.finalScriptSig && v.finalScriptSig.length != 0) || v.finalScriptWitness;
      if (script && !isSigned) {
        const address = PsbtAddress.fromOutputScript(Buffer.from(script), network);
        console.log(address);
        if (account === address) {
          inputsToSign.push({
            index,
            publicKey: keyPair.publicKey,
            sighashTypes: v.sighashType ? [v.sighashType] : undefined
          });
        }
      }

      console.log(v, index, value);
  });

  console.log(inputsToSign);

  try {  
    // Sign inputs at indexes
    inputsToSign.forEach((inputToSign) => { 
      // If input is taproot type and didn't set tapInternalKey,
      // assume it is implied to be the account's publicKey
      const input = psbt.data.inputs[inputToSign.index];
      const witnessOutputScript = input.witnessUtxo?.script && btc.OutScript.decode(input.witnessUtxo.script);

      if (!input.tapInternalKey && witnessOutputScript?.type === 'tr') {
        psbt.data.updateInput(inputToSign[0], {
          tapInternalKey: toXOnly(Buffer.from(keyPair.publicKey, 'hex')),
        });
      }

      psbt.signInput(inputToSign.index, keyPair, inputToSign.sighashTypes);

      if (finalize) {
        psbt.finalizeInput(inputToSign.index);
      }
    });
  } catch (error) {
    throw new Error(`Error signing PSBT ${error.toString()}`);
  }

  return psbt.toBase64();
}