<template>
  <div ref="loader" class="infinite-loader"></div>
</template>
  
<script setup>
import { ref, defineEmits, onMounted, onUnmounted } from 'vue';

const loader = ref(null);
const emit = defineEmits(['infinite']);

let observer;

onMounted(() => {
  observer = new IntersectionObserver(
    (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        emit('infinite');
      }
    },
    {
      root: null, // observing for visibility in the viewport
      rootMargin: '0px',
      threshold: 1.0, // trigger when 100% of the element is visible
    }
  );

  if (loader.value) {
    observer.observe(loader.value);
  }
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});
  
</script>
  
<style>
.infinite-loader {
  /* Style your loader, e.g., a small height and full width */
  height: 4px;
  width: 100%;
}
</style>