<template>
  <table class="table">
    <thead class="table-head">
      <tr>
        <th class="rank">#</th>
        <th class="token-img-column"></th>
        <th class="name">Tick</th>
        <th class="price">Price</th>
        <th class="change_24h">24h %</th>
        <!-- <th class="volume">Volume</th> -->
        <th class="volume">Total volume</th>
        <th class="market_cap">Market Cap</th>
        <th class="holders">Holders</th>
        <th class="transactions">Transactions</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr v-for="(token, index) in items" :key="token.id" class="table-row clickable" @click="navToLtc20(token.ticker)">
          <td class="rank">{{ index + 1 }}</td>
          <td class="token-img-column">
            <img v-if="loadImage(token.ticker)" class="token-img" loading="lazy" :src="loadImage(token.ticker)">
            <div v-if="!loadImage(token.ticker)" class="token-img">{{ token.ticker }}</div>
          </td>
          <td class="name">{{ token.ticker }}</td>
          <td :class="{'price': true, 'no-data': token.floor_price <= 0 }">
            <div v-if="token.floor_price > 0">
                <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(token.floor_price) }}
                <div class="usd-value">${{ (satToBtc(token.floor_price) * usd_rate).toLocaleString() }}</div>
            </div>
            <span v-if="!token.floor_price || token.floor_price <= 0">-</span>
          </td>
          <td :class="{'change_24h': true, 'no-data': token.change_24h <= 0 }" :style="{ color: token.change_24h > 0 ? 'lawngreen' : (token.change_24h < 0 ? 'tomato' : 'inherit') }">
            <div v-if="token.change_24h">{{ token.change_24h }}%</div>
            <span v-if="!token.change_24h">-</span>
          </td>
          <!-- <td class="volume">
          <div>
              <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ volume_24h }}
          </div>
          </td> -->
          <td :class="{ 'volume': true, 'no-data': token.total_volume <= 0 }">
            <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(token.total_volume).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}
            <div class="usd-value">${{ (satToBtc(token.total_volume) * usd_rate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</div>
          </td>
          <td :class="{ 'market_cap': true, 'no-data': token.floor_price <= 0 }">
            <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ (token.supply * satToBtc(token.floor_price)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}
            <div class="usd-value">${{ (token.supply * satToBtc(token.floor_price) * usd_rate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</div>
          </td>
          <td class="transactions">{{ Number(token.holders).toLocaleString() }}</td>
          <td class="holders">{{ Number(token.transactions).toLocaleString() }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { inject } from 'vue'
import utils from '@/utils';

export default {
  data() {
    return {
      usd_rate: inject('usd_rate', 0),
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
  },
  methods: {
    navToLtc20(name) {
      this.$router.push({
        name: "ltc20",
        params: {
            name: name,
        },
      });
    },
    loadImage(token) {
      if (token == 'lite') {
        return require(`@/assets/ltc20/${token}.jpg`);
      } else if (token == 'labs' || token == 'iltc' || token == 'elks' || token == '1337' || token == 'hodl') {
        return require(`@/assets/ltc20/${token}.png`);
      }
      return null;
    },
    ...utils
  }
}
</script>

<style>
.item-container {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.table-row .token-img {
  width: 42px;
  height: 42px;
  vertical-align: middle;
  object-fit: cover;
}

div.token-img {
  display: flex;
  background-color: #212121;
  font-size: .7rem;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
}

.token-img {
  border-radius: 50%;
}

th.volume, th.market_cap, th.change_24h {
  white-space: nowrap;
}

.table-row .usd-value {
  margin-left: 20px;
  font-weight: 500;
  font-size: 90%;
  line-height: 1;
  color: #fffc;
}
</style>