<template>
  <div class="listings-header">
    <h1>Collections</h1>
  </div>
  <div class="table-container">
    <table class="table">
      <thead class="table-head">
        <tr>
          <th class="rank">#</th>
          <th class="inscription-img-column"></th>
          <th class="name">Collection name</th>
          <th class="price">Floor price</th>
          <!-- <th class="volume">Volume</th> -->
          <th class="volume">Total volume</th>
          <th class="trades">Trades</th>
          <th class="supply">Supply</th>
          <!-- <th class="owners">Owners</th> -->
        </tr>
      </thead>
      <tbody class="table-body">
        <tr v-for="(collection, index) in collections" :key="collection.id" class="table-row clickable" @click="navToCollection(collection.collection)">
          <td class="rank">{{ index + 1 }}</td>
          <td class="inscription-img-column">
            <img v-if="collection.image.length > 0" class="inscription-img" loading="lazy" :src="`${collection.image}`">
            <div v-if="collection.image.length == 0" class="inscription-img">{{ collection.name }}</div>
          </td>
          <td class="name">{{ collection.name }}</td>
          <td  :class="{'price': true, 'no-data': collection.floor_price <= 0 }">
            <div v-if="collection.floor_price > 0">
              <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(collection.floor_price).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8}) }}
            </div>
            <span v-if="collection.floor_price <= 0">-</span>
          </td>
          <!-- <td class="volume">
            <div>
              <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ volume_24h }}
            </div>
          </td> -->
          <td :class="{ 'volume': true, 'no-data': collection.total_volume <= 0 }">
            <div>
              <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(collection.total_volume).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8}) }}
            </div>
          </td>
          <td class="trades">{{ Number(collection.trades_24h).toLocaleString() }}</td>
          <td class="supply">{{ Number(collection.supply).toLocaleString() }}</td>
          <!-- <td class="owners">1,474</td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject } from 'vue';
import config from '@/config.js'

export default {
  data() {
    return {
      usd_rate: inject('usd_rate', 0),
      collections: {},
    };
  },
  async beforeRouteEnter(to, from, next) {
    let collections_resp = await fetch(`${config.apiUrl}/collections`)
      .then(d => d.json());

    next(vm => {
      var sorted_collection = collections_resp.result.sort((a, b) => b.total_volume - a.total_volume);
      vm.collections = sorted_collection;
    });
  },
  methods: {
    navToCollection(name) {
      this.$router.push({
        name: "collection",
        params: {
            name: name,
        },
      });
    },
    short_address(str) {
      return str.substr(0, 5) + '...' + str.substr(str.length-5, str.length);
    },
    satToBtc(sat) {
      return Number(sat) / Math.pow(10, 8);
    },
  },
};
</script>

<style scoped>
img.inscription-img {
  object-fit: cover;
}

.inscription-img {
  border-radius: 50%;
}

td.name {
  font-size: 1.1rem;
  font-weight: 600;
}

.volume, .price {
  white-space: nowrap;
}

.table-row .no-data {
  color: #8a939b;
  font-weight: 400;
}
</style>