<template>
  <div v-show="isModalVisible" class="modal">
    <div class="modal-backdrop" @click="close"></div>
    <div class="modal-wrap">
      <div class="modal-window">
        <button
            type="button"
            class="btn-close"
            @click="close"
          >
          x
        </button>
        <h1 class="modal-header">
          <slot name="header">
            Rune Utxos
          </slot>
        </h1>
        <h2 class="modal-header">
          {{ rune_details.spaced_rune }}
        </h2>
  
        <div class="modal-body">
          <slot name="body">
            <div class="body-grid">
              <a v-for="utxo in rune_details.utxos" :key="utxo.outpoint" class="modal-row" :href="`https://ordinalslite.com/output/${utxo.outpoint}`" target="_blank">
                <div>{{ short_address(utxo.outpoint) }}</div>
                <div class="modal-row-value">
                  {{ amountToDecimal(utxo.amount, rune_details.divisibility) }} {{ rune_details.symbol }}
                </div>
                <button v-if="is_owner" class="table-button details-button" style="margin-left: 0.5rem;" @click.stop.prevent="listRune(utxo.outpoint, amountToDecimal(utxo.amount, rune_details.divisibility))">
                  <span>List</span>
                </button>
              </a>
            </div>
          </slot>
        </div>
        <div v-if="errorMessage" class="error-message" style="color: red; text-align: center; padding-bottom: 1rem;">{{ errorMessage }}</div>
        <button
          type="button"
          class="modal-button confirm-button"
          @click="close"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import utils from '@/utils.js'

export default {
  name: 'ModalComponent',
  
  data() {
    return {
      isReady: false,
      isModalVisible: false,
      errorMessage: null,
      content: null,
      rune_details: [],
      is_owner: false,
      usd_rate: inject('usd_rate', 0),
      available_balance: inject('wallet_balance', 0),
      wallet_connected: inject('wallet_connected', false),
      wallet_address: inject('wallet_address', false),
    };
  },
  methods: {
    close() {
      this.isModalVisible = false;
      this.errorMessage = null;
    },
    listRune(utxo, amount) {
      console.log(utxo);
      this.emitter.emit("modal-list-open", { rune: this.rune_details.spaced_rune, rune_utxo: utxo, rune_balance: amount, token: true });
      this.close();
    },
    ...utils
  },
  mounted() { 
    this.emitter.on("modal-rune-balance-details-open", (parameters) => {
      this.isReady = false;
      this.isModalVisible = true;
      this.errorMessage = null;
      this.rune_details = parameters.rune_details;
      this.is_owner = parameters.is_owner;
    });
  },
};
</script>

<style scoped>
  .modal-body {
    position: relative;
    padding: 0 10px 20px 10px;
  }

  .modal-backdrop {
    position: fixed;
    border: none;
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    opacity: 0.6;
    transition: opacity .15s ease;
    background-color: #000;
  }

  .hystmodal__shadow--show {
    pointer-events: auto;
    opacity: .6;
  }

  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    z-index: 99;
  }

  .modal-wrap {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    min-height: 100%;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    z-index: 97;
  }

  .modal-window {
    display: flex;
    flex-direction: column;
    margin: 50px 0;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    width: 500px;
    max-width: calc(100% - 1rem);
    min-height: 500px;
    overflow: visible;
    pointer-events: all;
    transition: transform .2s ease 0s,opacity .2s ease 0s;
    transform: scale(.9);
    background-color: #ffffff1f;
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    border-radius: 12px;
    padding: 1.5rem;
  }

  @media (max-width: 576px) {
    .modal-window {
      padding: .75rem;
    }
  }

  .modal-input {
    margin-right: 0.5rem;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    width: 6rem;
    text-align: left;
    font-size: inherit;
  }

  .modal-row {
    color: inherit;
  }

  .modal-row.modal-input {
    padding: 0.1rem;
  }

  .modal-row:hover {
    cursor: pointer;
    background-color: #0000004d;
    border-radius: 5px;
  }

  .modal-input:focus {
    border-bottom: 1px solid #007aff;
  }

  .modal-input:enabled {
    border-bottom: 1px solid tan;
  }

  .modal-amount {
    margin-top: 1em;
    font-size: larger;
  }

  .body-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .body-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .body-grid {
    margin-top: 1rem;
    max-height: 250px;
    overflow: auto;
  }

  .btn-close {
    position: absolute;
    z-index: 10;
    display: block;
    top: 14px;
    right: 14px;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    font-size: 0;
    cursor: pointer;
    outline: none;
  }

  .modal-box {
    max-height: 26rem;
    overflow-y: auto
  }

  @media (max-width: 576px) {
    .modal-box {
      max-height: 20rem;
    }
  }

  .modal-row {
    display: flex;
    justify-content: center;
    line-height: 2;
  }

  .modal-row>div {
    width: 50%;
  }

  .modal-row>div:first-child {
    text-align: right;
    padding-right: 2rem;
  }

  .modal-row .modal-row-value {
    text-align: left;
  }

  .modal-row .modal-row-input {
    padding: .1rem;
  }

  @media (max-width: 576px) {
    .modal-row>div:first-child {
      padding-right:1rem;
    }
  }

  .modal-button {
    margin-top: auto;
  }

  .modal-item {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
  }

  .item-text {
    text-align: center;
    margin-top: 2.75rem;
    margin-bottom: 2.75rem
  }

  .modal-total {
    margin-top: 1em;
    font-size: larger;
  }

  @media all and (max-width: 767px) {
    .modal-window {
      margin: 0;
    }
  }

  .modal-header {
    margin-top: 0;
    text-align: center;
    font-weight: 600;
  }

  @media (max-width: 576px) {
    .modal-header {
      font-size: 1.5rem;
    }
  }

  .content-container .code {
    border-radius: 12px;
    background-color: #00000080;
    padding: 0.5rem;
    overflow-wrap: break-word;
  }
</style>