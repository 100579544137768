import utils from '@/utils';

export const LitescribeWallet = {
  provider: null,
  setProvider(provider) {
    this.provider = provider;
  },
  async initialize() {
    // Force unlock
    await this.getWalletAddress();
  },
  async getWalletAddress() {
    if (this.isWalletInstalled()) {
      return (await window.litescribe.requestAccounts())?.[0];
    }
  },
  async getBalance() {
    return await window.litescribe.getBalance();
  },
  async sendBitcoin(address, amount) {
    console.log(`Sending ${amount} LTC to ${address}...`);
    return await window.litescribe.sendBitcoin(address, amount);
  },
  async sendInscription(address, inscriptionId) {
    console.log(`Sending ${inscriptionId} to ${address}...`);
    return await window.litescribe.sendInscription(address, inscriptionId, { feeRate: 1 });
  },
  async signPsbt(psbtBase64) {
      await this.getWalletAddress();

      return await window.litescribe.signPsbt(utils.base64ToHex(psbtBase64));
  },
  async signPsbts(psbtsBase64) {
      await this.getWalletAddress();

      var psbtsHex = [];
      for (var i = 0; i< psbtsBase64.length; i++) {
          psbtsHex.push(utils.base64ToHex(psbtsBase64[i]))
      }
      return await window.litescribe.signPsbts(psbtsHex);
  },
  async signMessage(message) {
      await this.getWalletAddress();

      return message;
  },
  isWalletInstalled() {
      if (typeof window.litescribe !== "undefined") {
          return true;
      }
      throw new Error("Litescribe Wallet not installed");
  }
};