<template>
  <table class="table">
    <thead class="table-head">
      <tr>
        <th class="tick">Tick</th>
        <th class="transferable">Transferable</th>
        <th class="available">Available</th>
        <th class="total-balance">Total Balance</th>
        <th></th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr v-for="token in items" :key="token.id" class="table-row clickable" @click="navToLtc20(token.ticker)">
        <td class="tick">{{ token.ticker }}</td>
        <td class="transferable">{{ Number(token.transferableBalance).toLocaleString() }}</td>
        <td class="available">{{ Number(token.availableBalance).toLocaleString() }}</td>
        <td class="total-balance">{{ (Number(token.transferableBalance) + Number(token.availableBalance)).toLocaleString() }}</td>
        <td><button class="table-button" @click.stop="showLtc20ListModal(token.ticker, token.availableBalance)">List</button></td>
      </tr>
    </tbody>
  </table>
</template>
  
<script>
import utils from '@/utils';

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
  },
  methods: {
    navToLtc20(ticker) {
      console.log("nav");
      this.$router.push({
        name: "ltc20",
        params: {
          name: ticker,
        },
      });
    },
    showLtc20ListModal(ticker, amount) {
      console.log("modal");
      this.emitter.emit("modal-ltc20-list-open", { ltc20_ticker: ticker, ltc20_amount: amount });
      event.preventDefault();
    },
    ...utils
  }
}
</script>
  
<style scoped>
.item-container {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

th.total-balance {
  white-space: nowrap;
}

.table-button {
  height: 30px;
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
  background-color: #D4D6E1;
  font-weight: 700;
  color: #345D9D;
  cursor: pointer;
  text-align: center;
  transition: all .25s ease-in-out;
}

.table-button:enabled:hover {
    background-color: #345d9d;
    color: #fff;
}
</style>