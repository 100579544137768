import utils from '@/utils';
import { WalletProvider } from '../web3';
import { payments } from 'bitcoinjs-lib';
import * as bip39 from '@scure/bip39';
import { HDKey } from "@scure/bip32";

import { signPsbt } from '../signer.js';

import ecc from "@bitcoinerlab/secp256k1";
import { ECPairFactory } from 'ecpair';

const ECPair = ECPairFactory(ecc)

export const ChikunWallet = {
    provider: null,
    seedphrase: '',
    hdkey: null,
    keyPair: null,
    address: null,
    setProvider(provider) {
        this.provider = provider;
    },
    setSeedPhrase(seedphrase) {
        this.seedphrase = seedphrase;
    },
    async initialize() {
        const seed = await bip39.mnemonicToSeed(this.seedphrase);
        // console.log(seed);

        const hdkey = HDKey.fromMasterSeed(seed);

        const child = hdkey.derive("m/84'/2'/0'/0/0");

        // console.log(hdkey)
        // console.log(child)

        this.hdkey = child;

        const pubkey = Buffer.from(child.publicKey);

        this.keyPair = ECPair.fromPrivateKey(Buffer.from(child.privateKey));

        const { address } = payments.p2wpkh({ pubkey, network: WalletProvider.LITECOIN_NETWORK });
        // console.log(address)

        this.address = address;
    },
    getWalletAddress() {
        return this.address;
    },
    async getBalance() {
        const stats = await fetch(
            `${WalletProvider.MEMPOOL_API_URL}/address/${this.address}`
          ).then((response) => response.json());
        const balance = {
            total: stats['chain_stats']['funded_txo_sum'] + stats['mempool_stats']['funded_txo_sum'] - stats['chain_stats']['spent_txo_sum'] - stats['mempool_stats']['spent_txo_sum']
        }

        return balance;
    },
    async sendBitcoin(address, amount) {
        await this.provider.updatePayerAddress(this.address, amount);
        console.log(`Sending ${amount} BTC to ${address}...`);
        var psbtUnsigned = await this.provider.generatePSBTSendBitcoin(this.address, address, amount, this.provider.paymentUtxos);

        try {
          await this.provider.signPSBTUsingWalletAndBroadcast(psbtUnsigned);
        } catch (error) {
          console.error(error);
          throw error;
        }

        return true;
    },
    async sendInscription(address, inscriptionId) {
        await this.provider.updatePayerAddress(this.address, 3000);
        console.log(`Sending Inscription ${inscriptionId} to ${address}...`);
        var psbtUnsigned = await this.provider.generatePSBTInscriptionSend(this.address, address, inscriptionId, true);

        try {
          await this.provider.signPSBTUsingWalletAndBroadcast(psbtUnsigned);
        } catch (error) {
          console.error(error);
          throw error;
        }

        return true;
    },
    async signPsbt(psbtBase64) {
        console.log(psbtBase64)
        var psbtBase64Signed = await signPsbt(psbtBase64, this.keyPair, this.address);
        return utils.base64ToHex(psbtBase64Signed);
    },
    async signsPsbt(psbtsBase64) {
        var psbtsHexSigned = [];
        for (var i = 0; i< psbtsBase64.length; i++) {
            var psbtBase64Signed = await signPsbt(psbtsBase64[i], this.keyPair, this.address);
            psbtsHexSigned.push(utils.base64ToHex(psbtBase64Signed));
        }
        return psbtsHexSigned;
    },
    signMessage(message) {
        return message;
    },
    lockWallet() {
        this.seedphrase = '';
        this.hdkey = null;
        this.keyPair = null;
    }
};