<template>
  <table class="table">
    <thead class="table-head">
      <tr>
        <th class="item">{{ rune ? "Rune" : "Inscription" }}</th>
        <th class="action-head">Action</th>
        <th class="price">Price</th>
        <th class="from">Seller</th>
        <th class="to">Buyer</th>
        <th class="date">Date</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr v-for="event in items" :key="event.id" class="table-row">
        <td class="item">
          <div class="item-container">
            <img v-if="event.type == 'collection'" class="inscription-img square-img" loading="lazy" :src="'https://ordinalslite.com/content/' + event.inscription_id">
            <div style="line-height: 1;">
              <span v-if="event.inscription_name" class="item-name">{{ event.inscription_name }}</span>
              <div>
                <router-link v-if="!rune" :to="{ name: 'inscription', params: { id: event.inscription_id }}"  class="inscription-link" style="font-size: 0.7rem;">#{{ event.inscription_number }}</router-link>
                <router-link v-if="rune" :to="{ name: 'rune', params: { id: rune.id }}" class="inscription-link" style="font-size: 0.7rem;">#{{ rune.id }}</router-link>
              </div>
            </div>
          </div>
        </td>
        <td class="action">
          <span v-if="event.action == 0" class="action-label action-list">list</span>
          <span v-if="event.action == 1" class="action-label action-sell">{{ event.buyer_address == address ? 'buy' : 'sell' }}</span>
          <span v-if="event.action == 2" class="action-label action-unlist">unlist</span>
          <span v-if="event.action == 6" class="action-label action-update">update</span>
        </td>
        <td class="price">
          <div>
            <img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(event.price) }}
          </div>
          <div v-if="event.ltc20_amount && event.ltc20_ticker">
            <div v-if="!rune"><img src="@/assets/Litecoin.png" class="litecoin-icon">{{ (satToBtc(event.price) / event.ltc20_amount).toLocaleString('en-US', { maximumFractionDigits: 8 }) }}/{{event.ltc20_ticker}}</div>
            <div v-if="rune"><img src="@/assets/Litecoin.png" class="litecoin-icon">{{ (satToBtc(event.price) / amountToDecimal(event.ltc20_amount, rune.divisibility)).toLocaleString('en-US', { maximumFractionDigits: 8 }) }}/{{rune.symbol}}</div>
          </div>
        </td>
        <td class="from">
          <router-link :to="{ name: 'address', params: { address: event.seller_address }}">{{ short_address(event.seller_address) }}</router-link>
        </td>
        <td class="to">
          <router-link v-if="event.buyer_address" :to="{ name: 'address', params: { address: event.buyer_address }}">{{ short_address(event.buyer_address) }}</router-link>
        </td>
        <td class="date" :title="new Date(event.timestamp * 1000)">{{ time_ago(event.timestamp * 1000) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import utils from '@/utils';

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    address: {
      type: String
    },
    rune: {
      type: Object
    }
  },
  methods: {
    ...utils
  }
}
</script>

<style>
.item-container {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.square-img {
  border-radius: 0;
}

.action-sell {
  color: #00d181;
  background-color: #00d1814d
}

.action-list {
  color: #027dff;
  background-color: #027dff4d
}

.action-unlist {
  color: #dc3545;
  background-color: #dc35454d
}

.action-update {
  color: gold;
  background-color: #5f530e
}

.action-label {
  font-size: .8rem;
  padding: 0.2rem 0.4rem;
  border-radius: 6px;
}
</style>