<template>
  <table class="table">
    <thead class="table-head">
      <tr>
        <th class="item">Inscription</th>
        <th class="action-head">Action</th>
        <th class="amount">Amount</th>
        <th class="from">From</th>
        <th class="to">To</th>
        <th class="date">Date</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr v-for="event in items" :key="event.id" class="table-row">
        <td class="item">
          <div class="item-container">
            <div style="line-height: 1;">
              <div>
                <router-link :to="{ name: 'inscription', params: { id: event.inscription_id }}"  class="inscription-link">{{ short_address(event.inscription_id) }}</router-link>
              </div>
            </div>
          </div>
        </td>
        <td class="action">
          {{ event.action.charAt(0).toUpperCase() + event.action.slice(1) }}
        </td>
        <td class="price">
          {{ event.amount }}
        </td>
        <td class="from">
          <router-link :to="{ name: 'address', params: { address: event.address_sender }}">{{ short_address(event.address_sender) }}</router-link>
        </td>
        <td class="to">
          <router-link v-if="event.address_receiver" :to="{ name: 'address', params: { address: event.address_receiver }}">{{ short_address(event.address_receiver) }}</router-link>
        </td>
        <td class="date" :title="new Date(event.timestamp * 1000)">{{ time_ago(event.timestamp * 1000) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import utils from '@/utils';

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
  },
  methods: {
    ...utils
  }
}
</script>

<style>
.item-container {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.square-img {
  border-radius: 0;
}

.action-sell {
  color: #00d181;
  background-color: #00d1814d
}

.action-list {
  color: #027dff;
  background-color: #027dff4d
}

.action-unlist {
  color: #dc3545;
  background-color: #dc35454d
}

.action-update {
  color: gold;
  background-color: #5f530e
}

.action-label {
  font-size: .8rem;
  padding: 0.2rem 0.4rem;
  border-radius: 6px;
}
</style>